import { DqLoader } from "@decentriq/components";
import { useOwnOrganizationUserEmailsQuery } from "@decentriq/graphql/dist/hooks";
import { faInfoCircle as fasInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
} from "@mui/joy";
import { isEmpty } from "lodash";
import { Fragment, memo, useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDatasetPermission } from "features/datasets/contexts";
import {
  createShareDatasetValidationResolver,
  type DatasetPermissionFormValues,
  type DatasetPermissionRole,
  datasetPermissionRoleOptions,
  datasetPermissionRolePresentation,
} from "features/datasets/models";

interface DatasetShareDialogProps {
  open: boolean;
  onCancel: () => void;
}

const DatasetShareDialog = memo<DatasetShareDialogProps>(
  ({ open, onCancel }) => {
    const {
      addPermission,
      permissions,
      isPermissionsDataLoading,
      isAddingPermission,
    } = useDatasetPermission();
    const {
      data: organisationUsersData,
      loading: isOrganisationUsersDataLoading,
    } = useOwnOrganizationUserEmailsQuery();
    const organisationUsers = useMemo(
      () => organisationUsersData?.myself?.organization?.users?.nodes ?? [],
      [organisationUsersData?.myself?.organization?.users?.nodes]
    );
    const { control, reset, handleSubmit } =
      useForm<DatasetPermissionFormValues>({
        defaultValues: {
          id: "",
          role: "User",
        },
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: useCallback(
          () =>
            createShareDatasetValidationResolver(
              organisationUsers,
              permissions
            ),
          [organisationUsers, permissions]
        )(),
      });
    const handleCancel = useCallback(() => {
      reset();
      onCancel();
    }, [onCancel, reset]);
    const handleCreatePermission = useCallback(
      (permission: DatasetPermissionFormValues) => {
        addPermission(permission);
        reset();
        onCancel();
      },
      [addPermission, onCancel, reset]
    );
    if (isOrganisationUsersDataLoading || isPermissionsDataLoading) {
      return (
        <Modal onClose={onCancel} open={open}>
          <ModalDialog role="dialog">
            <DialogTitle>Share dataset</DialogTitle>
            <DialogContent>
              <Stack alignItems="center" padding={3}>
                <DqLoader />
              </Stack>
            </DialogContent>
          </ModalDialog>
        </Modal>
      );
    }
    return (
      <Modal
        onClose={isAddingPermission ? undefined : handleCancel}
        open={open}
      >
        <form onSubmit={handleSubmit(handleCreatePermission)}>
          <ModalDialog role="dialog">
            <DialogTitle>Share dataset</DialogTitle>
            <DialogContent>
              <Stack>
                <Controller
                  control={control}
                  name="id"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl error={!isEmpty(error)}>
                        <FormLabel>Email</FormLabel>
                        <Input
                          autoFocus={true}
                          placeholder="e.g. user@example.com"
                          {...field}
                        />
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="role"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Fragment>
                        <FormControl>
                          <FormLabel>Access</FormLabel>
                          <Select
                            {...field}
                            onChange={(event, value) => field.onChange(value)}
                            placeholder="e.g. Owner"
                            renderValue={(option) => {
                              return option?.value
                                ? datasetPermissionRolePresentation[
                                    option.value as DatasetPermissionRole
                                  ]
                                : "";
                            }}
                          >
                            {datasetPermissionRoleOptions.map((role) => (
                              <Option key={role} value={role}>
                                {datasetPermissionRolePresentation[role]}
                              </Option>
                            ))}
                          </Select>
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                        <Alert
                          color="neutral"
                          startDecorator={
                            <FontAwesomeIcon icon={fasInfoCircle} />
                          }
                          variant="soft"
                        >
                          {field.value === "Owner"
                            ? "The owner will have full access, including the ability to edit and grant permissions to others. This access will be permanent and can only be revoked by deleting the dataset."
                            : "The user will have the ability to provision the dataset to data clean rooms or export it. This access is permanent and can only be revoked by deleting the dataset."}
                        </Alert>
                      </Fragment>
                    );
                  }}
                />
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={isAddingPermission ? undefined : handleCancel}>
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={isAddingPermission}
                loading={isAddingPermission}
                type="submit"
                variant="solid"
              >
                Share dataset
              </Button>
            </DialogActions>
          </ModalDialog>
        </form>
      </Modal>
    );
  }
);

DatasetShareDialog.displayName = "DatasetShareDialog";

export default DatasetShareDialog;
