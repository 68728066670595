import { DqTable } from "@decentriq/components";
import { Alert, Checkbox } from "@mui/joy";
import { memo } from "react";
import { type UnifiedSchemaPresentation } from "utils/dataset";

interface DatasetSchemaProps {
  schema: UnifiedSchemaPresentation;
}

const DatasetSchema: React.FC<DatasetSchemaProps> = ({
  schema: { columns },
}) => {
  if (!columns.length) {
    return <Alert color="info">Schema is not provided for this dataset</Alert>;
  }
  return (
    <DqTable
      columns={[
        { accessorKey: "name", header: "Column", id: "column", size: 100 },
        {
          accessorKey: "type",
          header: "Type",
          id: "type",
          muiTableBodyCellProps: { align: "center" },
          muiTableHeadCellProps: { align: "center" },
          size: 110,
        },
        {
          Cell: ({ cell }) => {
            const value = cell.getValue<boolean>();
            return <Checkbox checked={value} disabled={true} />;
          },
          accessorKey: "nullable",
          header: "Allow empty values",
          id: "nullable",
          muiTableBodyCellProps: { align: "center" },
          muiTableHeadCellProps: { align: "center" },
          size: 110,
        },
        {
          Cell: ({ cell }) => {
            const value = cell.getValue<boolean>();
            return <Checkbox checked={value} disabled={true} />;
          },
          accessorKey: "hashed",
          header: "Hashed values",
          id: "hashed",
          muiTableBodyCellProps: { align: "center" },
          muiTableHeadCellProps: { align: "center" },
          size: 90,
        },
      ]}
      data={columns}
    />
  );
};

DatasetSchema.displayName = "DatasetSchema";

export default memo(DatasetSchema);
