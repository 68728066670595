import { DqCard, DqCardContent } from "@decentriq/components";
import { Alert, Box, Typography } from "@mui/joy";
import { memo } from "react";
import { useDataLabContext } from "features/dataLabs";
import {
  DemographicDistributionCharts,
  SegmentDistributionChart,
  SegmentPerUserDistributionChart,
} from "./components";

const DataLabStatisticsCharts: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();

  const { segmentsDataset, demographicsDataset, statistics } = dataLab || {};
  const {
    share_of_users_per_segment_distribution: shareOfUsersPerSegmentDistribution,
    segments_per_user_distributions: segmentsPerUserDistributions,
    demographics_distributions: demographicsDistributions,
  } = statistics || {};

  return (
    <Box
      sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      {segmentsDataset &&
        (shareOfUsersPerSegmentDistribution ||
          segmentsPerUserDistributions) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: 1,
              width: "100%",
            }}
          >
            <Typography
              alignSelf="flex-start"
              fontWeight={500}
              level="body-md"
              mb={1}
            >
              Segments
            </Typography>
            <Alert color="info" sx={{ marginBottom: 2 }} variant="outlined">
              <Typography fontWeight={500} level="body-md">
                Quality guidance
              </Typography>
              <span>
                Ideally, there should be between 30-100 distinct segments and
                the segment names should be comprehensible to humans.
              </span>
              <br />
              <span>
                Ideally, each segment should be roughly the same size and not
                include too many nor too few users. A good share of users per
                segment is between 2-15% for each segment in the "Share of users
                per segment" chart.
              </span>
              <br />
              <span>
                Ideally, users are in multiple but not too many segments. A good
                number is between 3-15 in the Share of users per Number of
                Segments chart.
              </span>
              <br />
              <span>
                Ideally, "All users" and "Users with matching ID" have similar
                distributions in the "Share of users per number of segments"
                chart.
              </span>
            </Alert>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {shareOfUsersPerSegmentDistribution && (
                <DqCard sx={{ mb: 2, width: "100%" }}>
                  <DqCardContent>
                    <SegmentDistributionChart />
                  </DqCardContent>
                </DqCard>
              )}
              {segmentsPerUserDistributions && (
                <DqCard sx={{ width: "100%" }}>
                  <DqCardContent>
                    <SegmentPerUserDistributionChart />
                  </DqCardContent>
                </DqCard>
              )}
            </Box>
          </Box>
        )}
      {demographicsDataset && demographicsDistributions && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 1,
            width: "100%",
          }}
        >
          <Typography
            alignSelf="flex-start"
            fontWeight={500}
            level="body-md"
            mb={1}
          >
            Demographics
          </Typography>
          <Alert color="info" sx={{ marginBottom: 2 }} variant="outlined">
            <Typography fontWeight={500} level="body-md">
              Quality guidance
            </Typography>
            <span>
              Ideally, for good interpretability in the insights of the Media
              DCR, the number of age groups should be between 4-10 and the
              number of gender groups should be less than 5.
            </span>
            <br />
            <span>
              Ideally, there are &lt;10% of users without any demographics
              information.
            </span>
            <br />
            <span>
              Ideally, the distributions between "All users" and "Users with a
              matching ID" should be similar.
            </span>
            <br />
            <span>
              Ideally, the demographics distribution should be similar to what
              you and your advertiser expect from your audience.
            </span>
          </Alert>
          <DemographicDistributionCharts />
        </Box>
      )}
    </Box>
  );
};

DataLabStatisticsCharts.displayName = "DataLabStatisticsCharts";

export default memo(DataLabStatisticsCharts);
