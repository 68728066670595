import { useBoolean } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  type Keychain,
  type KeychainItem,
  KeychainItemKind,
} from "services/keychain";
import { useKeychain } from "contexts/keychain";

interface KeychainItemsHookPayload {
  kinds?: KeychainItemKind[];
}

interface KeychainItemsHookResult {
  loading: boolean;
  error: null | string;
  items: KeychainItem[];
  hasDatasetMetadataItem: (manifestHash: string) => boolean;
}

// Keychain is a generic javascript client with no reactive properties.
// To allow reactive updates, the hooks needs to subscribe with a listener callback.
// This listener then needs to be removed on unMount to prevent memory leaks.
const useSubscribeToKeychain = <T>({
  updater,
  updaterParams,
}: {
  updater: (keychain: Keychain, params: T) => void;
  updaterParams: T;
}) => {
  const keychain = useKeychain();
  useEffect(() => {
    const updateWithKinds = () => updater(keychain, updaterParams);
    // Register
    const unsubscribe = keychain.registerListener(updateWithKinds);
    // Update for the first time
    updateWithKinds();
    // Unsubscribe to allow the updater to be garbage collected
    return unsubscribe;
  }, [keychain, updater, updaterParams]);
};

export const defaultDatasetKeychainItemKinds = [
  KeychainItemKind.Dataset,
  KeychainItemKind.DatasetMetadata,
];

export const useKeychainItems = ({
  kinds,
}: KeychainItemsHookPayload = {}): KeychainItemsHookResult => {
  const [items, setItems] = useState<KeychainItem[]>([]);
  const [loading, { setTrue: setLoadingTrue, setFalse: setLoadingFalse }] =
    useBoolean(false);
  const [error, setError] = useState<null | string>(null);
  const updater = useCallback(
    async (keychain: Keychain, { kinds }: { kinds?: KeychainItemKind[] }) => {
      setLoadingTrue();
      const items = await keychain.getItems().catch((error) => {
        setError(`Failed to load keychain items: ${error}`);
        return [];
      });
      const filteredItems = kinds
        ? items.filter(({ kind }) => kinds.includes(kind))
        : items;
      setItems(filteredItems);
      setLoadingFalse();
    },
    [setLoadingFalse, setLoadingTrue]
  );
  useSubscribeToKeychain({
    updater,
    updaterParams: useMemo(
      () => ({
        kinds,
      }),
      [kinds]
    ),
  });
  const hasDatasetMetadataItem = useCallback(
    (manifestHash: string) =>
      items.some(
        ({ id, kind }) =>
          manifestHash === id && kind === KeychainItemKind.DatasetMetadata
      ),
    [items]
  );
  return {
    error,
    hasDatasetMetadataItem,
    items,
    loading,
  };
};
