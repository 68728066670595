import type JSZip from "jszip";
import { useCallback, useMemo } from "react";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts/MediaDataRoomContext";
import { useMediaDataRoomInsightsData } from "features/mediaDataRoom/contexts/MediaDataRoomInsightsDataContext";
import {
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  type MediaDataRoomJobResultTransform,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import {
  type Audience,
  type FilteredLookalikeAudience,
  type FilteredLookalikeAudienceROCCurve,
  type LookalikeAudienceStatisticsRaw,
} from "features/mediaDataRoom/models";

export interface LookalikeAudienceStatisticsHookResult {
  estimatedAudienceSize: number | null;
  addressableAudienceSize: number | null;
  seedAudienceSize: number | null;
  audienceQualityScore: number | null;
  testSetPositiveExamplesSize: number | null;
  testSetSize: number | null;
  recall: FilteredLookalikeAudienceROCCurve | null;
  rawStatistics: LookalikeAudienceStatisticsRaw | null;
  computeState: Omit<
    MediaDataRoomJobHookResult<LookalikeAudienceStatisticsRaw>,
    "computeResults"
  >;
}

interface LookalikeAudienceStatisticsHookPayload {
  audienceId: string | undefined;
  audienceReach: number;
  skip?: boolean;
  audiences: Audience[];
}

// Detailed calculation description:
// https://www.notion.so/decentriq/Re-Enable-Beta-Model-Quality-Viz-3-18-2-b49a6106201448aaac1d3c11568910b8?pvs=4#dd6bf187d4f74c4db7c18f4dcba677b0
const calculateAudienceQualityScore = (
  filteredAudiences: FilteredLookalikeAudience[],
  selectedReach: number
): number => {
  const trueFalsePositivePairs: number[][] = (filteredAudiences || []).map(
    ({ quality: { roc_curve } }) => [roc_curve?.tpr, roc_curve?.fpr]
  );
  const reachValues: number[] = (filteredAudiences || []).map(
    ({ reach }) => reach
  );

  const normalizedPrecision = trueFalsePositivePairs.map(
    ([tpr, fpr]) => tpr / (tpr + fpr)
  );
  const maxNormalizedPrecision = Math.max(...normalizedPrecision);

  const offset = 0.5;
  const scaleFactor =
    maxNormalizedPrecision > offset
      ? (1 - offset) / (maxNormalizedPrecision - offset)
      : 1;

  // Calculate quality
  const quality = normalizedPrecision.map(
    (precision) => scaleFactor * (precision - offset) + offset
  );

  // Pair slider position with quality
  const qualityPerReachArray = reachValues.map((reach, index) => [
    quality[index],
    reach,
  ]);

  const [rawQualityScore = 0] =
    qualityPerReachArray.find(([, reach]) => reach === selectedReach) || [];

  return rawQualityScore === 1
    ? rawQualityScore * 10
    : parseFloat((rawQualityScore * 10).toFixed(2));
};

const useLookalikeAudienceStatistics = ({
  audienceId,
  audienceReach,
  skip = false,
  audiences: allAdvertiserAudiences,
}: LookalikeAudienceStatisticsHookPayload): LookalikeAudienceStatisticsHookResult => {
  const {
    dataRoomId,
    driverAttestationHash,
    supportedFeatures: {
      showAbsoluteValues,
      enableExtendedLookalikeStatistics,
    },
  } = useMediaDataRoom();
  const { session, publishedDatasetsHashes } = useMediaDataRoomInsightsData();
  const hasStatisticsEnabled = useMemo(
    () => showAbsoluteValues || enableExtendedLookalikeStatistics,
    [showAbsoluteValues, enableExtendedLookalikeStatistics]
  );
  const transform = useCallback<
    MediaDataRoomJobResultTransform<LookalikeAudienceStatisticsRaw>
  >(async (zip: JSZip) => {
    const audienceStatisticsFile = zip.file("lookalike_audience.json");
    if (audienceStatisticsFile === null) {
      throw new Error("lookalike_audience.json not found in zip");
    }
    const audienceStatisticsFileStructure: LookalikeAudienceStatisticsRaw =
      JSON.parse(await audienceStatisticsFile.async("string"));
    return audienceStatisticsFileStructure;
  }, []);
  const { computeResults, ...jobState } = useMediaDataRoomJob({
    input: MediaDataRoomJobInput.create(
      "getLookalikeAudienceStatistics",
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes
    )
      // Lookalike audience statistics computation depends on everything except audiences dataset
      // TODO: Uncomment it when issue with jobs resolved
      // .withoutAudiencesDependency()
      .withResourceId(audienceId!),
    requestCreator: useCallback(
      (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        generateAudience: session!.compiler.abMedia.getParameterPayloads(
          audienceId!,
          allAdvertiserAudiences
        ).generate,
        scopeIdHex,
      }),
      [session, audienceId, allAdvertiserAudiences]
    ),
    session,
    skip: skip || !audienceId || !hasStatisticsEnabled,
    transform,
  });

  const {
    estimatedAudienceSize,
    addressableAudienceSize,
    seedAudienceSize,
    testSetPositiveExamplesSize,
    testSetSize,
    recall,
  } = useMemo(() => {
    if (!audienceId || !hasStatisticsEnabled || !computeResults) {
      return {
        addressableAudienceSize: null,
        audienceQuality: null,
        estimatedAudienceSize: null,
        qualityScore: null,
        recall: null,
        seedAudienceSize: null,
        testSetPositiveExamplesSize: null,
        testSetSize: null,
      };
    }
    // Estimated Audience Size
    const { size, quality } =
      (computeResults.filtered_audiences || [])?.find(
        (audience) => audienceReach === audience.reach
      ) || {};

    return {
      addressableAudienceSize: computeResults?.addressable_audience_size || 0,
      estimatedAudienceSize: size || 0,
      recall: quality?.roc_curve!,
      seedAudienceSize: computeResults?.seed_audience_size || 0,
      testSetPositiveExamplesSize:
        computeResults?.quality?.roc_curve?.test_set_positive_examples_size ||
        0,
      testSetSize: computeResults?.quality?.roc_curve?.test_set_size || 0,
    };
  }, [audienceId, hasStatisticsEnabled, computeResults, audienceReach]);

  // Quality Score
  const audienceQualityScore = useMemo<number>(
    () =>
      calculateAudienceQualityScore(
        computeResults?.filtered_audiences || [],
        audienceReach
      ),
    [computeResults?.filtered_audiences, audienceReach]
  );

  return {
    addressableAudienceSize,
    audienceQualityScore,
    computeState: jobState,
    estimatedAudienceSize,
    rawStatistics: computeResults || null,
    recall,
    seedAudienceSize,
    testSetPositiveExamplesSize,
    testSetSize,
  };
};

export default useLookalikeAudienceStatistics;
