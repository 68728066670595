import { InfoTooltip } from "@decentriq/components";
import { useSetSyntheticNodeIncludeStatisticsMutation } from "@decentriq/graphql/dist/hooks";
import { testIds } from "@decentriq/utils";
import { Checkbox, Stack } from "@mui/joy";
import { memo, useCallback } from "react";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import useSyntheticNodeIncludeStatistics from "./useSyntheticNodeIncludeStatistics";

type SyntheticNodeIncludeStatisticsCheckboxProps = {
  computeNodeId: string;
  readOnly?: boolean;
};

const SyntheticNodeIncludeStatisticsCheckbox: React.FC<
  SyntheticNodeIncludeStatisticsCheckboxProps
> = ({ computeNodeId, readOnly }) => {
  const { includeReportWithStats } =
    useSyntheticNodeIncludeStatistics(computeNodeId);
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [setSyntheticNodeIncludeStatisticsMutation] =
    useSetSyntheticNodeIncludeStatisticsMutation();
  const setSyntheticNodeIncludeStatistics = useCallback(
    async (includeReportWithStats: boolean) => {
      try {
        return setSyntheticNodeIncludeStatisticsMutation({
          variables: {
            input: {
              id: computeNodeId,
              includeReportWithStats,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The quality report settings could not be updated."
          )
        );
        throw error;
      }
    },
    [setSyntheticNodeIncludeStatisticsMutation, computeNodeId, enqueueSnackbar]
  );
  return (
    <Checkbox
      checked={includeReportWithStats}
      data-testid={testIds.computeNode.computeNodeCreator.includeStatistics}
      disabled={readOnly}
      label={
        <Stack alignItems="center" direction="row">
          Include original data statistics in the quality report{" "}
          <InfoTooltip tooltip="Generating synthetic data brings a quality report with charts of the distribution of each correlation. Having original data in the report facilitates comparison with synthetic data." />
        </Stack>
      }
      onChange={(event) =>
        void setSyntheticNodeIncludeStatistics(event.target.checked)
      }
    />
  );
};

SyntheticNodeIncludeStatisticsCheckbox.displayName =
  "SyntheticNodeIncludeStatisticsCheckbox";

export default memo(SyntheticNodeIncludeStatisticsCheckbox);
