import {
  DqSidePanel,
  DqSidePanelActions,
  DqSidePanelContent,
} from "@decentriq/components";
import { faFileExport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack } from "@mui/joy";
import { memo, useCallback } from "react";
import {
  useAdvertiserAudiences,
  useMediaDataRoom,
} from "features/mediaDataRoom/contexts";
import { type Audience } from "features/mediaDataRoom/models";
import ExportAudienceButton from "../ExportAudienceButton/ExportAudienceButton";
import AudienceDrawerDetails from "./AudienceDrawerDetails";
import AudienceDrawerToolbar from "./AudienceDrawerToolbar";

type MediaDataRoomAudienceDrawerProps = {
  open: boolean;
  onClose: () => void;
  hasExportButton: boolean;
  audience: Audience | null;
  seedAudience: Audience | null;
};

const MediaDataRoomAudienceDrawer: React.FC<MediaDataRoomAudienceDrawerProps> =
  memo(({ onClose, open, audience, hasExportButton, seedAudience }) => {
    const { isPublishingAudience, publishAudience } = useAdvertiserAudiences();
    const { isAdvertiser } = useMediaDataRoom();
    const handlePublishAudience = useCallback(
      () => audience && publishAudience(audience),
      [audience, publishAudience]
    );
    return (
      <DqSidePanel anchor="right" onClose={onClose} open={open}>
        {audience ? (
          <>
            <AudienceDrawerToolbar audience={audience} onClose={onClose} />
            <DqSidePanelContent>
              <AudienceDrawerDetails
                {...audience}
                seedAudience={seedAudience}
              />
            </DqSidePanelContent>
            <DqSidePanelActions>
              <Stack direction="row" spacing={1}>
                {hasExportButton && (
                  <ExportAudienceButton
                    audience={audience}
                    menuPlacement="top"
                  />
                )}
                {isAdvertiser && audience.mutable.status !== "published" ? (
                  <Button
                    color="primary"
                    loading={isPublishingAudience}
                    onClick={handlePublishAudience}
                    startDecorator={
                      <FontAwesomeIcon fixedWidth={true} icon={faFileExport} />
                    }
                    variant="solid"
                  >
                    Grant publisher access
                  </Button>
                ) : null}
              </Stack>
            </DqSidePanelActions>
          </>
        ) : null}
      </DqSidePanel>
    );
  });

MediaDataRoomAudienceDrawer.displayName = "MediaDataRoomAudienceDrawer";

export default MediaDataRoomAudienceDrawer;
