import { faArrowRightArrowLeft as fatArrowRightArrowLeft } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo, useCallback } from "react";

export interface MigrationAvailableDialogProps {
  open: boolean;
  postponeable: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const MigrationAvailableDialog = memo<MigrationAvailableDialogProps>(
  ({ open, onClose, onConfirm, postponeable }) => {
    const onModalClose = useCallback(() => {
      if (postponeable) {
        onClose();
      }
    }, [onClose, postponeable]);
    return (
      <Modal
        disableEscapeKeyDown={!postponeable}
        onClose={onModalClose}
        open={open}
      >
        <ModalDialog role="alertdialog">
          <DialogTitle>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={fatArrowRightArrowLeft}
              size="3x"
            />
            Migration is available
          </DialogTitle>
          <DialogContent>
            <Typography
              component="div"
              level="body-sm"
              sx={{ textWrap: "balance" }}
            >
              We are changing the authentication and secrets management to be
              able to provide you with the latest features. After the migration:
              <ul
                style={{
                  display: "inline-block",
                  margin: "0.5rem 1rem",
                  textAlign: "left",
                  textWrap: "wrap",
                }}
              >
                <li>
                  You must have access to your email account used as user name
                  to avoid being locked out of your account.
                </li>
                <li>
                  If you use the SDK, you will have to install the latest SDK,
                  create new API tokens, and make small code adaptations.
                </li>
              </ul>
              You can learn more{" "}
              <Link
                color="primary"
                href="https://docs.decentriq.com/migrating-to-v4"
                rel="noreferrer"
                tabIndex={0}
                target="_blank"
                underline="hover"
              >
                <strong>here</strong>
              </Link>
              .
            </Typography>
          </DialogContent>
          <DialogActions sx={{ flexDirection: "column", pt: `0 !important` }}>
            <Button
              color="primary"
              loadingIndicator="Migrating..."
              onClick={onConfirm}
              variant="solid"
            >
              Migrate now
            </Button>
            {postponeable && (
              <Button onClick={onClose} size="sm">
                Migrate later
              </Button>
            )}
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }
);

MigrationAvailableDialog.displayName = "MigrationAvailableDialog";
export default MigrationAvailableDialog;
