import { useAuth0 } from "@auth0/auth0-react";
import { utils } from "@decentriq/core";
import { useCallback, useMemo, useState } from "react";
import { itemToEntry } from "services/keychain";
import { useApiCore, useKeychain } from "contexts";
import { getEffectiveErrorMessage, logError, logWarning } from "utils";

const enum MigrationStatus {
  NotStarted,
  Migrating,
  Completed,
}

interface UseKeychainMigrationHookResult {
  migrate: (storageEnclaveToken: string) => Promise<boolean>;
  migrationStatus: MigrationStatus;
  progress: number | null;
}

const useKeychainMigration = (): UseKeychainMigrationHookResult => {
  const { user } = useAuth0();
  const email = user?.email;
  const { client, sessionManager } = useApiCore();
  const keychain = useKeychain();
  const [migrationStatus, setMigrationStatus] = useState<MigrationStatus>(
    MigrationStatus.NotStarted
  );
  const [numberOfKeychainItems, setNumberOfKeychainItems] = useState<
    number | null
  >(null);
  const [numberOfMigratedItems, setNumberOfMigratedItems] = useState<
    number | null
  >(null);
  const migrate = useCallback<
    (storageEnclaveToken: string) => Promise<boolean>
  >(
    async (storageEnclaveToken) => {
      if (!client || !keychain || !email) {
        logError("Migration called before the initialization");
        return false;
      }
      await client.markMigrationStarted().catch(() => {
        logWarning("Migration already started");
      });
      let sessionV2, keychainItems, migratedItems;
      try {
        sessionV2 = await sessionManager.getV2();
        sessionV2.setAuthToken({
          type: "enclave-access",
          value: JSON.parse(storageEnclaveToken).token,
        });
        keychainItems = (await keychain.getItems()).map(itemToEntry);
        setNumberOfKeychainItems(keychainItems.length);
        setMigrationStatus(MigrationStatus.Migrating);
        migratedItems = await client.getKeychainMigratedItems();
        setNumberOfMigratedItems(migratedItems.length);
        const migratedItemsSet = new Set<string>(
          migratedItems.map(({ kind, key }) => `${kind}-${key}`)
        );
        for (const item of keychainItems) {
          if (migratedItemsSet.has(`${item.kind}-${item.key}`)) {
            continue;
          }
          try {
            const secret = utils.keychainEntryToSecret(item, email);
            const secret_id = await sessionV2.createSecret(secret);
            await client.migrateKeychainItem(item.key, item.kind, secret_id);
            setNumberOfMigratedItems((prev) =>
              prev !== null ? prev + 1 : null
            );
          } catch (error) {
            if (/duplicate key value/.test(getEffectiveErrorMessage(error))) {
              logWarning(`Found duplicated secret for item ${item}`);
              continue;
            }
            logError(error);
            return false;
          }
        }
        await client.markMigrationCompleted();
        setMigrationStatus(MigrationStatus.Completed);
        return true;
      } catch (error) {
        logError(error);
        return false;
      }
    },
    [client, email, keychain, sessionManager]
  );
  const progress = useMemo(() => {
    if (
      numberOfKeychainItems === null ||
      numberOfMigratedItems === null ||
      numberOfKeychainItems === 0
    ) {
      return null;
    }
    return (numberOfMigratedItems * 100) / numberOfKeychainItems;
  }, [numberOfKeychainItems, numberOfMigratedItems]);
  return {
    migrate,
    migrationStatus,
    progress,
  };
};

export default useKeychainMigration;
