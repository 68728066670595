import {
  DqSidePanel,
  DqSidePanelActions,
  DqSidePanelContent,
} from "@decentriq/components";
import { faArrowRight as falArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { Fragment, memo } from "react";
import { useNavigate } from "react-router-dom";
import { dataRoomPathBuilder, type DataRoomTypeNames } from "models";
import { DataRoomInformation, DataRoomToolbar } from "./components";

interface DataRoomDrawerProps {
  __typename?: DataRoomTypeNames;
  dataRoomId?: string;
  open: boolean;
  onClose: () => void;
}

const DataRoomDrawer = memo<DataRoomDrawerProps>(
  ({ __typename, dataRoomId, open, onClose }) => {
    const navigate = useNavigate();
    const dataRoomLink = dataRoomPathBuilder(dataRoomId ?? "", __typename!);
    return (
      <DqSidePanel onClose={onClose} open={open}>
        {dataRoomId && __typename ? (
          <Fragment>
            <DataRoomToolbar
              __typename={__typename}
              dataRoomId={dataRoomId}
              onClose={onClose}
            />
            <DqSidePanelContent>
              <DataRoomInformation
                __typename={__typename}
                dataRoomId={dataRoomId}
              />
            </DqSidePanelContent>
            <DqSidePanelActions>
              <Button
                color="primary"
                endDecorator={
                  <FontAwesomeIcon fixedWidth={true} icon={falArrowRight} />
                }
                onClick={() => navigate(dataRoomLink, { replace: false })}
                variant="solid"
              >
                Open
              </Button>
            </DqSidePanelActions>
          </Fragment>
        ) : null}
      </DqSidePanel>
    );
  }
);

DataRoomDrawer.displayName = "DataRoomDrawer";

export default DataRoomDrawer;
