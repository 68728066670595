import {
  useUpdateSqlComputeNodeMutation,
  useUpdateSqliteComputeNodeMutation,
} from "@decentriq/graphql/dist/hooks";
import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import { Box, Stack } from "@mui/joy";
import { useDebounceFn } from "ahooks";
import { Allotment } from "allotment";
import { memo, useCallback } from "react";
import { SqlEditorField } from "components";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { FileExplorer, PrivacyFilterEditor } from "./components";
import useSqlComputeNode from "./useSqlComputeNode";

interface SqlComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
  editorOptions?: object;
  hidePrivacyFilter?: boolean;
  fullHeight?: boolean;
  variant: WorkerTypeShortName.Sqlite | WorkerTypeShortName.Sql;
}

const SqlComputeNodeEditor: React.FC<SqlComputeNodeEditorProps> = memo(
  ({ computeNodeId, readOnly, editorOptions, hidePrivacyFilter, variant }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { node, isLoading } = useSqlComputeNode(computeNodeId);
    const sqlStatement = node?.statement || "";
    const [updateSqlComputeNodeMutation] = useUpdateSqlComputeNodeMutation();
    const [updateSqliteComputeNodeMutation] =
      useUpdateSqliteComputeNodeMutation();
    const updateSqlComputeNode = useCallback(
      async (value: string = "") => {
        try {
          return await (
            variant === WorkerTypeShortName.Sql
              ? updateSqlComputeNodeMutation
              : updateSqliteComputeNodeMutation
          )({
            variables: {
              input: {
                id: computeNodeId,
                statement: value,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "The computation could not be updated."
            )
          );
        }
      },
      [
        variant,
        updateSqlComputeNodeMutation,
        updateSqliteComputeNodeMutation,
        computeNodeId,
        enqueueSnackbar,
      ]
    );
    const { run: debouncedUpdateSqlComputeNode } = useDebounceFn(
      updateSqlComputeNode,
      { wait: 750 }
    );
    return isLoading ? null : (
      <Box
        sx={{
          "--focus-border": "var(--joy-palette-primary-500)",
          "--separator-border": "var(--joy-palette-divider)",
          height: "100%",
          minHeight: "300px",
          overflow: "scroll",
          py: 1,
          resize: "vertical",
        }}
      >
        <Allotment maxSize={Number.POSITIVE_INFINITY} minSize={0}>
          <Allotment.Pane>
            <Stack direction="column" height="100%">
              {!hidePrivacyFilter && (
                <PrivacyFilterEditor
                  computeNodeId={computeNodeId}
                  readOnly={readOnly}
                />
              )}
              <SqlEditorField
                defaultValue={sqlStatement}
                editorOptions={editorOptions}
                onChange={debouncedUpdateSqlComputeNode}
              />
            </Stack>
          </Allotment.Pane>
          {!readOnly ? (
            <Allotment.Pane minSize={200} preferredSize="25%" snap={true}>
              <FileExplorer fullHeight={true} />
            </Allotment.Pane>
          ) : null}
        </Allotment>
      </Box>
    );
  }
);
SqlComputeNodeEditor.displayName = "SqlComputeNodeEditor";

export default SqlComputeNodeEditor;
