import { DqSidePanelTitle } from "@decentriq/components";
import { faTrashCan as faTrashCanLight } from "@fortawesome/pro-light-svg-icons";
import { Box } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import {
  DataLabDeleteDialog,
  DataLabStatusLabel,
  useDataLabContext,
} from "features/dataLabs";

interface DataLabDetailsToolbarProps {
  id: string;
  onClose: () => void;
}

const DataLabDetailsToolbar: React.FC<DataLabDetailsToolbarProps> = ({
  id,
  onClose,
}) => {
  const [
    isDeleteDialogOpen,
    { setTrue: openDeleteDialog, setFalse: closeDeleteDialog },
  ] = useBoolean(false);
  const {
    dataLab: { data: dataLab, loading },
  } = useDataLabContext();
  return (
    <>
      <DqSidePanelTitle
        ToolbarProps={{
          menuItems: [
            {
              dangerous: true,
              icon: faTrashCanLight,
              label: "Delete datalab",
              onClick: openDeleteDialog,
            },
          ],
        }}
        onClose={onClose}
        separated={true}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            gap: 1,
            overflow: "hidden",
          }}
        >
          {loading || !dataLab?.name ? "Loading datalab name..." : dataLab.name}
          <DataLabStatusLabel isValidated={dataLab?.isValidated ?? false} />
        </Box>
      </DqSidePanelTitle>
      <DataLabDeleteDialog
        id={id}
        onCancel={closeDeleteDialog}
        open={isDeleteDialogOpen}
      />
    </>
  );
};

DataLabDetailsToolbar.displayName = "DataLabDetailsToolbar";

export default memo(DataLabDetailsToolbar);
