import { useRollBackKeychainMigrationForUserMutation } from "@decentriq/graphql/dist/hooks";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface RollbackMigrationDialogProps {
  open: boolean;
  userId: string;
  onCancel: () => void;
  onComplete: () => void;
}

const RollbackMigrationDialog: React.FC<RollbackMigrationDialogProps> = ({
  open,
  userId,
  onCancel,
  onComplete,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const [rollbackMigration, { loading: isRollbackUserLoading }] =
    useRollBackKeychainMigrationForUserMutation({
      onCompleted: () => {
        enqueueSnackbar(`User has been successfully rolled back.`);
        onCancel();
        onComplete();
      },
      onError: (error) => {
        onCancel();
        enqueueSnackbar(`User could not be rolled back.`, {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
      variables: {
        userId,
      },
    });
  return (
    <Modal onClose={!isRollbackUserLoading ? onCancel : undefined} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            level="title-md"
            sx={{ textWrap: "balance" }}
            textAlign="center"
          >
            <strong>
              Are you sure you want to roll back keychain migration for this
              user?
            </strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            level="body-sm"
            sx={{ textWrap: "balance" }}
            textAlign="center"
          >
            This action will roll back the keychain migration for this user. Any
            items added to the keychain after the migration will be lost.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={isRollbackUserLoading} onClick={onCancel}>
            Cancel
          </Button>
          <Box sx={{ color: "common.white" }}>
            <Button
              loading={isRollbackUserLoading}
              loadingPosition="start"
              onClick={() => rollbackMigration()}
              startDecorator={
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faExclamationTriangle}
                />
              }
              sx={{ color: "error.main" }}
              variant="solid"
            >
              Roll back migration
            </Button>
          </Box>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
RollbackMigrationDialog.displayName = "RollbackMigrationDialog";

export default RollbackMigrationDialog;
