import { Grid } from "@mui/joy";
import { memo } from "react";
import { EmptyData } from "components";
import AdvertiserAudienceGeneratorHeader from "../AdvertiserAudienceGeneratorStep/AdvertiserAudienceGeneratorHeader";

const AdvertiserAudienceGeneratorEmptyState = memo(() => {
  return (
    <Grid container={true} rowSpacing={4}>
      <Grid xs={7}>
        <AdvertiserAudienceGeneratorHeader />
      </Grid>
      <Grid xs={12}>
        <EmptyData
          description="Please check if the Matching ID is compatible."
          title="There are not enough individuals in the overlap."
        />
      </Grid>
    </Grid>
  );
});

AdvertiserAudienceGeneratorEmptyState.displayName =
  "AdvertiserAudienceGeneratorEmptyState";

export default AdvertiserAudienceGeneratorEmptyState;
