import { useMigrationInfoQuery } from "@decentriq/graphql/dist/hooks";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Link, Stack, Typography } from "@mui/joy";
import { Fragment, memo, useMemo } from "react";
import { useApiCore } from "contexts";
import { DataRoomTypeNames } from "models";
import { useKeychainMigrationContext } from "wrappers";

interface NonMigratedUserDataRoomAccessWrapperProps {
  driverAttestationHash: string;
  __typename: DataRoomTypeNames;
}

const NonMigratedUserDataRoomAccessWrapper = memo<
  React.PropsWithChildren<NonMigratedUserDataRoomAccessWrapperProps>
>(({ children, driverAttestationHash, __typename }) => {
  const { isMigrated, driverMrsignerAttestationSpecHash } = useApiCore();
  const canAccessDataRoom =
    isMigrated || driverAttestationHash !== driverMrsignerAttestationSpecHash;

  const { data: migrationInfo } = useMigrationInfoQuery();
  const showMigrationButton = useMemo(() => {
    return (
      !migrationInfo?.myself?.migrationCompletedAt &&
      migrationInfo?.myself?.needsMigration &&
      migrationInfo?.myself?.organization?.showMigrationPrompt
    );
  }, [migrationInfo]);

  const { onPromptMigrationClick } = useKeychainMigrationContext();

  if (!canAccessDataRoom) {
    const isMediaDcr = [
      DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
      DataRoomTypeNames.PublishedMediaInsightsDcr,
      DataRoomTypeNames.PublishedMediaDataRoom,
    ].includes(__typename);

    const dcrDisplayName = isMediaDcr ? "Media DCR" : "DCR";

    return (
      <Alert
        color="danger"
        startDecorator={<FontAwesomeIcon icon={faExclamationCircle} />}
        sx={{ m: 2 }}
        variant="soft"
      >
        <Stack>
          <strong>DCR incompatible - Please migrate your account</strong>
          <Typography color="danger" level="body-sm">
            This {dcrDisplayName} version is not compatible with your account.
            Please accept the migration shown at login to view and interact with
            this {dcrDisplayName}.
            {showMigrationButton && (
              <Fragment>
                {" "}
                <Link onClick={onPromptMigrationClick}>
                  Click here to migrate now
                </Link>
              </Fragment>
            )}
          </Typography>
        </Stack>
      </Alert>
    );
  }

  return children;
});

NonMigratedUserDataRoomAccessWrapper.displayName =
  "NonMigratedUserDataRoomAccessWrapper";
export default NonMigratedUserDataRoomAccessWrapper;
