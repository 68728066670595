import { useAuth0 } from "@auth0/auth0-react";
import { useMigrationInfoQuery } from "@decentriq/graphql/dist/hooks";
import {
  faArrowsRotate,
  faSignOut,
  faUserCog,
  faUserSlash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Dropdown,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import { useBoolean } from "ahooks";
import { forwardRef, memo, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { DeleteAccountDialog } from "components";
import { useAccountDelete, useSignOut } from "hooks";
import { useKeychainMigrationContext } from "wrappers";

const UserMenu: React.FC = () => {
  const {
    user: { name: currentUserName = "", email: currentUserEmail = "" } = {},
  } = useAuth0();
  const [
    isDeleteAccountDialogOpen,
    { setFalse: closeDeleteAccountDialog, setTrue: openDeleteAccountDialog },
  ] = useBoolean(false);
  const [deleteAccount, isDeleteAccountLoading] = useAccountDelete(
    closeDeleteAccountDialog
  );

  const { data: migrationInfo } = useMigrationInfoQuery();
  const showMigrationButton = useMemo(() => {
    return (
      !migrationInfo?.myself?.migrationCompletedAt &&
      migrationInfo?.myself?.needsMigration &&
      migrationInfo?.myself?.organization?.showMigrationPrompt
    );
  }, [migrationInfo]);

  const { onPromptMigrationClick } = useKeychainMigrationContext();
  const signOut = useSignOut();
  return (
    <>
      <Dropdown>
        <MenuButton
          endDecorator={null}
          slotProps={{ root: { sx: { cursor: "pointer" } } }}
          slots={{
            root: forwardRef(({ ownerState, ...restProps }, ref) => (
              <Box ref={ref} {...restProps} />
            )),
          }}
        >
          <Avatar alt={currentUserName} color="primary">
            {currentUserName.substr(0, 2).toUpperCase()}
          </Avatar>
        </MenuButton>
        <Menu popperOptions={{ placement: "bottom-start" }}>
          <Typography
            level="body-sm"
            sx={({ spacing }) => ({ padding: spacing(0.5, 1.5) })}
          >
            {currentUserEmail}
          </Typography>
          <MenuItem component={NavLink} to={"/tokens"}>
            <ListItemDecorator sx={{ marginInlineEnd: 0, minInlineSize: 0 }}>
              <FontAwesomeIcon icon={faUserCog} />
            </ListItemDecorator>
            API tokens
          </MenuItem>
          {showMigrationButton && (
            <MenuItem onClick={onPromptMigrationClick}>
              <ListItemDecorator sx={{ marginInlineEnd: 0, minInlineSize: 0 }}>
                <FontAwesomeIcon color="error" icon={faArrowsRotate} />
              </ListItemDecorator>
              Migrate keychain
            </MenuItem>
          )}
          <MenuItem color="danger" onClick={openDeleteAccountDialog}>
            <ListItemDecorator sx={{ marginInlineEnd: 0, minInlineSize: 0 }}>
              <FontAwesomeIcon color="error" icon={faUserSlash} />
            </ListItemDecorator>
            Delete account
          </MenuItem>
          <MenuItem onClick={signOut}>
            <ListItemDecorator
              sx={{ marginInlineEnd: 0, marginRight: 0.5, minInlineSize: 0 }}
            >
              <FontAwesomeIcon color="error" icon={faSignOut} />
            </ListItemDecorator>
            Log out
          </MenuItem>
        </Menu>
      </Dropdown>
      <DeleteAccountDialog
        loading={isDeleteAccountLoading}
        onCancel={closeDeleteAccountDialog}
        onConfirm={deleteAccount}
        open={isDeleteAccountDialogOpen}
      />
    </>
  );
};

UserMenu.displayName = "UserMenu";

export default memo(UserMenu);
