// TODO: Fix joy migration
import {
  faExternalLinkSquare,
  faPartyHorn,
  faShieldKeyhole,
  faSliders,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  type Theme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useDocsLink } from "hooks";

const useListItemIconStyles = makeStyles()((theme: Theme) => ({
  root: {
    minWidth: theme.spacing(5),
  },
}));

const useWelcomePopupStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: theme.palette.secondary.main,
  },
  linkButtonIcon: {},
  listIcon: {},
  title: {
    lineHeight: 1.25,
  },
}));

interface WelcomePopupProps {
  open: boolean;
  onClose: () => void;
}

const WelcomePopup = ({ open, onClose }: WelcomePopupProps) => {
  const { classes: welcomePopupClasses } = useWelcomePopupStyles();
  const { classes: listItemIconClasses } = useListItemIconStyles();
  const docsBaseLink = useDocsLink();
  return (
    <Modal open={open}>
      <ModalDialog>
        <DialogContent>
          <Box textAlign="center">
            <FontAwesomeIcon
              className={welcomePopupClasses.icon}
              fixedWidth={true}
              icon={faPartyHorn}
              size="5x"
            />
          </Box>
          <Typography
            align="center"
            className={welcomePopupClasses.title}
            color="textPrimary"
            level="title-md"
            marginTop={2}
          >
            <strong>Welcome on board!</strong>
          </Typography>

          <Typography
            align="left"
            component="div"
            level="body-sm"
            marginTop={2}
          >
            Start using the Decentriq Platform to collaborate and analyse data,
            secured by confidential computing.
            <List>
              <ListItem disableGutters={true}>
                <ListItemIcon classes={listItemIconClasses}>
                  <FontAwesomeIcon
                    className={welcomePopupClasses.listIcon}
                    fixedWidth={true}
                    icon={faShieldKeyhole}
                  />
                </ListItemIcon>
                <ListItemText primary="Create a new data clean room" />
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemIcon classes={listItemIconClasses}>
                  <FontAwesomeIcon
                    className={welcomePopupClasses.listIcon}
                    fixedWidth={true}
                    icon={faSliders}
                  />
                </ListItemIcon>
                <ListItemText primary="Define data, computations and participants" />
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemIcon classes={listItemIconClasses}>
                  <FontAwesomeIcon
                    className={welcomePopupClasses.listIcon}
                    fixedWidth={true}
                    icon={faUsers}
                  />
                </ListItemIcon>
                <ListItemText primary="Publish the data clean room and collaborate" />
              </ListItem>
            </List>
          </Typography>
        </DialogContent>
        <Divider />
        <Button
          component="a"
          href={`${docsBaseLink}/welcome-to-decentriq`}
          target="_blank"
        >
          <Box display="inline" marginRight="0.5rem">
            Getting started
          </Box>
          <FontAwesomeIcon
            className={welcomePopupClasses.linkButtonIcon}
            fixedWidth={true}
            icon={faExternalLinkSquare}
          />
        </Button>
        <Divider />
        <Button
          component="a"
          href="https://www.decentriq.com/end-user-terms"
          target="_blank"
        >
          <Box display="inline" marginRight="0.5rem">
            Read the End User Terms
          </Box>
          <FontAwesomeIcon
            className={welcomePopupClasses.linkButtonIcon}
            fixedWidth={true}
            icon={faExternalLinkSquare}
          />
        </Button>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>GET STARTED</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default WelcomePopup;
