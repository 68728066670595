import { data_connector_job } from "@decentriq/core";
import { type DataConnectorJob } from "@decentriq/core/dist/graphqlGen/graphql";
import { type DataConnectorJobResult } from "@decentriq/graphql/dist/types";
import { type ApiCoreContextValue } from "contexts";
import { type LocalResolverContext } from "../../models";

export function makeDatasetImportResolvers(
  client: ApiCoreContextValue["client"],
  sessionManager: ApiCoreContextValue["sessionManager"],
  _store: ApiCoreContextValue["store"]
) {
  return {
    result: async (
      parent: Partial<DataConnectorJob>,
      _args: null,
      _context: LocalResolverContext,
      _info: any
    ): Promise<DataConnectorJobResult> => {
      if (parent.id === undefined) {
        throw new Error(
          "In order to query the result of a DatasetImport, its id needs to be available"
        );
      }

      const enclaveSpecifications = await client.getEnclaveSpecifications(
        sessionManager.includeMrsigner
      );
      const datasetImportId = parent.id;

      try {
        await data_connector_job.getDataConnectorJobResult({
          client,
          dataConnectorJobId: datasetImportId,
          options: {
            additionalEnclaveSpecs: enclaveSpecifications,
          },
        });
        return {
          error: null,
          success: true,
        };
      } catch (e) {
        return {
          error: e.message,
          success: false,
        };
      }
    },
  };
}
