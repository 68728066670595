import {
  type MatchingColumnFormat,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import {
  faBullhorn,
  faDatabase,
  faDoorOpen,
  faEye,
  faLink,
  faNewspaper,
  faPeopleGroup,
  faUserTie,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Grid, styled, Typography } from "@mui/joy";
import { get } from "lodash";
import { memo, useMemo } from "react";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import {
  type CollaborationTypes,
  RawMediaDataRoomFeaturesToMediaDataRoomFeatures,
} from "models";
import { RawSupportedFeatures } from "../models";

enum MediaDataRoomSupportedFeaturesLabels {
  HideAbsoluteValues = "Hide absolute audience sizes",
  EnableModelPerformanceEvaluation = "Enable model performance evaluation",
  EnableAdvertiserAudienceDownload = "Allow advertiser and agency to export audiences",
  EnableExtendedLalQualityStatistics = "Enable extended lookalike quality statistics",
}

const rawMediaDcrSupportedFeaturesToMediaDcrSupportedFeaturesPresentation: Record<
  RawSupportedFeatures,
  MediaDataRoomSupportedFeaturesLabels
> = {
  [RawSupportedFeatures.ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES]:
    MediaDataRoomSupportedFeaturesLabels.HideAbsoluteValues,
  [RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD]:
    MediaDataRoomSupportedFeaturesLabels.EnableAdvertiserAudienceDownload,
  [RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION]:
    MediaDataRoomSupportedFeaturesLabels.EnableModelPerformanceEvaluation,
};

const ReviewStepIcon = styled(FontAwesomeIcon)(({ theme: { spacing } }) => ({
  marginRight: spacing(1),
}));

const MediaDataRoomSummary: React.FC = () => {
  const {
    loading,
    definition,
    supportedFeatures: { showAbsoluteValues },
    rawSupportedFeatures,
  } = useMediaDataRoom();
  const {
    name,
    publisherEmails = [],
    advertiserEmails = [],
    observerEmails = [],
    agencyEmails = [],
    dataPartnerEmails = [],
    matchingIdFormat = "STRING",
    hashMatchingIdWith = null,
  } = definition ?? {};

  const enabledFeaturesDisplay = useMemo(() => {
    return rawSupportedFeatures?.reduce((acc, feature) => {
      // Hide absolute values feature is removed from the array in order to show it separately in the section below
      if (
        feature === RawSupportedFeatures.ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES
      ) {
        return acc;
      }
      const mappedFeature: CollaborationTypes | null = get(
        RawMediaDataRoomFeaturesToMediaDataRoomFeatures,
        feature,
        null
      );
      if (mappedFeature !== null) {
        acc.push(mappedFeature);
      }
      return acc;
    }, [] as CollaborationTypes[]);
  }, [rawSupportedFeatures]);

  const supportedFeaturesDisplay = useMemo(() => {
    return rawSupportedFeatures?.reduce((acc, feature) => {
      // Hide absolute values feature is removed from the array in order to show it separately in the section below
      if (
        feature === RawSupportedFeatures.ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES
      ) {
        return acc;
      }
      const mappedSupportedFeature: RawSupportedFeatures | null = get(
        rawMediaDcrSupportedFeaturesToMediaDcrSupportedFeaturesPresentation,
        feature,
        null
      );
      if (mappedSupportedFeature) {
        acc.push(mappedSupportedFeature);
      }
      return acc;
    }, [] as MediaDataRoomSupportedFeaturesLabels[]);
  }, [rawSupportedFeatures]);

  if (loading) {
    return <CircularProgress sx={{ "--CircularProgress-size": "32px" }} />;
  }

  return (
    <Grid container={true}>
      <Grid container={true} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faDoorOpen} />
          <Typography sx={{ fontWeight: 500 }}>Name</Typography>
        </Grid>
        <Grid xs={8}>{name || "—"}</Grid>
      </Grid>
      <Grid container={true} mt={1} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faLink} />
          <Typography sx={{ fontWeight: 500 }}>Matching ID</Typography>
        </Grid>
        <Grid xs={8}>
          {matchingIdTypeAndHashingAlgorithmPresentation({
            matchingIdFormat: matchingIdFormat as MatchingColumnFormat,
            matchingIdHashingAlgorithm:
              hashMatchingIdWith as TableColumnHashingAlgorithm,
          })}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faWrench} />
          <Typography sx={{ fontWeight: 500 }}>Collaboration types</Typography>
        </Grid>
        <Grid xs={8}>
          {enabledFeaturesDisplay?.length
            ? enabledFeaturesDisplay.join(", ")
            : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faWrench} />
          <Typography sx={{ fontWeight: 500 }}>
            Additional configurations
          </Typography>
        </Grid>
        <Grid xs={8}>
          {supportedFeaturesDisplay?.length
            ? supportedFeaturesDisplay.join(", ")
            : ""}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faPeopleGroup} />
          <Typography sx={{ fontWeight: 500 }}>
            Absolute audience sizes
          </Typography>
        </Grid>
        <Grid xs={8}>{showAbsoluteValues ? "Show" : "Hide"}</Grid>
      </Grid>
      <Grid container={true} mt={1} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faNewspaper} />
          <Typography sx={{ fontWeight: 500 }}>Publisher</Typography>
        </Grid>
        <Grid xs={8}>
          {publisherEmails.length ? publisherEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faBullhorn} />
          <Typography sx={{ fontWeight: 500 }}>Advertiser</Typography>
        </Grid>
        <Grid xs={8}>
          {advertiserEmails.length ? advertiserEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faEye} />
          <Typography sx={{ fontWeight: 500 }}>Observer</Typography>
        </Grid>
        <Grid xs={8}>
          {observerEmails.length ? observerEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      <Grid container={true} mt={1} xs={12}>
        <Grid alignItems="center" container={true} xs={4}>
          <ReviewStepIcon fixedWidth={true} icon={faUserTie} />
          <Typography sx={{ fontWeight: 500 }}>Agency</Typography>
        </Grid>
        <Grid xs={8}>
          {agencyEmails.length ? agencyEmails.join(", ") : "—"}
        </Grid>
      </Grid>
      {dataPartnerEmails !== null && (
        <Grid container={true} mt={1} xs={12}>
          <Grid alignItems="center" container={true} xs={4}>
            <ReviewStepIcon fixedWidth={true} icon={faDatabase} />
            <Typography sx={{ fontWeight: 500 }}>Data partner</Typography>
          </Grid>
          <Grid xs={8}>
            {dataPartnerEmails.length ? dataPartnerEmails.join(", ") : "—"}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

MediaDataRoomSummary.displayName = "LookalikeMediaDataRoomSummary";

export default memo(MediaDataRoomSummary);
