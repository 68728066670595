import { type ApolloError } from "@apollo/client";
import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";
import {
  DataConnectorJobType,
  type DataImportExportStatus,
  type DataSourceType,
  type DataTargetType,
} from "@decentriq/graphql/dist/types";
import { EXTERNAL_CONNECTIONS_TYPES } from "features/datasets/components/ExternalConnections";

interface ExternalConnectionDataHookResult {
  data: {
    connectionType: DataSourceType | DataTargetType;
    createdAt: string;
    finishedAt: string | null | undefined;
    name: string;
    status: DataImportExportStatus;
    type: EXTERNAL_CONNECTIONS_TYPES;
  } | null;
  loading: boolean;
  error: ApolloError | undefined;
  isDataReady: boolean;
}

const useExternalConnection = (
  connectionId: string | null
): ExternalConnectionDataHookResult => {
  const { data, loading, error } = useDataConnectorJobQuery({
    skip: !connectionId,
    variables: { id: connectionId! },
  });
  if (!connectionId || loading || error || !data) {
    return {
      data: null,
      error,
      isDataReady: false,
      loading,
    };
  }
  const dataConnectorJob = data.dataConnectorJob;
  return {
    data: {
      connectionType: (dataConnectorJob.targetType ??
        dataConnectorJob.sourceType)!,
      createdAt: dataConnectorJob.createdAt,
      finishedAt: dataConnectorJob.finishedAt,
      name: dataConnectorJob?.name,
      status: dataConnectorJob?.status,
      type:
        dataConnectorJob?.type === DataConnectorJobType.ExportDataset
          ? EXTERNAL_CONNECTIONS_TYPES.EXPORT
          : EXTERNAL_CONNECTIONS_TYPES.IMPORT,
    },
    error: undefined,
    isDataReady: true,
    loading: false,
  };
};

export default useExternalConnection;
