import { Stack } from "@mui/joy";
import { Fragment, memo } from "react";
import { OrganizationMigrationDeadline } from "components";
import {
  OrganizationArchiveButton,
  OrganizationDataConnectorConfigurationsEditor,
  OrganizationDetails,
  OrganizationDomainEditor,
  OrganizationEnclaveWorkersEditor,
  OrganizationFeaturesEditor,
  OrganizationLicenseEditor,
  OrganizationLogoEditor,
  OrganizationMediaScopeEditor,
  OrganizationNameEditor,
} from "containers";
import { useUserRole } from "hooks";

const OrganizationForm: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const { isSuperAdmin, isDecentriqAdmin } = useUserRole();
  return (
    <Stack sx={{ maxWidth: 600, p: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <OrganizationLogoEditor organizationId={organizationId} />
        {isSuperAdmin && (
          <OrganizationArchiveButton organizationId={organizationId} />
        )}
      </Stack>
      <OrganizationNameEditor organizationId={organizationId} />
      <OrganizationLicenseEditor organizationId={organizationId} />
      {isDecentriqAdmin && (
        <OrganizationDetails organizationId={organizationId} />
      )}
      <OrganizationEnclaveWorkersEditor organizationId={organizationId} />
      <OrganizationFeaturesEditor organizationId={organizationId} />
      <OrganizationDomainEditor organizationId={organizationId} />
      {isDecentriqAdmin && (
        <Fragment>
          <OrganizationMediaScopeEditor organizationId={organizationId} />
          <OrganizationDataConnectorConfigurationsEditor
            organizationId={organizationId}
          />
          <OrganizationMigrationDeadline organizationId={organizationId} />
        </Fragment>
      )}
    </Stack>
  );
};

OrganizationForm.displayName = "OrganizationForm";

export default memo(OrganizationForm);
