import { Fragment, memo } from "react";
import { UsersList } from "components";

const UsersPage = () => {
  return (
    <Fragment>
      <UsersList />
    </Fragment>
  );
};

export default memo(UsersPage);
