// TODO: Fix joy migration
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, type Theme } from "@mui/material";
import { useLocalStorageState } from "ahooks";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  useAzureAppConfiguration,
  useAzureAppConfigurationFeatureFlag,
} from "hooks";

const useNotificationBannerStyles = makeStyles()((theme: Theme) => ({
  action: {
    paddingTop: "2px",
  },
  message: {
    "& a": {
      "&:visited": {
        color: "inherit",
      },
      color: "inherit",
    },
    color: "white",
    textAlign: "center",
    width: "100%",
  },
  root: {
    background: "rgb(13, 21, 53)",
  },
}));

const NotificationBanner = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes: notificationBannerClasses } = useNotificationBannerStyles();
  const { value: isNotificationBannerEnabled, error: notificationBannerError } =
    useAzureAppConfigurationFeatureFlag({
      flagKey: "notification_banner",
      polling: 1000 * 60 * 5, // 5 minutes
    });
  const [localNotificationBannerRevision, setLocalNotificationBannerRevision] =
    useLocalStorageState<number | undefined>(
      "decentriq.com/notification-banner-revision"
    );

  const {
    value: {
      content: notificationBannerContent,
      revision: notificationBannerRevision,
      dismissible: notificationBannerDismissable,
    },
    error: notificationBannerValuesError,
  } = useAzureAppConfiguration({
    configKey: "notification_banner_values",
    defaultValue: {
      content: "",
      dismissible: false,
      revision: 0,
    },
    polling: 1000 * 60 * 5, // 5 minutes
  });
  const [isNotificationBannerClosed, setIsNotificationBannerClosed] =
    useState<boolean>(false);
  const isNotificationBannerShown =
    isNotificationBannerEnabled &&
    !!notificationBannerRevision &&
    notificationBannerRevision !== localNotificationBannerRevision &&
    !isNotificationBannerClosed;

  useEffect(() => {
    if (notificationBannerValuesError) {
      enqueueSnackbar(
        "Error getting user configurations, if the issue persists please contact support.",
        {
          context: `Error: ${notificationBannerValuesError}`,
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, notificationBannerValuesError]);

  useEffect(() => {
    if (notificationBannerError) {
      enqueueSnackbar(notificationBannerError.message, {
        context: `Error: ${notificationBannerError}`,
        variant: "error",
      });
    }
  }, [enqueueSnackbar, notificationBannerError]);

  const handleNotificationBannerClose = useCallback(() => {
    setIsNotificationBannerClosed(true);
    setLocalNotificationBannerRevision(notificationBannerRevision);
  }, [
    setIsNotificationBannerClosed,
    setLocalNotificationBannerRevision,
    notificationBannerRevision,
  ]);
  return isNotificationBannerShown ? (
    <Alert
      classes={notificationBannerClasses}
      icon={false}
      onClose={
        notificationBannerDismissable
          ? handleNotificationBannerClose
          : undefined
      }
      severity="info"
      slotProps={{ closeButton: { size: "small", sx: { color: "white" } } }}
      slots={{
        closeIcon: () => <FontAwesomeIcon fixedWidth={true} icon={faXmark} />,
      }}
      square={true}
    >
      <span
        dangerouslySetInnerHTML={{ __html: notificationBannerContent }}
      ></span>
    </Alert>
  ) : null;
};

export default NotificationBanner;
