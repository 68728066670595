import {
  DqSortableAccordion,
  DqSortableAccordionDetails,
  DqSortableAccordionSummary,
} from "@decentriq/components";
import {
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  ComputeJobStatus,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { faCode, faCodeSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/joy";
import { Collapse, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useEffect, useMemo } from "react";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
} from "contexts";
import { getConfigExpandActionLabel } from "features/computeNode/utils";
import ComputeNodeResult from "../ComputeNodeResult/ComputeNodeResult";
import {
  ComputeNodeEditor,
  ComputeNodeEditorDialog,
  ComputeNodeToolbar,
  SyntheticDataReportDialog,
} from "../";
import useComputeNode from "./useComputeNode";

const ComputeNodeEditorCollapse = styled(Collapse)({
  ".MuiCollapse-wrapperInner": {
    display: "flex",
    flexDirection: "column",
  },
});

interface ComputeNodeProps {
  computeNodeId: string;
  draggable?: boolean;
}

const ComputeNode = ({
  computeNodeId,
  draggable: isDraggable = false,
  ...rest
}: ComputeNodeProps) => {
  const node = useComputeNode(computeNodeId);
  const { testing } = usePublishedDataRoom();
  const { isPublished } = useDataRoom();
  const computationTypename = node?.computationType;
  const hasEditor = useMemo(() => {
    if (node?.computationType) {
      return [
        WorkerTypeShortName.Python,
        WorkerTypeShortName.R,
        WorkerTypeShortName.Sql,
        WorkerTypeShortName.Sqlite,
        WorkerTypeShortName.Synthetic,
      ].includes(node?.computationType);
    }
    return false;
  }, [node?.computationType]);
  const nodeJob = node?.job;
  const job =
    isPublished &&
    ((testing && nodeJob?.purpose === ComputeJobPurpose.Standard) ||
      (!testing && nodeJob?.purpose === ComputeJobPurpose.Test))
      ? undefined
      : nodeJob;
  const hasResultToFetch =
    job?.status === ComputeJobStatus.Succeded &&
    job?.autoFetching !== ComputeJobAutoFetching.None;
  const hasSdgQualityReport =
    job &&
    job?.status === ComputeJobStatus.Succeded &&
    computationTypename === WorkerTypeShortName.Synthetic;
  const {
    isExpanded,
    isEditorDialogOpened,
    readOnly,
    isSdgQualityReportDialogOpened,
    expand,
    isExpandedConfig,
    toggleConfig,
    toggle,
  } = useComputeNodesVars();
  useEffect(() => {
    if (hasResultToFetch) {
      expand(computeNodeId);
    }
  }, [hasResultToFetch, expand, computeNodeId]);
  const isEditorHidden = !isExpandedConfig(computeNodeId);
  const toggleEditor = useCallback(
    () => toggleConfig(computeNodeId),
    [computeNodeId, toggleConfig]
  );
  const id = computeNodeId;
  const [ThisAccordion, ThisAccordionSummary, ThisAccordionSummaryDetails] =
    isDraggable
      ? [
          DqSortableAccordion,
          DqSortableAccordionSummary,
          DqSortableAccordionDetails,
        ]
      : [Accordion, AccordionSummary, AccordionDetails];
  return (
    <ThisAccordion
      expanded={Boolean(isExpanded(computeNodeId))}
      id={id}
      onChange={() => toggle(computeNodeId)}
      variant="outlined"
      {...rest}
    >
      <ThisAccordionSummary
        id={id}
        slotProps={{
          indicator: { sx: { order: 1, ...(!isDraggable && { ml: 0.5 }) } },
        }}
      >
        <ComputeNodeToolbar computeNodeId={computeNodeId} />
      </ThisAccordionSummary>
      <ThisAccordionSummaryDetails
        slotProps={{
          content: {
            sx: hasEditor
              ? {
                  "--ListItem-paddingLeft": "0",
                  "--ListItem-paddingRight": "0",
                  [`&.${accordionDetailsClasses.expanded}`]: {
                    paddingBlock: "1px 0 !important",
                  },
                }
              : { p: 3 },
          },
          root: {
            sx: {
              borderBottomLeftRadius: "var(--List-radius)",
              borderBottomRightRadius: "var(--List-radius)",
            },
          },
        }}
      >
        {readOnly && hasResultToFetch ? (
          <Button
            onClick={toggleEditor}
            startDecorator={
              <FontAwesomeIcon
                fixedWidth={true}
                icon={isEditorHidden ? faCode : faCodeSimple}
              />
            }
            sx={{ mb: isEditorHidden ? 1 : 0 }}
          >
            {getConfigExpandActionLabel(isEditorHidden, computationTypename)}
          </Button>
        ) : null}
        <ComputeNodeEditorCollapse
          in={!readOnly || !isEditorHidden || !hasResultToFetch}
          timeout="auto"
          unmountOnExit={true}
        >
          {isEditorDialogOpened(computeNodeId) ? (
            <Box
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                padding: "1.5rem 0",
                position: "relative",
              }}
            >
              <Typography level="body-sm" style={{ color: grey["400"] }}>
                Content is being edited on the modal window
              </Typography>
            </Box>
          ) : (
            <ComputeNodeEditor
              computeNodeId={computeNodeId}
              editorOptions={{ resizable: true }}
              fullHeight={true}
            />
          )}
        </ComputeNodeEditorCollapse>
        {hasResultToFetch && (
          <Stack sx={{ pt: 2 }}>
            <ComputeNodeResult
              autoFetching={node!.job!.autoFetching}
              computationTypename={computationTypename!}
              computeNodeId={computeNodeId}
              dcrHash={node!.job!.dataRoomHash!}
              driverAttestationHash={node!.job!.driverAttestationHash}
              jobId={node!.job!.id}
            />
          </Stack>
        )}
        {computationTypename === WorkerTypeShortName.Match ? (
          <Typography level="body-sm" sx={{ mt: readOnly ? 1 : 3 }}>
            This computation matches data from both tables according to
            configurable matching conditions.
          </Typography>
        ) : null}
        {computationTypename === WorkerTypeShortName.Match ? (
          readOnly ? (
            <Typography component="div" level="body-sm" sx={{ mt: 1 }}>
              Notes:
              <ul>
                <li>The combined keys from each group must be unique.</li>
                <li>
                  Results of the Matching Node are not directly available.
                  Access these results by using them in other computations.
                </li>
                <li>
                  Statistics are available to all analysts of the Matching Node.
                </li>
              </ul>
            </Typography>
          ) : (
            <Typography component="div" level="body-sm" sx={{ mt: 1 }}>
              Analysts of this computation will only be able to access
              aggregated matching statistics. To use the matching result, refer
              to it in further computations.
            </Typography>
          )
        ) : null}
      </ThisAccordionSummaryDetails>
      {isEditorDialogOpened(computeNodeId) && (
        <ComputeNodeEditorDialog computeNodeId={computeNodeId} />
      )}
      {hasSdgQualityReport && isSdgQualityReportDialogOpened(computeNodeId) ? (
        <SyntheticDataReportDialog
          computeNodeId={computeNodeId}
          dcrHash={node!.job!.dataRoomHash!}
          driverAttestationHash={node!.job!.driverAttestationHash}
          jobId={node!.job!.id}
          open={isSdgQualityReportDialogOpened(computeNodeId)}
        />
      ) : null}
    </ThisAccordion>
  );
};

export default ComputeNode;
