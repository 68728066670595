import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { memo, type PropsWithChildren, useEffect } from "react";
import { Loading } from "components";
import { useAzureAppConfigurationFeatureFlag } from "hooks";
import { UnderMaintenancePage } from "pages";

const MaintenanceBannerWrapper = withAuthenticationRequired(
  memo(({ children }: PropsWithChildren) => {
    const { enqueueSnackbar } = useSnackbar();
    const { value: isUnderMaintenanceEnabled, error: underMaintenanceError } =
      useAzureAppConfigurationFeatureFlag({
        flagKey: "under_maintenance",
        polling: 1000 * 60 * 5, // 5 minutes
      });

    useEffect(() => {
      if (underMaintenanceError) {
        enqueueSnackbar(
          "Error getting user flag, if the issue persists please contact support.",
          {
            context: `Error: ${underMaintenanceError}`,
            variant: "error",
          }
        );
      }
    }, [enqueueSnackbar, underMaintenanceError]);

    if (isUnderMaintenanceEnabled === null) {
      return <Loading />;
    }

    if (isUnderMaintenanceEnabled) {
      return <UnderMaintenancePage />;
    }

    return children;
  })
);
MaintenanceBannerWrapper.displayName = "MaintenanceBannerWrapper";

export default MaintenanceBannerWrapper;
