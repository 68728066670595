import { data_science, proto } from "@decentriq/core";
import { type EnclaveSpecification } from "@decentriq/core/dist/types";
import {
  type DataRoomAttestationSpec,
  type QueryDataRoomAttestationSpecsArgs,
} from "@decentriq/graphql/dist/types";
import * as forge from "node-forge";
import { type ApiCoreContextValue } from "contexts";
import { isInsecureSpec } from "utils/apicore";
import { type LocalResolverContext } from "../../../models";

const makeDataRoomAttestationSpecsResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (
    parent = null,
    args: QueryDataRoomAttestationSpecsArgs,
    context: LocalResolverContext
  ): Promise<Array<DataRoomAttestationSpec>> => {
    const { dcrHash, driverAttestationHash } = args;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const dataRoomEnclaveSpecs = wrapper.getEnclaveSpecifications();
    const latestEnclaveSpecs: EnclaveSpecification[] =
      await client.getEnclaveSpecifications(sessionManager.includeMrsigner);
    const latestEnclaveSpecsMap = new Map(
      latestEnclaveSpecs.map((i) => {
        const encoded =
          proto.attestation.AttestationSpecification.encodeDelimited(
            i.proto
          ).finish();
        const hash = forge.md.sha256
          .create()
          .update(forge.util.binary.raw.encode(encoded))
          .digest()
          .toHex();
        return [hash, i];
      })
    );
    const usedSpecs: DataRoomAttestationSpec[] = [];
    for (const enclaveSpec of dataRoomEnclaveSpecs) {
      const attestationProto = forge.util.binary.base64.decode(
        enclaveSpec.attestationProtoBase64
      );
      const hash = forge.md.sha256
        .create()
        .update(forge.util.binary.raw.encode(attestationProto))
        .digest()
        .toHex();
      const spec = latestEnclaveSpecsMap.get(hash);
      usedSpecs.push({
        hash,
        id: enclaveSpec.id,
        isInsecure: spec ? isInsecureSpec(spec) : true,
        isUnavailable: !spec,
        name: enclaveSpec.id,
      });
    }
    return usedSpecs;
  };

export default makeDataRoomAttestationSpecsResolver;
