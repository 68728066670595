// TODO: Fix joy migration
import { DqCard, DqCardContent, InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/joy";
import { grey } from "@mui/material/colors";
import { memo, useCallback, useMemo } from "react";
import {
  MediaDataRoomCreationStep,
  useCreationWizardConfiguration,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "features/mediaDataRoom";
import { MatchingIdChip } from "features/mediaDataRoom/containers/MediaDataRoomCreationWizard/components";
import { OrganizationTile } from "features/mediaPortalShared";
import { mediaDataRoomCollaborationTypes } from "models";
import SelectPublisherStepMarketFilters from "./SelectPublisherStepMarketFilters";

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const SelectPublisherStep: React.FC = () => {
  const { handleBackStep, handleNextStep, setActiveStep } =
    useCreationWizardStepper();
  const {
    allowedCollaborationTypes,
    canProceedToConfiguration,
    contactButtonEnabled,
    contactButtonTooltip,
  } = useCreationWizardConfiguration();
  const {
    isAvailablePublishersLoading = false,
    availablePublishers = [],
    selectedPublisher,
    selectPublisher,
    invitePublisher,
  } = useCreationWizardPublisher();
  const handleInviteNewPublisher = useCallback(() => {
    invitePublisher();
    handleNextStep();
  }, [handleNextStep, invitePublisher]);
  const addPublisher = useMemo(
    () => (
      <Grid
        alignItems="center"
        data-testid={
          testIds.mediaInsightsDcr.mediaInsightDcrCreateSelectPublisher
            .invitePublisher
        }
        direction="column"
        key={0}
        lg={3}
        md={4}
        xs={6}
      >
        <DqCard
          onSelect={handleInviteNewPublisher}
          selected={false}
          sx={{ height: "100%", minHeight: 120, width: "100%" }}
        >
          <DqCardContent>
            <StyledIcon fixedWidth={true} icon={faNewspaper} size="3x" />
            <Typography
              color="initial"
              fontWeight={500}
              level="body-sm"
              textAlign="center"
            >
              + Invite publisher
            </Typography>
          </DqCardContent>
        </DqCard>
      </Grid>
    ),
    [handleInviteNewPublisher]
  );
  let content: React.ReactNode;
  if (isAvailablePublishersLoading) {
    content = (
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        height="200px"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  } else if (!availablePublishers?.length) {
    content = (
      <Stack alignItems="center" height="200px" justifyContent="center">
        {addPublisher}
      </Stack>
    );
  } else {
    content = (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography fontWeight="fontWeightSemiBold" level="body-md" mb={1}>
          Select publisher
        </Typography>
        <SelectPublisherStepMarketFilters />
        <Box
          mb={2}
          sx={{
            borderColor: grey[300],
            borderStyle: "solid",
            borderWidth: 2,
            maxHeight: "250px",
            minHeight: "130px",
            overflowY: "auto",
            p: 1.5,
          }}
        >
          <Grid columnSpacing={2} container={true} rowSpacing={2}>
            {addPublisher}
            {availablePublishers.map((publisher) => {
              const { id, publisherLogo, publisherName, marketIds } = publisher;
              const isSelected = id === selectedPublisher?.id;
              return (
                <Grid
                  alignItems="center"
                  direction="column"
                  key={id}
                  lg={3}
                  md={4}
                  xs={6}
                >
                  <OrganizationTile
                    logo={publisherLogo}
                    marketIds={marketIds}
                    name={publisherName}
                    onSelect={() => selectPublisher(publisher)}
                    selected={isSelected}
                    sx={{ height: "100%", minHeight: 120, width: "100%" }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box sx={{ overflowY: "auto" }}>
          {selectedPublisher?.publisherDescription ? (
            <Box mb={2}>
              <Typography
                fontWeight={"fontWeightSemiBold"}
                level="body-md"
                mb={0.5}
              >
                Description
              </Typography>
              <Typography>{selectedPublisher.publisherDescription}</Typography>
            </Box>
          ) : null}
          {canProceedToConfiguration && (
            <>
              <MatchingIdChip />
              <Box>
                <Typography
                  fontWeight={"fontWeightSemiBold"}
                  level="body-md"
                  mb={0.5}
                >
                  Supported collaboration types
                  <InfoTooltip tooltip="If you are interested in a collaboration type which is not yet supported, please contact the publisher using the button to the right." />
                </Typography>
                <Box>
                  {mediaDataRoomCollaborationTypes.map((feature) => (
                    <Chip
                      disabled={!allowedCollaborationTypes.includes(feature)}
                      key={feature}
                      sx={{ mr: 1 }}
                    >
                      {feature}
                    </Chip>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <Divider />
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        {content}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleBackStep}>Back</Button>
        {!canProceedToConfiguration && contactButtonEnabled ? (
          <Tooltip title={contactButtonTooltip}>
            <div>
              <Button
                color="primary"
                data-testid={
                  testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                    .requestCollaborationButton
                }
                onClick={() => {
                  setActiveStep(
                    MediaDataRoomCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER
                  );
                }}
                variant="solid"
              >
                Contact publisher
              </Button>
            </div>
          </Tooltip>
        ) : null}
        {canProceedToConfiguration && (
          <Button
            color="primary"
            data-testid={
              testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard.submitButton
            }
            onClick={handleNextStep}
            variant="solid"
          >
            Continue
          </Button>
        )}
      </DialogActions>
    </>
  );
};

SelectPublisherStep.displayName = "SelectPublisherStep";

export default memo(SelectPublisherStep);
