import { Box } from "@mui/joy";
import { memo } from "react";
import { useExternalConnection } from "../ExternalConnections/hooks";

interface ExternalConnectionNameProps {
  connectionId: string | null;
}

const ExternalConnectionName = memo<ExternalConnectionNameProps>(
  ({ connectionId }) => {
    const { isDataReady, data } = useExternalConnection(connectionId);
    return isDataReady ? (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: 1,
          overflow: "hidden",
        }}
      >
        {data!.name}
      </Box>
    ) : (
      "Loading name..."
    );
  }
);

ExternalConnectionName.displayName = "ExternalConnectionName";

export default ExternalConnectionName;
