import {
  type ApiCoreSessionManager,
  type Client,
  type SessionV2,
} from "@decentriq/core";
import { createContext } from "react";
import { useSafeContext } from "hooks";

export interface ApiCoreStore {
  get: <T = any>(key: string) => T | undefined;
  pop: <T = any>(key: string) => T | undefined;
  popStrict: <T = any>(key: string) => T;
  push: (key: any) => string;
  reset: () => void;
}

export const enum MigrationStatus {
  Migrated = "Migrated",
  NoMigration = "NoMigration",
  PromptMigration = "PromptMigration",
  MigrationInProgress = "MigrationInProgress",
}

export interface ApiCoreContextValue {
  client: Client;
  // TODO: This is temporary method to get authenticated session v2, more elegant solution should be created
  getSessionV2: () => Promise<SessionV2>;
  isMigrated: boolean;
  migrationDeadline: Date;
  migrationStatus: MigrationStatus;
  driverMrsignerAttestationSpecHash: string;
  reset: () => void;
  sessionManager: ApiCoreSessionManager;
  setMigrationStatus: (status: MigrationStatus) => void;
  store: ApiCoreStore;
}

export const ApiCoreContext = createContext<ApiCoreContextValue | null>(null);

export const ApiCoreProvider = ApiCoreContext.Provider;

ApiCoreContext.displayName = "ApiCoreContext";

export const useApiCore = () => useSafeContext(ApiCoreContext);

export default useApiCore;
