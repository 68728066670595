import { DqAudienceSummary } from "@decentriq/components";
import { memo, useMemo } from "react";
import { type RuleBasedAudience } from "features/mediaDataRoom/models";
import { mapRulesBasedAudiencetoAudienceRulesTree } from "features/mediaDataRoom/utils";
import { useAudiences } from "features/mediaDataRoom/wrappers";

type AudienceDrawerRuleBasedDetailsProps = RuleBasedAudience;

const AudienceDrawerRuleBasedDetails: React.FC<AudienceDrawerRuleBasedDetailsProps> =
  memo((audience) => {
    const { audiences } = useAudiences();
    const audienceTree = useMemo(
      () =>
        mapRulesBasedAudiencetoAudienceRulesTree(
          audience,
          audiences.computeResults || []
        ),
      [audience, audiences]
    );
    return <DqAudienceSummary audienceTree={audienceTree} />;
  });

AudienceDrawerRuleBasedDetails.displayName = "AudienceDrawerRuleBasedDetails";

export default AudienceDrawerRuleBasedDetails;
