import { DqSidePanelTitle } from "@decentriq/components";
import { faTrashCan as faTrashCanLight } from "@fortawesome/pro-light-svg-icons";
import { Box } from "@mui/joy";
import { memo } from "react";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import { type Audience } from "features/mediaDataRoom/models";
import AudienceName from "../AudienceName/AudienceName";
import DeleteAudienceButton from "../DeleteAudienceButton/DeleteAudienceButton";

type AudienceDrawerToolbarProps = {
  onClose: () => void;
  audience: Audience;
};

const AudienceDrawerToolbar: React.FC<AudienceDrawerToolbarProps> = memo(
  ({ audience, onClose }) => {
    const { isAdvertiser } = useMediaDataRoom();
    return (
      <DeleteAudienceButton
        audience={audience}
        renderTrigger={(openDeleteAudienceDialog) => (
          <DqSidePanelTitle
            ToolbarProps={{
              menuItems: isAdvertiser
                ? [
                    {
                      icon: faTrashCanLight,
                      label: "Delete audience",
                      onClick: openDeleteAudienceDialog,
                    },
                  ]
                : [],
            }}
            onClose={onClose}
            separated={true}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <AudienceName kind={audience.kind} name={audience.mutable.name} />
            </Box>
          </DqSidePanelTitle>
        )}
      />
    );
  }
);

AudienceDrawerToolbar.displayName = "AudienceDrawerToolbar";

export default AudienceDrawerToolbar;
