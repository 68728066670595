import {
  useUpdateUserMutation,
  useUserDetailsQuery,
} from "@decentriq/graphql/dist/hooks";
import { faXmark as falXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, FormControl, FormLabel, IconButton, Input } from "@mui/joy";
import { Fragment, memo, useCallback } from "react";

interface UserMigrationSettingsProps {
  userId: string;
}

const UserMigrationSettings = memo(({ userId }: UserMigrationSettingsProps) => {
  const { data } = useUserDetailsQuery({
    variables: { userId },
  });

  const [updateUserMutation] = useUpdateUserMutation();

  const needsMigration = data?.user?.needsMigration;
  const migrationPromptedAt = data?.user?.migrationPromptedAt ?? "";

  const deleteMigrationPromptedAt = useCallback(() => {
    updateUserMutation({
      variables: {
        input: {
          migrationPromptedAt: {
            value: null,
          },
          userId,
        },
      },
    });
  }, [updateUserMutation, userId]);

  const onNeedsMigrationChange = useCallback(() => {
    updateUserMutation({
      variables: {
        input: {
          needsMigration: !needsMigration,
          userId,
        },
      },
    });
  }, [needsMigration, updateUserMutation, userId]);

  return (
    <Fragment>
      <FormControl>
        <Checkbox
          checked={!!needsMigration}
          label="Needs migration"
          name="needsMigration"
          onChange={onNeedsMigrationChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Migration prompted at</FormLabel>
        <Input
          endDecorator={
            <IconButton
              disabled={!migrationPromptedAt}
              onClick={deleteMigrationPromptedAt}
            >
              <FontAwesomeIcon icon={falXmark} />
            </IconButton>
          }
          name="migrationPrompotedAt"
          placeholder="Migration prompted at"
          slotProps={{
            input: {
              disabled: true,
            },
          }}
          value={migrationPromptedAt}
        />
      </FormControl>
    </Fragment>
  );
});

UserMigrationSettings.displayName = "UserMigrationSettings";

export default UserMigrationSettings;
