import { differenceInSeconds, format, isValid } from "date-fns";
import { type FC, memo } from "react";
import { TimeAgoFormatted } from "components";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";

// TODO: Move it even higher in the feature after the research what format and where is used
const AUDIENCE_DATE_FORMAT = "dd/MM/yyyy HH:mm";

interface AudienceCreationDateProps {
  createdAt: unknown;
}

const AudienceCreationDate: FC<AudienceCreationDateProps> = memo(
  ({ createdAt }) => {
    const { createdAt: dataRoomCreatedAt } = useMediaDataRoom();
    if (!createdAt || !isValid(new Date(createdAt as string))) {
      // TODO: Eventually that date will need to come either from ddc or from the backend with correct date
      // when all of the needed datasests were provisioned
      return dataRoomCreatedAt
        ? format(new Date(dataRoomCreatedAt), AUDIENCE_DATE_FORMAT)
        : "N/A";
    }
    const now = new Date();
    const createdAtDate = new Date(createdAt as string);
    const isOlderThanAMinute = differenceInSeconds(now, createdAtDate) > 59;
    return isOlderThanAMinute ? (
      format(createdAtDate, AUDIENCE_DATE_FORMAT)
    ) : (
      <TimeAgoFormatted date={createdAt as string} />
    );
  }
);

AudienceCreationDate.displayName = "AudienceCreationDate";
export default AudienceCreationDate;
