// TODO: Fix joy migration
import { useCollaboratingOrganizationsQuery } from "@decentriq/graphql/dist/hooks";
import { type OrganizationDataRoomFilter } from "@decentriq/graphql/dist/types";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Option, Select } from "@mui/joy";
import { memo, useEffect, useMemo, useState } from "react";

type CollaboratingOrganizationSelectProps = {
  organizationId: string;
  setQueryFilter: (filter: OrganizationDataRoomFilter) => void;
};

const CollaboratingOrganizationSelect: React.FC<
  CollaboratingOrganizationSelectProps
> = ({ organizationId, setQueryFilter }) => {
  const [selectedCollaboratingOrganizationId, selectCollaboratingOrganization] =
    useState<string | null>(null);
  const { data } = useCollaboratingOrganizationsQuery({
    variables: { filter: {}, organizationId },
  });

  const collaboratingOrganizationsOptions = useMemo(
    () =>
      (data?.organization?.collaboratingOrganizations?.nodes || [])
        .map(({ organization }) => ({
          id: organization?.id,
          label: organization?.name,
        }))
        // array is frozen in strict mode so it has to be copied before sorting
        .slice()
        .sort((a, b) => a?.label?.localeCompare(b?.label)),
    [data]
  );

  // Set backend filter whenever value is changed
  useEffect(() => {
    setQueryFilter({
      participantOrganizationIdEquals: selectedCollaboratingOrganizationId,
    });
  }, [selectedCollaboratingOrganizationId, setQueryFilter]);

  return collaboratingOrganizationsOptions.length > 0 ? (
    <Select
      endDecorator={
        selectedCollaboratingOrganizationId ? (
          <IconButton
            onClick={() => selectCollaboratingOrganization(null)}
            variant="outlined"
          >
            <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
          </IconButton>
        ) : null
      }
      indicator={selectedCollaboratingOrganizationId ? null : undefined}
      onChange={(event, value) => {
        selectCollaboratingOrganization(value);
      }}
      placeholder="No collaborating organization selected"
      renderValue={(option) => {
        return (
          collaboratingOrganizationsOptions.find(
            ({ id }) => id === option?.value
          )?.label || "No collaborating organization selected"
        );
      }}
      value={selectedCollaboratingOrganizationId}
    >
      {collaboratingOrganizationsOptions.map(({ id, label }) => (
        <Option key={id} value={id}>
          {label}
        </Option>
      ))}
    </Select>
  ) : null;
};
CollaboratingOrganizationSelect.displayName = "CollaboratingOrganizationSelect";

export default memo(CollaboratingOrganizationSelect);
