import { DqSidePanelTitle } from "@decentriq/components";
import { faStar as falStar } from "@fortawesome/pro-light-svg-icons";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tooltip } from "@mui/joy";
import { memo, useCallback } from "react";
import {
  DataRoomActions,
  DataRoomActionTypes,
  usePublishedDataRoomIsFavorite,
} from "features";
import { useDraftDataRoomIsFavorite } from "features/draftDataRoom/components/DraftDataRoomActions/hooks";
import { useDataRoomOverviewData } from "hooks";
import { DataRoomTypeNames } from "models";

interface DataRoomToolbarProps {
  dataRoomId: string;
  __typename: DataRoomTypeNames;
  onClose: () => void;
}

const DataRoomToolbar = memo<DataRoomToolbarProps>(
  ({ dataRoomId, __typename, onClose }) => {
    const { data: overviewData } = useDataRoomOverviewData(
      dataRoomId,
      __typename
    );
    const { setDraftDataRoomIsFavorite } = useDraftDataRoomIsFavorite({
      id: dataRoomId,
      skip: __typename !== DataRoomTypeNames.DraftDataRoom,
    });
    const { setPublishedDataRoomIsFavorite } = usePublishedDataRoomIsFavorite({
      __typename: DataRoomTypeNames.PublishedDataRoom,
      id: dataRoomId,
      skip: __typename === DataRoomTypeNames.DraftDataRoom,
    });
    const toggleFavorite = useCallback(
      (isFavorite: boolean) => {
        if (dataRoomId) {
          switch (__typename) {
            case DataRoomTypeNames.DraftDataRoom:
              return setDraftDataRoomIsFavorite(isFavorite);
            case DataRoomTypeNames.PublishedDataRoom:
            case DataRoomTypeNames.PublishedMediaInsightsDcr:
              return setPublishedDataRoomIsFavorite(isFavorite);
            default:
              return null;
          }
        } else {
          return null;
        }
      },
      [
        __typename,
        dataRoomId,
        setDraftDataRoomIsFavorite,
        setPublishedDataRoomIsFavorite,
      ]
    );
    const { name, isFavorite } = overviewData || {};
    const dataRoomToolbarActions = ({
      buttons,
      menuLists,
    }: {
      buttons: any[];
      menuLists: any[];
    }) => ({
      buttons: [
        ...buttons.filter(
          ({ type }: any) =>
            ![
              DataRoomActionTypes.Publish,
              DataRoomActionTypes.ToggleFavorite,
            ].includes(type)
        ),
      ],
      menuLists,
    });
    return (
      <DqSidePanelTitle onClose={onClose} separated={true}>
        <Box
          sx={{
            alignItems: "baseline",
            display: "flex",
            flex: 1,
            gap: 1,
          }}
        >
          {name}
          <Tooltip title={`${isFavorite ? "Remove from" : "Add to"} favorites`}>
            <IconButton onClick={() => void toggleFavorite(!isFavorite)}>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={isFavorite ? fasStar : falStar}
              />
            </IconButton>
          </Tooltip>
          {/* The component here is used instead of ToolbarProps for DqSidePanelTitle in order 
        to unify DCRs actions around the platform and avoid duplicating logic */}
          <Box marginLeft="auto">
            <DataRoomActions
              __typename={__typename}
              actions={dataRoomToolbarActions}
              id={dataRoomId}
            />
          </Box>
        </Box>
      </DqSidePanelTitle>
    );
  }
);

DataRoomToolbar.displayName = "DataRoomToolbar";

export default DataRoomToolbar;
