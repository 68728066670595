import { type Session } from "@decentriq/core";
import type JSZip from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type PublishedDatasetsHashes } from "../../models";

interface AdvertiserDataReportHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  publishedDatasetsHashes: PublishedDatasetsHashes;
  session?: Session;
  skip: boolean;
}

const useAdvertiserDataReport = ({
  session,
  dataRoomId,
  driverAttestationHash,
  publishedDatasetsHashes,
  skip,
}: AdvertiserDataReportHookPayload): MediaDataRoomJobHookResult<{
  numberOfDeduplicatedRows: number;
  numberOfIngestedRows: number;
}> => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const customDatasetsHashes = useMemo(
    () =>
      publishedDatasetsHashes
        // Advertiser data report computation depends only on advertiser dataset so only that used for caching
        // TODO: uncomment when issue with jobs resolved
        //.updateAudiencesDatasetHash(null)
        .updateDatasetsHash(
          publishedDatasetsHashes.advertiserDatasetHash
            ? {
                advertiserDatasetHash:
                  publishedDatasetsHashes.advertiserDatasetHash,
                // TODO: remove this when issue with jobs resolved
                demographicsDatasetHash:
                  publishedDatasetsHashes.publisherDatasetsHashes
                    .demographicsDatasetHash ?? "",
                embeddingsDatasetHash:
                  publishedDatasetsHashes.publisherDatasetsHashes
                    .embeddingsDatasetHash ?? "",
                matchingDatasetHash:
                  publishedDatasetsHashes.publisherDatasetsHashes
                    .matchingDatasetHash ?? "",
                segmentsDatasetHash:
                  publishedDatasetsHashes.publisherDatasetsHashes
                    .segmentsDatasetHash ?? "",
              }
            : null
        ),
    [publishedDatasetsHashes]
  );
  const transform = useCallback(async (zip: JSZip) => {
    const reportFile = zip.file("report.json");
    if (reportFile === null) {
      throw new Error("report.json not found in zip");
    }
    const report:
      | { num_ingested_rows: number; num_deduplicated_rows: number }
      | undefined = JSON.parse(await reportFile.async("string"))["audiences"];
    if (
      !report ||
      report.num_deduplicated_rows === undefined ||
      report.num_ingested_rows === undefined
    ) {
      throw new Error("Advertiser data report is missing");
    }
    return {
      numberOfDeduplicatedRows: report.num_deduplicated_rows,
      numberOfIngestedRows: report.num_ingested_rows,
    };
  }, []);
  const computeJob = useMediaDataRoomJob({
    input: MediaDataRoomJobInput.create(
      "ingestAudiencesReport",
      dataRoomId,
      driverAttestationHash,
      customDatasetsHashes
    ),
    requestCreator: useCallback(
      (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        scopeIdHex,
      }),
      []
    ),
    session,
    skip,
    transform,
  });
  useEffect(() => {
    const error = computeJob.error;
    if (error) {
      const key = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          `Cannot fetch Advertiser data report`
        )
      );
      setErrorSnackbarId(key);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [closeSnackbar, computeJob.error, enqueueSnackbar, setErrorSnackbarId]);

  return computeJob;
};

export default useAdvertiserDataReport;
