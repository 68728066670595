import { faAngleDown, faFileContract } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, IconButton, Menu, MenuItem } from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { Fragment, memo, useState } from "react";
import { usePopupState } from "hooks";
import { bindToggle } from "hooks/usePopupState/usePopupState";

const submitForApprovalMenuOptions: { label: string }[] = [
  { label: "Submit for approval" },
  { label: "Submit for approval without validation" },
];

interface SubmitForApprovalButtonProps {
  disabled: boolean;
  loading: boolean;
  onClick: (validate: boolean) => void;
}

const SubmitForApprovalButton: React.FC<SubmitForApprovalButtonProps> = ({
  onClick,
  disabled,
  loading,
}) => {
  const popupId = "submit-for-approval-strategy-menu";
  const popupState = usePopupState({ popupId });
  const { anchorEl, isOpen, close } = popupState;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const onButtonClick = () => onClick(selectedIndex === 0);
  const onMenuItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    close();
    setSelectedIndex(index);
  };
  return (
    <Fragment>
      <ButtonGroup role="group" sx={{ "--ButtonGroup-separatorSize": "0px" }}>
        <Button
          color="primary"
          disabled={disabled || loading}
          onClick={onButtonClick}
          startDecorator={<FontAwesomeIcon icon={faFileContract} />}
          variant="soft"
        >
          {submitForApprovalMenuOptions[selectedIndex].label}
        </Button>
        <IconButton color="primary" variant="soft" {...bindToggle(popupState)}>
          <FontAwesomeIcon icon={faAngleDown} />
        </IconButton>
      </ButtonGroup>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={(event) => {
          if ((event.target as HTMLElement).closest("button") !== anchorEl) {
            close();
          }
        }}
      >
        <Menu
          anchorEl={anchorEl}
          id={popupId}
          onClose={close}
          open={isOpen}
          placement="bottom-end"
        >
          {submitForApprovalMenuOptions.map(({ label }, index) => {
            return (
              <MenuItem
                key={label}
                onClick={(event) => onMenuItemClick(event, index)}
              >
                {label}
              </MenuItem>
            );
          })}
        </Menu>
      </ClickAwayListener>
    </Fragment>
  );
};

SubmitForApprovalButton.displayName = "SubmitForApprovalButton";

export default memo(SubmitForApprovalButton);
