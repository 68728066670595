import { Alert, Box, Link, Typography } from "@mui/joy";
import { useDocsLink } from "hooks";
import AccessTokensConstructor from "./AccessTokensConstructor";

const AccessTokens: React.FC = () => {
  const docsBaseLink = useDocsLink();
  return (
    <Box
      sx={({ spacing }) => ({ margin: spacing(2, 2.5), overflow: "hidden" })}
    >
      <Typography level="title-md" marginBottom={1}>
        API tokens
      </Typography>
      <Alert>
        <Typography level="body-sm">
          The access tokens created on this page can be used in our SDKs to
          authenticate with the Decentriq Platform. For more details, please
          refer{" "}
          <Link
            color="neutral"
            href={`${docsBaseLink}/sdk/getting-started`}
            rel="noreferrer"
            target="_blank"
            underline="always"
          >
            to the Python SDK documentation.
          </Link>{" "}
          If you are looking for previously created tokens, please refer to the{" "}
          <Link color="neutral" href="/legacy-tokens" underline="always">
            old API tokens page.
          </Link>
        </Typography>
      </Alert>
      <AccessTokensConstructor />
    </Box>
  );
};

export default AccessTokens;
