import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  type FetchResult,
  from,
  InMemoryCache,
  type NormalizedCacheObject,
  Observable,
  type Operation,
  split,
  type TypePolicies,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import { getOperationDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ComputeJobAutoFetching,
  MediaPublishDraftDataRoomDocument,
} from "@decentriq/graphql/dist/types";
import DebounceLink from "apollo-link-debounce";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import { type Mutex } from "async-mutex";
import { print } from "graphql";
import { type Client, type ClientOptions, createClient } from "graphql-ws";
import { memo, useCallback, useEffect, useState } from "react";
import { type Keychain } from "services";
import { Loading } from "components";
import { useApiCore, useConfiguration } from "contexts";
import {
  DraftComputeNodeTypeNames,
  DraftDataNodeTypeNames,
  PublishedComputeNodeTypeNames,
  PublishedDataNodeTypeNames,
} from "models";
import { logError, logInfo } from "utils";
import { makeDraftComputationNodeResolvers } from "./resolvers/DraftComputationNode";
import {
  makeDatasetExportResolvers,
  makeDatasetImportResolvers,
  makeMutationResolvers,
  makePublishedComputationNodeResolvers,
  makePublishedDataRoomResolvers,
  makePublishedLeafNodeResolvers,
  makePublishedMediaDataRoomResolvers,
  makeQueryResolvers,
  makeSubmittedDataRoomRequestResolvers,
} from "./resolvers";

class NetworkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NetworkError";
  }
}

class WebSocketLink extends ApolloLink {
  client: Client;
  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }
  request(operation: Operation): Observable<FetchResult> {
    return new Observable((observer) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          complete: observer.complete.bind(observer),
          error: (error: any) => {
            if (error instanceof Error) {
              observer.error(error);
            } else if (error instanceof CloseEvent) {
              observer.error(
                new NetworkError(
                  `Socket closed with event ${error.code}` + error.reason
                    ? `: ${error.reason}`
                    : ""
                )
              );
            } else {
              observer.error(
                new NetworkError(
                  error.map((err: any) => err.message).join(", ")
                )
              );
            }
          },
          next: observer.next.bind(observer),
        }
      );
    });
  }
}

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) =>
      logError(
        `[GraphQL error]: Operation: ${operation.operationName} Message: ${message}, Path: ${path}`
      )
    );
  }
});

const ApolloWrapper = memo<
  React.PropsWithChildren & {
    getKeychainSharedInstance: () => Keychain | null;
  }
>(({ children, getKeychainSharedInstance }) => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const { client: apiCoreClient, sessionManager, store } = useApiCore();
  const {
    graphqlUrl,
    websocketUrl,
    clientLogRequests: logRequests,
  } = useConfiguration();
  const [client, setClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >();
  const getKeychain = useCallback(() => {
    const keychain = getKeychainSharedInstance();
    if (keychain === null) {
      throw Error("Unable to use Keychain before it was set");
    }
    return keychain;
  }, [getKeychainSharedInstance]);
  useEffect(() => {
    const webSocketLink = new WebSocketLink({
      connectionParams: async () => {
        const token = await getAccessTokenSilently();
        if (token) {
          return {
            headers: {
              Authorization: `Bearer ${token}`,
              "Authorization-Type": "user",
            },
          };
        }
        return undefined;
      },
      retryAttempts: Infinity,
      url: websocketUrl?.replace(/\/$/, ""),
    });
    const httpLink = new BatchHttpLink({
      // Wait no more than 20ms after first batched operation
      batchInterval: 20,
      // No more than 20 operations per batch
      batchMax: 20,
      fetch: async (uri, options) => {
        const token = await getAccessTokenSilently();
        if (token) {
          options = {
            ...options,
            headers: {
              ...options?.headers,
              Authorization: `Bearer ${token}`,
              "Authorization-Type": "user",
            },
          };
        }
        return fetch(uri, options);
      },
      uri: graphqlUrl?.replace(/\/$/, ""),
    });
    const debounceLink = new DebounceLink(100);
    const transportLink = split(
      ({ query }) => {
        const definition = getOperationDefinition(query);
        return (
          definition?.kind === "OperationDefinition" &&
          definition?.operation === "subscription"
        );
      },
      webSocketLink,
      httpLink
    );
    const possibleTypes = {
      DraftComputationNode: [
        "DraftPostNode",
        "DraftPreviewNode",
        "DraftScriptingNode",
        "DraftSqlNode",
        "DraftSqliteNode",
        "DraftMatchNode",
        "DraftS3SinkNode",
        "DraftSyntheticNode",
      ],
      DraftNode: [
        "DraftPostNode",
        "DraftPreviewNode",
        "DraftRawLeafNode",
        "DraftTableLeafNode",
        "DraftScriptingNode",
        "DraftSqlNode",
        "DraftSqliteNode",
        "DraftMatchNode",
        "DraftS3SinkNode",
        "DraftSyntheticNode",
      ],
      PublishedNode: [
        "PublishedPostNode",
        "PublishedPreviewNode",
        "PublishedRawLeafNode",
        "PublishedTableLeafNode",
        "PublishedScriptingNode",
        "PublishedSqlNode",
        "PublishedSqliteNode",
        "PublishedMatchNode",
        "PublishedS3SinkNode",
        "PublishedSyntheticNode",
      ],
    };
    const typePolicies: TypePolicies = {
      ComputeJob: {
        fields: {
          autoFetching: {
            read: (value) => value || ComputeJobAutoFetching.None,
          },
          dcrHash: {
            read: (value) => value || null,
          },
          driverAttestationHash: {
            read: (value) => value || null,
          },
        },
      },
      ComputeNode: {
        fields: {
          isExpanded: {
            read: (value) => value || false,
          },
          isPopped: {
            read: (value) => value || false,
          },
          isSaving: {
            read: (value) => value || false,
          },
        },
      },
      DataLab: {
        keyFields: ["id"],
      },
      DataRoomRequest: {
        fields: {
          tempApprovers: {
            read: (value) => value,
          },
        },
      },
      Dataset: {
        fields: {
          isUploader: {
            read: (_, { readField, isReference }) => {
              const userRef = readField("owner");
              if (isReference(userRef)) {
                const userEmail = readField("email", userRef);
                return userEmail === user?.email;
              }
              return false;
            },
          },
        },
      },
      DatasetCollection: {
        fields: {
          totalCount: {
            read: (value, { readField }) => {
              const datasets = readField<unknown[]>("nodes") || [];
              return datasets?.length || 0;
            },
          },
        },
      },
      DirectAudience: {
        keyFields: ["name"],
      },
      DraftDataRoom: {
        fields: {
          isOwner: {
            read: (_, { readField, isReference }) => {
              const userRef = readField("owner");
              if (isReference(userRef)) {
                const userEmail = readField("email", userRef);
                return userEmail === user?.email;
              }
            },
          },
          participantById: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: "DraftParticipant",
                id: args?.id,
              });
            },
          },
        },
      },
      KeychainItem: {
        keyFields: ["id", "kind"],
      },
      PublishedNode: {
        keyFields: ["id", "dcrHash", "driverAttestationHash", "commitId"],
      },
      Query: {
        fields: {
          computeJob: {
            read: (value, { args, toReference }) => {
              return toReference({
                __typename: "ComputeJob",
                id: args?.id,
              });
            },
          },
          dataset: {
            read: (value, { args, toReference }) => {
              return toReference({
                __typename: "Dataset",
                id: args?.id,
              });
            },
          },
          datasetExport: {
            read: (_value, { args, toReference }) => {
              return toReference({
                __typename: "DatasetExport",
                id: args?.datasetExportId || args?.id,
              });
            },
          },
          datasetImport: {
            read: (_value, { args, toReference }) => {
              return toReference({
                __typename: "DatasetImport",
                id: args?.datasetImportId || args?.id,
              });
            },
          },
          draftDataRoom: {
            read: (value, { args, toReference }) => {
              return toReference({
                __typename: "DraftDataRoom",
                id: args?.id,
              });
            },
          },
          draftNode: {
            read: (value, { args, toReference, canRead }) => {
              if (value) {
                return value;
              } else {
                const { id } = args || {};
                if (id) {
                  for (const __typename of Object.values({
                    ...DraftDataNodeTypeNames,
                    ...DraftComputeNodeTypeNames,
                  })) {
                    const ref = toReference({ __typename, id });
                    if (canRead(ref)) {
                      return ref;
                    }
                  }
                }
                return undefined;
              }
            },
          },
          mediaCalculateStatistics: {
            // NOTE:
            // `mediaCalculateStatistics` for publisher and advertiser return the same payload, but potentially
            // a different amount of fields depending on the GraphQL document used, thus forcing Apollo Client
            // to overwrite the object under that specific key in the cache, notifying the component that the object
            // has changed, while also figuring out that some fields required by the component are missing, hence
            // also re-executing the query with eventually repeating the same process for the other component in
            // the infinite loop. The remedy is to either always fetch the same amount of fields, or manage the cache
            // properly from the local resolver itself, or define the merging function like it is done below
            merge: (existing, incoming) => {
              return { ...existing, ...incoming };
            },
          },
          organization: {
            read: (_value, { args, toReference }) => {
              return toReference({
                __typename: "Organization",
                id: args?.organizationId || args?.id,
              });
            },
          },
          organizationById: {
            read: (_value, { args, toReference }) => {
              return toReference({
                __typename: "Organization",
                id: args?.id,
              });
            },
          },
          publishedDataRoom: {
            read: (_value, { args, toReference }) => {
              return toReference({
                __typename: "PublishedDataRoom",
                id: args?.id,
              });
            },
          },
          publishedLookalikeMediaDataRoom: {
            read: (_value, { args, toReference }) => {
              return toReference({
                __typename: "PublishedLookalikeMediaDataRoom",
                id: args?.id,
              });
            },
          },
          publishedMediaDataRoom: {
            read: (_value, { args, toReference }) => {
              return toReference({
                __typename: "PublishedMediaDataRoom",
                id: args?.id,
              });
            },
          },
          publishedNode: {
            read: (value, { args, toReference, canRead }) => {
              if (value) {
                return value;
              } else {
                const {
                  id,
                  dcrHash,
                  driverAttestationHash,
                  commitId = null,
                } = args || {};
                for (const __typename of Object.values({
                  ...PublishedComputeNodeTypeNames,
                  ...PublishedDataNodeTypeNames,
                })) {
                  const ref = toReference({
                    __typename,
                    commitId,
                    dcrHash,
                    driverAttestationHash,
                    id,
                  });
                  if (canRead(ref)) {
                    return ref;
                  }
                }
                return undefined;
              }
            },
          },
          user: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: "User",
                id: args?.id,
              });
            },
          },
        },
      },
      User: {
        fields: {
          accessTokenById: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: "ApiToken",
                id: args?.id,
              });
            },
          },
        },
      },
    };
    const cache = new InMemoryCache({ possibleTypes, typePolicies });

    let links = [debounceLink, transportLink];
    if (logRequests) {
      links = [errorLink].concat(links);
    }
    const link = from(links);

    const mutexMap = new Map<string, Mutex>();
    const resolvers = {
      DatasetExport: makeDatasetExportResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      DatasetImport: makeDatasetImportResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      DraftMatchNode: makeDraftComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      DraftPostNode: makeDraftComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      DraftS3SinkNode: makeDraftComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      DraftScriptingNode: makeDraftComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      DraftSqlNode: makeDraftComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      DraftSqliteNode: makeDraftComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      DraftSyntheticNode: makeDraftComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      Mutation: makeMutationResolvers(
        apiCoreClient,
        sessionManager,
        store,
        mutexMap,
        getKeychain
      ),
      PublishedDataRoom: makePublishedDataRoomResolvers(
        apiCoreClient,
        sessionManager,
        store,
        mutexMap
      ),
      PublishedMatchNode: makePublishedComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedMediaDataRoom: makePublishedMediaDataRoomResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedPostNode: makePublishedComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedPreviewNode: makePublishedComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedRawLeafNode: makePublishedLeafNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedS3SinkNode: makePublishedComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedScriptingNode: makePublishedComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedSqlNode: makePublishedComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedSqliteNode: makePublishedComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedSyntheticNode: makePublishedComputationNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      PublishedTableLeafNode: makePublishedLeafNodeResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
      Query: makeQueryResolvers(apiCoreClient, sessionManager, store),
      SubmittedDataRoomRequest: makeSubmittedDataRoomRequestResolvers(
        apiCoreClient,
        sessionManager,
        store
      ),
    };
    const client = new ApolloClient({
      cache,
      link,
      resolvers,
    });
    const storage = new LocalStorageWrapper(window.localStorage);
    const maxSize = 1048576 * 5;
    const persistor = new CachePersistor({ cache, maxSize, storage });
    client.onClearStore(() => persistor.purge());
    setClient(client);
  }, [
    getAccessTokenSilently,
    graphqlUrl,
    user?.email,
    websocketUrl,
    logRequests,
    getKeychain,
  ]);
  /// TODO: This is REALLY REALLY BAD. Just for temp usage
  useEffect(() => {
    if (client) {
      window.__importMediaDCRdefinition = (input: unknown) => {
        logInfo(
          `!!!  Hidden technique !!!\n Example ${JSON.stringify(
            {
              activationDownloadByAdvertiser: true,
              activationDownloadByAgency: true,
              activationDownloadByPublisher: true,
              activationType: "CONSENTLESS",
              advertiserUserEmails: ["user_2@decentriq.ch"],
              agencyUserEmails: [],
              enableOverlapInsights: true,
              mainAdvertiserUserEmail: "user_2@decentriq.ch",
              mainPublisherUserEmail: "user_1@decentriq.com",
              name: "Hidden technique",
              observerUserEmails: [],
              publisherUserEmails: ["user_1@decentriq.com"],
            },
            null,
            2
          )}`
        );
        client
          .mutate({
            mutation: MediaPublishDraftDataRoomDocument,
            variables: input,
          })
          .then((value) => logInfo(value.data?.mediaPublishDraftDataRoom))
          .catch(logError);
      };
    }
  }, [client, apiCoreClient, sessionManager, store]);
  return !client || isLoading ? (
    <Loading />
  ) : (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  );
});
ApolloWrapper.displayName = "ApolloWrapper";

export default ApolloWrapper;
