import { type MediaDataRoomJobHookResult } from "./models";

const mapJobResultStatusPriority: Record<
  MediaDataRoomJobHookResult<unknown>["status"],
  number
> = {
  COMPLETED: 1,
  COMPUTING: 3,
  FETCHING: 2,
};

export const mergeMediaDataRoomJobResults = <R, T extends unknown[]>(
  results: { [K in keyof T]: MediaDataRoomJobHookResult<T[K]> },
  merge: (results: T) => R
): MediaDataRoomJobHookResult<R> => {
  const status: MediaDataRoomJobHookResult<R>["status"] =
    results
      .map((result) => result.status)
      .sort(
        (a, b) => mapJobResultStatusPriority[b] - mapJobResultStatusPriority[a]
      )?.[0] ?? "COMPUTING";
  return {
    computeResults: results.every((result) => result.status === "COMPLETED")
      ? merge(results.map((result) => result.computeResults) as T)
      : undefined,
    error: results.find((result) => result.error)?.error,
    loading: status !== "COMPLETED",
    retry: async () => {
      await Promise.all(results.map((result) => result.retry()));
    },
    // For the merged result we can't set cache data individually
    setCacheData: (data) => {},
    status,
  };
};
