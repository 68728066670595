import { DqLoader } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { Box, Stack, styled } from "@mui/joy";
import { memo } from "react";
import { EmptyData } from "components";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import { DeleteDisplayConfigurationButton } from "features/mediaPortalShared";
import { CommonSnackbarOrigin } from "hooks";
import DataPartnerDisplayConfigurationPresentation from "../DataPartnerDisplayConfigurationPresentation/DataPartnerDisplayConfigurationPresentation";
import UpdateDataPartnerDisplayConfigurationButton from "../UpdateDataPartnerDisplayConfigurationButton/UpdateDataPartnerDisplayConfigurationButton";

const StyledCenteringBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "center",
});

const DataPartnerDisplayConfiguration = memo(() => {
  const {
    displayConfiguration,
    isDisplayConfigurationLoading,
    deleteDisplayConfiguration,
  } = useDataPartnerPortal();
  if (isDisplayConfigurationLoading) {
    return (
      <StyledCenteringBox>
        <DqLoader />
      </StyledCenteringBox>
    );
  }
  if (displayConfiguration === null) {
    return (
      <EmptyData title="No display configuration found. Please create one." />
    );
  }
  return (
    <Stack>
      <DataPartnerDisplayConfigurationPresentation />
      <Stack direction="row" justifyContent="flex-end">
        <DeleteDisplayConfigurationButton
          confirmationTestId={
            testIds.dataPartnerPortal.displayConfiguration
              .deleteDialogConfirmationButton
          }
          deleteConfiguration={deleteDisplayConfiguration}
          dialogDescription="This will also delete all Data usage configurations. This cannot be undone."
          dialogTitle="Are you sure you want to delete this display configuration?"
          displayConfiguration={displayConfiguration}
          errorMessage="Cannot delete display configuration"
          snackbarOrigin={CommonSnackbarOrigin.DATA_PARTNER_PORTAL}
          testId={testIds.dataPartnerPortal.displayConfiguration.deleteButton}
        />
        <UpdateDataPartnerDisplayConfigurationButton />
      </Stack>
    </Stack>
  );
});
DataPartnerDisplayConfiguration.displayName = "DataPartnerDisplayConfiguration";

export default DataPartnerDisplayConfiguration;
