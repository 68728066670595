import { DqTable } from "@decentriq/components";
import { faEdit, faShare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Stack, Tooltip } from "@mui/joy";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useCallback } from "react";
import { useDatasetPermission } from "features/datasets/contexts";
import {
  type DatasetPermission,
  type DatasetPermissionRole,
  datasetPermissionRolePresentation,
} from "features/datasets/models";

const columns: MRT_ColumnDef<DatasetPermission>[] = [
  {
    accessorKey: "id",
    header: "Email",
  },
  {
    Cell: ({ cell }) =>
      datasetPermissionRolePresentation[cell.getValue<DatasetPermissionRole>()],
    accessorKey: "role",
    header: "Access",
  },
];

const DatasetPermissionsTabPanel = memo(() => {
  const {
    permissions,
    openEditDialog,
    canEditPermissionsOrDefault,
    openShareDialog,
    canShareDatasetOrDefault,
  } = useDatasetPermission();
  const onPermissionEdit = useCallback(
    (event: React.MouseEvent<HTMLElement>, permission: DatasetPermission) => {
      event.stopPropagation();
      openEditDialog(permission);
    },
    [openEditDialog]
  );
  const onOpenAddNewPermissionDialog = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      openShareDialog();
    },
    [openShareDialog]
  );
  const canShareDataset = canShareDatasetOrDefault(false);
  return (
    <Stack>
      {canShareDataset && (
        <Stack direction="row" justifyContent="flex-end">
          <Button
            color="primary"
            onClick={onOpenAddNewPermissionDialog}
            startDecorator={
              <FontAwesomeIcon fixedWidth={true} icon={faShare} />
            }
            variant="solid"
          >
            Share dataset
          </Button>
        </Stack>
      )}
      <DqTable
        columns={columns}
        data={permissions}
        enableRowActions={true}
        enableRowSelection={false}
        renderRowActions={({ row }) => {
          return (
            <Tooltip
              title={
                !canEditPermissionsOrDefault(false)
                  ? "You have no permissions to edit"
                  : "Edit permission"
              }
            >
              <span>
                <IconButton
                  disabled={!canEditPermissionsOrDefault(false)}
                  onClick={(event) => onPermissionEdit(event, row.original)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton>
              </span>
            </Tooltip>
          );
        }}
      />
    </Stack>
  );
});

DatasetPermissionsTabPanel.displayName = "DatasetPermissionsTabPanel";

export default DatasetPermissionsTabPanel;
