import { Box, Grid } from "@mui/joy";
import { memo } from "react";
import DqLogo from "assets/logos/decentriq-platform-symbol-square.svg";
import {
  GettingStartedActions,
  NotificationBanner,
  UserSupportMenu,
} from "components";

const GettingStarted: React.FC = () => {
  return (
    <Grid
      alignItems="center"
      container={true}
      flexDirection="column"
      height="100%"
      justifyContent="center"
      position="relative"
    >
      <Box style={{ left: 0, position: "absolute", right: 0, top: 0 }}>
        <NotificationBanner />
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        <DqLogo
          strokeWidth="1px"
          style={{ height: "14rem", marginBottom: "16px" }}
        />
        <GettingStartedActions />
      </Box>
      <UserSupportMenu />
    </Grid>
  );
};

GettingStarted.displayName = "GettingStarted";

export default memo(GettingStarted);
