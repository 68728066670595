import {
  faBallotCheck,
  faLinkSlash,
  faSync,
  faTrashCan,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonGroup,
  IconButton,
  type ListItemButtonProps,
  ListItemDecorator,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/joy";
import { CircularProgress } from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { useSafeState } from "ahooks";
import { Fragment, memo, useMemo } from "react";
import { usePopupState } from "hooks";
import { bindToggle } from "hooks/usePopupState/usePopupState";
import { type DataIngestionDestination, type DataType } from "models";
import DataNodeDataButton from "./DataNodeDataButton";

interface DataNodeDeprovisionButtonProps {
  id: string;
  hasValidationError?: boolean;
  label: string;
  onDelete?: (replace: boolean) => void;
  onDeprovision: () => Promise<void>;
  openValidationReport?: () => void;
  datasetHash?: string;
  dataType: DataType;
  testing?: boolean;
  ingestionDestination: DataIngestionDestination;
}

enum OptionKey {
  validationReport,
  replace,
  deprovision,
  delete,
}

interface Option {
  color?: ListItemButtonProps["color"];
  hidden?: boolean;
  icon: IconDefinition;
  key: OptionKey;
  label: string;
  tooltip: string;
}

const DataNodeDeprovisionButton: React.FC<DataNodeDeprovisionButtonProps> = ({
  id,
  hasValidationError,
  label,
  onDelete,
  onDeprovision,
  openValidationReport,
  datasetHash,
  dataType,
  testing,
  ingestionDestination,
}) => {
  const popupId = "data-node-deprovision-menu";
  const popupState = usePopupState({ popupId });
  const { anchorEl, isOpen, close } = popupState;
  const [deprovisioning, setDeprovisioning] = useSafeState<boolean>(false);
  const destination =
    ingestionDestination === "dataRoom" ? "data clean room" : "datalab";
  const hasDeletion = Boolean(onDelete);
  const dataNodeDeprovisionMenuOptions = useMemo<Option[]>(
    () => [
      ...(hasValidationError
        ? [
            {
              icon: faBallotCheck,
              key: OptionKey.validationReport,
              label: "Show validation report",
              tooltip:
                "This will display the validation report of this dataset according to the constraints defined by this table.",
            },
          ]
        : []),
      {
        hidden: true,
        icon: faSync,
        key: OptionKey.replace,
        label: `Replace ${label}`,
        tooltip: `This will deprovision this ${label} from all data clean rooms, delete it and open dialog to provision new one.`,
      },
      {
        icon: faLinkSlash,
        key: OptionKey.deprovision,
        label: `Deprovision ${label}`,
        tooltip: `This will deprovision the ${label} from this ${destination}. The ${label} will not be deleted. To do so, use the "Delete" option, or go to the "Datasets" page from the sidebar menu.`,
      },
      ...(hasDeletion
        ? [
            {
              color: "danger" as "danger",
              icon: faTrashCan,
              key: OptionKey.delete,
              label: `Delete ${label}`,
              tooltip: `This will deprovision this ${label} from ${
                ingestionDestination === "dataLab" ? "this datalab and" : ""
              } all data clean rooms, then delete it.`,
            },
          ]
        : []),
    ],
    [hasValidationError, label, destination, hasDeletion, ingestionDestination]
  );
  const handleDeprovisioning = async () => {
    try {
      setDeprovisioning(true);
      await onDeprovision();
    } finally {
      setDeprovisioning(false);
    }
  };
  const onMenuItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    key: OptionKey
  ) => {
    close();
    switch (key) {
      case OptionKey.deprovision: {
        handleDeprovisioning();
        return;
      }
      case OptionKey.replace: {
        onDelete?.(true);
        return;
      }
      case OptionKey.delete: {
        onDelete?.(false);
        return;
      }
      case OptionKey.validationReport: {
        openValidationReport?.();
        return;
      }
      default:
        return;
    }
  };
  if (deprovisioning) {
    return <CircularProgress sx={{ "--CircularProgress-size": "16px" }} />;
  }
  return (
    <Fragment>
      <ButtonGroup
        color={testing ? "secondary" : "primary"}
        disabled={deprovisioning}
        role="group"
        sx={{
          "--ButtonGroup-connected": "0",
          "--ButtonGroup-separatorSize": "0px",
        }}
        variant="plain"
      >
        <DataNodeDataButton
          data-first-child="true"
          dataType={dataType}
          datasetId={id}
          datasetManifestHash={datasetHash}
          onClick={close}
          testing={testing}
          withActions={false}
        />
        <IconButton {...bindToggle(popupState)}>
          <FontAwesomeIcon icon={faAngleDown} />
        </IconButton>
      </ButtonGroup>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={(event) => {
          if ((event.target as HTMLElement).closest("button") !== anchorEl) {
            close();
          }
        }}
      >
        <Menu
          anchorEl={anchorEl}
          id={popupId}
          onClose={close}
          open={isOpen}
          placement="bottom-end"
          sx={{ "--ListItemDecorator-size": "1.625rem" }}
        >
          {dataNodeDeprovisionMenuOptions
            .filter((option) => !option.hidden)
            .map((option, index) => (
              <Tooltip key={index} placement="left" title={option.tooltip}>
                <MenuItem
                  color={option.color}
                  onClick={(event) => onMenuItemClick(event, option.key)}
                >
                  <ListItemDecorator>
                    <FontAwesomeIcon fixedWidth={true} icon={option.icon} />
                  </ListItemDecorator>
                  {option.label}
                </MenuItem>
              </Tooltip>
            ))}
        </Menu>
      </ClickAwayListener>
    </Fragment>
  );
};

export default memo(DataNodeDeprovisionButton);
