import { useApolloClient } from "@apollo/client";
import { type Session } from "@decentriq/core";
import { useCreateMediaInsightsComputeJobMutation } from "@decentriq/graphql/dist/hooks";
import { GetMediaInsightsComputeJobDocument } from "@decentriq/graphql/dist/types";
import { useQueryClient } from "@tanstack/react-query";
import { loadAsync } from "jszip";
import isNil from "lodash/isNil";
import { useCallback, useMemo } from "react";
import { logDebug } from "utils";
import {
  type AbMediaRequestValueOfKey,
  type MediaDataRoomJobInput,
  type MediaDataRoomJobResultTransform,
  type MediaDataRoomRequestKey,
} from "./models";
import useMediaDataRoomRequest from "./useMediaDataRoomRequest";

export interface MediaDataRoomLazyJobHookPayload<
  T,
  K extends MediaDataRoomRequestKey,
> {
  /** @param input `MediaDataRoomJobInput` includes essential info for sending request, like request key, dataRoomId, driverAttestationHash, etc, please check `MediaDataRoomJobInput` for more details */
  input: MediaDataRoomJobInput<K>;
  /** @param requestCreator function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key; if not provided, it will need to be provided in the payload of the runner otherwise an error will be thrown */
  requestCreator?: (
    dataRoomIdHex: string,
    scopeIdHex: string
  ) => AbMediaRequestValueOfKey<K>;
  /** @param transform function to transform the job result, it provides the zip file in `JSZip` format */
  transform: MediaDataRoomJobResultTransform<T>;
  /** @param session optional `Session`, if not provided, it will be fetched */
  session?: Session;
}

export type MediaDataRoomLazyJobRunner<
  T,
  K extends MediaDataRoomRequestKey,
> = (payload?: {
  /**
   *This function is used to create the request body for the given key, it has priority over the `requestCreator` provided in the hook options
   *
   * @param dataRoomIdHex data room id in the hex format
   * @param scopeIdHex data scope id in the hex format
   * @returns body of the request for the given key
   */
  requestCreator?: (
    dataRoomIdHex: string,
    scopeIdHex: string
  ) => AbMediaRequestValueOfKey<K>;
  /**
   *By providing options you can override the session and scope id used during the request execution
   * @param session `Session` object to be used for the request
   * @param scopeId data scope id to be used for the request
   */
  options?: { session?: Session; scopeId?: string };
  updateInput?: (input: MediaDataRoomJobInput<K>) => MediaDataRoomJobInput<K>;
}) => Promise<T>;
export type MediaDataRoomLazyJobHookResult<
  T,
  K extends MediaDataRoomRequestKey,
> = [MediaDataRoomLazyJobRunner<T, K>];

/**
 *This hook is used to create request, fetch the results of the job for using job ID and compute node name from request response on demand.
 *
 * @param input `MediaDataRoomJobInput` includes essential info for sending request, like request key, dataRoomId, driverAttestationHash, etc, please check `MediaDataRoomJobInput` for more details
 * @param requestCreator function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key
 * @param session optional `Session`, if not provided, it will be fetched
 * @param transform function to transform the job result, it provides the zip file in `JSZip` format
 * @returns array with the runner function, which returns `Promise` with the transformed job result. If the runner is used on the `AbMediaRequest` key which does not return job ID and compute node name, an error will be thrown
 */
const useMediaDataRoomLazyJob = <T, K extends MediaDataRoomRequestKey>({
  input: payloadInput,
  requestCreator,
  transform,
  ...restPayload
}: MediaDataRoomLazyJobHookPayload<T, K>): MediaDataRoomLazyJobHookResult<
  T,
  K
> => {
  const { key, dataRoomId, driverAttestationHash } = payloadInput;
  const queryClient = useQueryClient();
  const apolloClient = useApolloClient();
  const [createMediaInsightsComputeJobMutation] =
    useCreateMediaInsightsComputeJobMutation();
  const [sendRequest, getSession] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key,
    requestCreator,
  });
  const run = useCallback<MediaDataRoomLazyJobRunner<T, K>>(
    async (payload) => {
      if (!payload?.requestCreator && !requestCreator) {
        throw new Error(
          `Failed to create request for "${key}". requestCreator is not provided neither in payload nor in hook options`
        );
      }
      const input = payload?.updateInput?.(payloadInput) ?? payloadInput;
      const queryKey = input.withFetchResultsSubkey().buildQueryKey();
      if (!input.skipCaching && !isNil(queryClient.getQueryData(queryKey))) {
        logDebug("||| Existing job cache found, returning results", key);
        return queryClient.getQueryData(queryKey) as T;
      }
      let job: { computeNodeName: string; jobIdHex: string } | null = null;
      if (input.canBeCached && !input.skipCaching) {
        const getJobResult = await apolloClient.query({
          fetchPolicy: "no-cache",
          query: GetMediaInsightsComputeJobDocument,
          variables: {
            input: {
              cacheKey: input.buildJobKey(),
              jobType: input.buildJobType(),
              publishedDataRoomId: dataRoomId,
            },
          },
        });
        job = getJobResult?.data?.mediaComputeJob ?? null;
        if (job) {
          logDebug("||| Existing job found, fetching results", key);
        }
      }
      const session =
        payload?.options?.session ??
        restPayload.session ??
        (await getSession());
      if (!job) {
        const response = await sendRequest({
          options: { scopeId: payload?.options?.scopeId, session },
          requestCreator: payload?.requestCreator ?? requestCreator!,
        });
        if (!("computeNodeName" in response) || !("jobIdHex" in response)) {
          throw new Error(`Invalid response for job ${key}`);
        }
        job = response;
        if (input.canBeCached && !input.skipCaching) {
          await createMediaInsightsComputeJobMutation({
            variables: {
              input: {
                ...job,
                cacheKey: input.buildJobKey(),
                jobType: input.buildJobType(),
                publishedDataRoomId: dataRoomId,
              },
            },
          });
        }
      }
      const result = await session.getComputationResult(
        {
          computeNodeId: job.computeNodeName,
          jobId: job.jobIdHex,
        },
        {
          interval: 1,
        }
      );
      const zip = await loadAsync(result);
      const data = await transform(zip);
      if (input.canBeCached && !input.skipCaching) {
        queryClient.setQueryData(
          input.withFetchResultsSubkey().buildQueryKey(),
          () => data
        );
      }
      return data;
    },
    // Its safe to rely only on `input.queryChangingKey` being changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      key,
      restPayload.session,
      sendRequest,
      transform,
      getSession,
      requestCreator,
      queryClient,
      apolloClient,
      createMediaInsightsComputeJobMutation,
      payloadInput.queryChangingKey,
    ]
  );
  return useMemo(() => [run], [run]);
};

export default useMediaDataRoomLazyJob;
