import { Box, Stack } from "@mui/joy";
import { memo } from "react";
import {
  DataLabDownloadValidationReportButton,
  DataLabStatistics,
  DataLabValidationAlerts,
  DataLabValidationButton,
  useDataLabContext,
} from "features/dataLabs";

const DataLabQualityStatistics: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();
  return (
    <Stack flex={1} mt={1}>
      <Box alignItems="center" display="flex" justifyContent="flex-end">
        <DataLabDownloadValidationReportButton />
        <DataLabValidationButton />
      </Box>
      <DataLabValidationAlerts />
      {dataLab?.statistics ? <DataLabStatistics /> : null}
    </Stack>
  );
};

DataLabQualityStatistics.displayName = "DataLabQualityStatistics";

export default memo(DataLabQualityStatistics);
