import {
  type Client,
  enclaveSpecifications,
  type proto,
  type types,
  utils,
} from "@decentriq/core";
import { type ColumnDataType } from "@decentriq/graphql/dist/types";
import { type DataRoomTableColumnDefinition } from "models";
import { replaceStringFromMap } from "../String";

export interface DataRoomEnclaveRepresentation {
  dataRoomDescription: proto.data_room.IDataRoom | undefined | null;
  dataRoomConfiguration: JSON | undefined;
}
export const decodeDataRoomConfiguration = async (
  dataRoomConfiguration: proto.data_room.IDataRoomConfiguration,
  client: Client,
  includeMrsigner: boolean
) => {
  const existingEnclaveSpecs = enclaveSpecifications.getAll();
  // In addition to the list of enclave specifications defined in this
  // version of the SDK, get the specifications of the currently running
  // enclaves (which might be debug enclaves with no known attestation spec),
  // and try to lookup a matching decoder in the list of known enclave specs.
  const latestEnclaveSpecsByName = existingEnclaveSpecs.reduce(
    (agg: any, spec: types.EnclaveSpecification) => {
      const latestSpec = agg[spec.name];
      if (
        !latestSpec ||
        spec.version === "mrsigner" ||
        (latestSpec.version !== "mrsigner" && latestSpec.version < spec.version)
      ) {
        agg[spec.name] = spec;
      }
      return agg;
    },
    {}
  );
  const currentlyAvailableEnclaveSpecs =
    await client.getEnclaveSpecifications(includeMrsigner);
  const allEnclaveSpecifications = [...existingEnclaveSpecs];
  for (const spec of currentlyAvailableEnclaveSpecs) {
    if (latestEnclaveSpecsByName[spec.name]) {
      allEnclaveSpecifications.push({
        ...spec,
        decoder: latestEnclaveSpecsByName[spec.name].decoder,
      });
    }
  }
  return utils.decodeDataRoomConfiguration(
    dataRoomConfiguration,
    allEnclaveSpecifications
  );
};

export function parseColumnDefinition(
  sqlCreateStatement: string
): DataRoomTableColumnDefinition[] {
  let columns: DataRoomTableColumnDefinition[] = [];
  const match = sqlCreateStatement.match(
    /^\s*CREATE\s+TABLE\s+"?(?<name>.*?)"?\s+\((?<columns>.+?)\)\s*;\s*$/is
  );
  if (match) {
    const columnsMatch = match?.groups?.columns?.match(
      /(?<column>\s*"[^"]*"[^,]+|[^,]+)/gis
    );
    if (columnsMatch) {
      columns =
        columnsMatch?.filter(Boolean)?.map((column: string) => {
          const columnTrim = column.trim();
          const columnMatch = columnTrim.match(
            /^\s*(?<name>("[^"]*")|[a-zA-Z0-9_]+)\s*(?<dataType>(varchar|int|float))\s*(?<nullConstraint>((not)?\s*null))?\s*$/i
          );
          let name = "?";
          let primitiveType = "TEXT" as unknown as ColumnDataType;
          let nullable = true;
          if (columnMatch) {
            name = columnMatch?.groups?.name
              ?.trim()
              ?.replace(/"/g, "") as string;
            primitiveType = columnMatch?.groups
              ?.dataType as unknown as ColumnDataType;
            nullable = !columnMatch?.groups?.nullConstraint
              ?.replace(/\s\s+/g, " ")
              ?.toLowerCase()
              ?.includes("not null");
          }
          return {
            name,
            nullable,
            primitiveType,
          };
        }) || [];
    }
  }

  return columns;
}

export const getDataRoomComputeNodesNameMap = (
  nodeNames:
    | ({ nodeName: string; computeNodeId: string } | undefined | null)[]
    | undefined
): { [key: string]: string } =>
  (nodeNames || []).reduce((a, v) => {
    if (v) {
      return {
        ...a,
        [v.computeNodeId]: v.nodeName,
      };
    }
    return a;
  }, {});

export const optimizeDataRoomError = (
  error: any,
  computeNodesNameMap: { [key: string]: string }
) => {
  if (typeof error === "string") {
    return replaceStringFromMap(error, {
      ...computeNodesNameMap,
      "compute_node_id: ": "Computation name: ",
    });
  }
  return error;
};

// Need to slufigy the publisher name to make sure it containse no special characters
// and only contains lowercase letters, numbers and underscores
export const slugify = (str: string) => {
  return str
    .toString() // Convert to string
    .toLowerCase() // Convert the string to lowercase
    .trim() // Remove whitespace from both ends of a string
    .replace(/\s+/g, "_") // Replace spaces with -
    .replace(/[^\w-]+/g, ""); // Remove all non-word chars
};
