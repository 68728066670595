import { DqLoader } from "@decentriq/components";
import { faFileLines, faTable } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, List, ListItem, Typography } from "@mui/joy";
import { Fragment, memo, useState } from "react";
import { DatasetPopup } from "features/datasets";
import {
  defaultDatasetKeychainItemKinds,
  useKeychainItems,
} from "hooks/keychain/useKeychainItems";
import { type DataType } from "models";
import { type Dataset } from "types";

type DatasetType = Pick<Dataset, "name" | "manifestHash" | "__typename">;

interface DatasetsInformationProps {
  loading?: boolean;
  datasets: DatasetType[];
  emptyText?: string;
}

const DatasetsInformation = memo<DatasetsInformationProps>(
  ({ datasets, loading, emptyText = "No datasets provisioned" }) => {
    const [selectedDatasetManifestHash, setSelectedDatasetManifestHash] =
      useState<string>("");
    const { hasDatasetMetadataItem, loading: isKeychainDataLoading } =
      useKeychainItems({
        kinds: defaultDatasetKeychainItemKinds,
      });
    if (loading || isKeychainDataLoading) {
      return <DqLoader />;
    }
    if (!datasets.length) {
      return (
        <Typography fontStyle="italic" level="body-sm">
          {emptyText}
        </Typography>
      );
    }
    const datasetsWithKeychainItem: (DatasetType & { type: DataType })[] =
      datasets.map((dataset) => ({
        ...dataset,
        type: (hasDatasetMetadataItem(dataset.manifestHash)
          ? "table"
          : "unstructured") as DataType,
      }));
    return (
      <Fragment>
        <List
          sx={{
            "--ListItem-minHeight": 0,
            padding: 0,
          }}
        >
          {datasetsWithKeychainItem.map(({ manifestHash, name, type }) => (
            <ListItem key={manifestHash}>
              <Typography
                component={Link}
                key={manifestHash}
                level="body-sm"
                onClick={() => setSelectedDatasetManifestHash(manifestHash)}
                startDecorator={
                  <FontAwesomeIcon
                    fixedWidth={true}
                    icon={type === "table" ? faTable : faFileLines}
                    size="sm"
                  />
                }
              >
                {name}
              </Typography>
            </ListItem>
          ))}
        </List>
        <DatasetPopup
          manifestHash={selectedDatasetManifestHash}
          onDismiss={() => setSelectedDatasetManifestHash("")}
          open={Boolean(selectedDatasetManifestHash)}
          withActions={false}
        />
      </Fragment>
    );
  }
);

DatasetsInformation.displayName = "DatasetsInformation";

export default DatasetsInformation;
