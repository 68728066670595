import { Option, Select } from "@mui/joy";
import { type FC, memo, useCallback } from "react";

interface OwnedByOrganizationFilterProps {
  onChange: (value: boolean | null) => void;
  value: boolean;
}

const OwnedByOrganizationFilter: FC<OwnedByOrganizationFilterProps> = memo(
  ({ onChange, value }) => {
    const handleOwnedByOrganizationChange = useCallback(
      (
        event: React.SyntheticEvent<Element, Event> | null,
        value: boolean | null
      ) => {
        //value should be passed as null, not false, for proper logic
        onChange(value || null);
      },
      [onChange]
    );
    return (
      <Select onChange={handleOwnedByOrganizationChange} value={value}>
        <Option value={true}>DCRs created by my organizations</Option>
        <Option value={false}>All</Option>
      </Select>
    );
  }
);

OwnedByOrganizationFilter.displayName = "OwnedByOrganizationFilter";
export default OwnedByOrganizationFilter;
