import { DqCard, DqCardContent, DqLoader } from "@decentriq/components";
import {
  faClock,
  faCopy,
  faDiagramProject,
  faHashtagLock,
  faUser,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/joy";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { DetailsGridIcon, DetailsGridValue, StateIcon } from "components";
// TODO: We should refactor this to do not import something from features inside the components folder
import DatasetsInformation from "features/datasets/components/DatasetsInformation/DatasetsInformation";
import { useCopyToClipboard, useDataRoomOverviewData } from "hooks";
import {
  dataRoomTypeCardPresentation,
  DataRoomTypeNames,
  getDataRoomTypeFromTypename,
} from "models";
import { ParticipantsPopover } from "./components";

interface DataRoomInformationProps {
  dataRoomId: string;
  __typename: DataRoomTypeNames;
}

const DataRoomInformation = memo<DataRoomInformationProps>(
  ({ dataRoomId, __typename }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [, copyToClipboard] = useCopyToClipboard();
    // Data needed for the info block
    const { data: overviewData, isLoading } = useDataRoomOverviewData(
      dataRoomId,
      __typename
    );
    const {
      id,
      owner,
      createdAt,
      collaboratingOrganizationNames = [],
      participants = [],
      isStopped,
      datasetPublications,
    } = overviewData || {};
    // DataRoomType field
    const dataRoomType = getDataRoomTypeFromTypename[__typename];
    const { title: dataRoomTypeTitle, icon: dataRoomTypeIcon } =
      dataRoomTypeCardPresentation[dataRoomType] || {};
    // isLocked
    const isLocked =
      __typename === DataRoomTypeNames.PublishedDataRoom ||
      __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
      __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom ||
      __typename === DataRoomTypeNames.PublishedMediaInsightsDcr;
    // Datasets
    const dataRoomDatasets = (datasetPublications?.nodes || []).map(
      ({ dataset }) => dataset
    );
    // Loading state
    if (isLoading) {
      return (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          left="0"
          top="0"
          width="100%"
        >
          <DqLoader />
        </Box>
      );
    }

    return (
      <Stack>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Created by</strong>
            </Typography>
            <DetailsGridValue icon={faUser} value={owner?.email || "—"} />
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>{`${
                __typename === DataRoomTypeNames.DraftDataRoom
                  ? "Created"
                  : "Published"
              } date`}</strong>
            </Typography>
            {createdAt ? (
              <DetailsGridValue
                icon={faClock}
                value={format(new Date(createdAt), "dd.MM.yyy, HH:mm")}
              />
            ) : (
              "—"
            )}
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Status</strong>
            </Typography>
            <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
              <StateIcon
                status={
                  isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"
                }
              />
              <DetailsGridValue
                value={
                  isLocked ? (isStopped ? "Stopped" : "Published") : "Draft"
                }
              />
            </Box>
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Grid xs={12}>
              <Typography
                gutterBottom={true}
                level="body-sm"
                textColor="inherit"
              >
                <strong>Type</strong>
              </Typography>
              <DetailsGridValue
                icon={dataRoomTypeIcon}
                value={dataRoomTypeTitle}
              />
            </Grid>
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography level="body-sm">
              <strong>ID</strong>
            </Typography>
            <Stack alignItems="center" flexDirection="row" width="100%">
              <FontAwesomeIcon fixedWidth={true} icon={faHashtagLock} />
              <Tooltip title={id}>
                <Typography level="body-sm" noWrap={true}>
                  {id}
                </Typography>
              </Tooltip>
              <Tooltip title={<span>Copy Data Clean Room id</span>}>
                <IconButton
                  onClick={() => {
                    if (id) {
                      void copyToClipboard(id);
                      enqueueSnackbar("DataRoom id copied");
                    }
                  }}
                  size="sm"
                  sx={{ marginLeft: "auto" }}
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faCopy} />
                </IconButton>
              </Tooltip>
            </Stack>
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Collaborating organizations</strong>
            </Typography>
            {collaboratingOrganizationNames.length ? (
              <DetailsGridValue
                icon={faDiagramProject}
                value={collaboratingOrganizationNames.join(", ")}
              />
            ) : (
              <span>—</span>
            )}
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Participants</strong>
            </Typography>
            {participants.length ? (
              <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
                <DetailsGridIcon icon={faUsers} />
                {participants.length === 1 ? (
                  <DetailsGridValue value={participants[0]?.userEmail} />
                ) : (
                  <ParticipantsPopover
                    ownerEmail={owner?.email}
                    participants={participants}
                  />
                )}
              </Box>
            ) : (
              "—"
            )}
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Participants</strong>
            </Typography>
            {participants.length ? (
              <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
                <DetailsGridIcon icon={faUsers} />
                {participants.length === 1 ? (
                  <DetailsGridValue value={participants[0]?.userEmail} />
                ) : (
                  <ParticipantsPopover
                    ownerEmail={owner?.email}
                    participants={participants}
                  />
                )}
              </Box>
            ) : (
              "—"
            )}
          </DqCardContent>
        </DqCard>
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Datasets provisioned</strong>
            </Typography>
            <DatasetsInformation
              datasets={dataRoomDatasets}
              emptyText="No datasets provisioned to this data room yet"
              loading={isLoading}
            />
          </DqCardContent>
        </DqCard>
      </Stack>
    );
  }
);
DataRoomInformation.displayName = "DataRoomInformation";

export default DataRoomInformation;
