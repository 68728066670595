import { DqTable } from "@decentriq/components";
import { useCollaboratingOrganizationsQuery } from "@decentriq/graphql/dist/hooks";
import { type CollaboratingOrganizationFilter } from "@decentriq/graphql/dist/types";
import { Stack } from "@mui/joy";
import { isNil, omitBy } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { AdminMonthPicker } from "components";
import OwnedByOrganizationFilter from "./OwnedByOrganizationFilter/OwnedByOrganizationFilter";

type CollaboratingOrganizationsListProps = {
  organizationId: string;
};

const CollaboratingOrganizationsList: React.FC<
  CollaboratingOrganizationsListProps
> = ({ organizationId }) => {
  const [filter, setQueryFilter] = useState<CollaboratingOrganizationFilter>({
    isOwnedByOrganization: true,
  });
  const handleSetQueryFilter = useCallback(
    (filter: CollaboratingOrganizationFilter) => {
      setQueryFilter((existingFilter) =>
        omitBy({ ...existingFilter, ...filter }, isNil)
      );
    },
    []
  );
  const handleOwnedByOrganizationFilter = useCallback(
    (value: boolean | null) => {
      handleSetQueryFilter({ isOwnedByOrganization: value });
    },
    [handleSetQueryFilter]
  );
  const { data } = useCollaboratingOrganizationsQuery({
    variables: { filter, organizationId },
  });
  const collaboratingOrganizations = useMemo(
    () => data?.organization?.collaboratingOrganizations?.nodes || [],
    [data]
  );
  const collaboratingOrganizationsColumns = useMemo(
    () => [
      {
        accessorKey: "organization.name",
        header: "Organization name",
        id: "name",
      },
      {
        accessorKey: "numberOfCommonDataRooms",
        header: "# of DCRs",
        id: "dcrCount",
      },
    ],
    []
  );
  return (
    <DqTable
      columns={collaboratingOrganizationsColumns}
      data={collaboratingOrganizations}
      enableGlobalFilter={true}
      enableSorting={true}
      enableTopToolbar={true}
      initialState={{
        showGlobalFilter: true,
      }}
      localization={{
        noRecordsToDisplay: "No collaborating organizations found",
      }}
      muiSearchTextFieldProps={{
        placeholder: "Search collaborating organizations",
      }}
      muiTableBodyRowProps={{
        sx: {
          "& > .MuiTableCell-root:first-child": { pl: 2 },
          "& > .MuiTableCell-root:last-child": { pr: 2 },
        },
      }}
      muiTableHeadRowProps={{
        sx: {
          "& > .MuiTableCell-root:first-child": { pl: 2 },
          "& > .MuiTableCell-root:last-child": {
            "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
              right: "-1rem",
            },
            pr: 2,
          },
        },
      }}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderTopToolbarCustomActions={() => {
        return (
          <Stack
            alignItems="center"
            alignSelf="center"
            direction="row"
            spacing={2}
            sx={{ ml: 1 }}
          >
            <AdminMonthPicker
              defaultValue={new Date()}
              setQueryFilter={handleSetQueryFilter}
            />
            <OwnedByOrganizationFilter
              onChange={handleOwnedByOrganizationFilter}
              value={!!filter?.isOwnedByOrganization}
            />
          </Stack>
        );
      }}
    />
  );
};

CollaboratingOrganizationsList.displayName = "CollaboratingOrganizationsList";

export default memo(CollaboratingOrganizationsList);
