import { DqCard, DqCardContent } from "@decentriq/components";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Chip, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import {
  EstimatedAudienceSize,
  QualityScore,
  useMediaDataRoom,
} from "features/mediaDataRoom";
import { useLookalikeAdvertiserAudienceGenerator } from "features/mediaDataRoom/containers/LookalikeAdvertiserAudienceGenerator";
import { AudienceQualityStatisticsDrawer } from "features/mediaDataRoom/containers/LookalikeAdvertiserAudienceGenerator/components";
import { PositiveCurveScoreBar } from "./components";

const AudienceQualityStatistics: React.FC = () => {
  const { selectedAudienceStatistics, enableExtendedLookalikeStatistics } =
    useLookalikeAdvertiserAudienceGenerator();
  const {
    testSetPositiveExamplesSize,
    testSetSize,
    estimatedAudienceSize,
    audienceQualityScore,
    computeState: { loading: isStatisticsComputationLoading },
  } = selectedAudienceStatistics!;
  const {
    supportedFeatures: { showAbsoluteValues },
  } = useMediaDataRoom();

  return (
    <Stack direction="column" flex={1} spacing={1.5}>
      <Typography fontWeight={600} level="title-md" mb={1.5} textAlign="center">
        {enableExtendedLookalikeStatistics
          ? "Lookalike audience quality statistics"
          : "Lookalike audience"}
        {enableExtendedLookalikeStatistics && (
          <Chip color="warning" sx={{ ml: 1 }}>
            Beta
          </Chip>
        )}
      </Typography>
      {isStatisticsComputationLoading && (
        <Alert
          color="neutral"
          startDecorator={<FontAwesomeIcon icon={faInfoCircle} />}
          sx={{ mb: 1 }}
          variant="soft"
        >
          <Stack spacing={0}>
            <Typography level="title-md">Audience is computing</Typography>
            <Typography level="body-sm">
              This may take up to an hour. You can still save the audience
              during this time, but results won't be immediate.
            </Typography>
          </Stack>
        </Alert>
      )}
      {showAbsoluteValues && (
        <EstimatedAudienceSize
          estimatedAudienceSize={estimatedAudienceSize!}
          loading={isStatisticsComputationLoading}
        />
      )}
      <QualityScore
        loading={isStatisticsComputationLoading}
        qualityScore={audienceQualityScore}
      />
      {enableExtendedLookalikeStatistics && (
        <DqCard sx={{ width: "100%" }}>
          <DqCardContent>
            <PositiveCurveScoreBar />
          </DqCardContent>
        </DqCard>
      )}
      {!isStatisticsComputationLoading &&
        showAbsoluteValues &&
        (testSetPositiveExamplesSize || testSetSize) < 50 && (
          <Alert color="warning" sx={{ mt: 1 }}>
            There were too few individuals in the overlapping audience to create
            a large holdout group for testing. This may result in a less
            accurate assessment of model quality.
          </Alert>
        )}
      {enableExtendedLookalikeStatistics && <AudienceQualityStatisticsDrawer />}
    </Stack>
  );
};

AudienceQualityStatistics.displayName = "AudienceQualityStatistics";

export default memo(AudienceQualityStatistics);
