import {
  useOrganizationDetailsQuery,
  useUpdateOrganizationMigrationDeadlineMutation,
} from "@decentriq/graphql/dist/hooks";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { memo, useCallback, useEffect, useState } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface OrganizationMigrationDeadlineProps {
  organizationId: string;
}

const OrganizationMigrationDeadline = memo(
  ({ organizationId }: OrganizationMigrationDeadlineProps) => {
    const [migrationDeadline, setMigrationDeadline] = useState<string>("");
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.ADMIN,
    });
    const { data: organizationDetailsData } = useOrganizationDetailsQuery({
      variables: {
        organizationId,
      },
    });

    const [updateOrganizationMigrationDeadlineMutation] =
      useUpdateOrganizationMigrationDeadlineMutation({
        onCompleted: () => {
          enqueueSnackbar(
            `Organization migration deadline has been successfully updated.`
          );
        },
      });

    useEffect(() => {
      setMigrationDeadline(
        organizationDetailsData?.organization?.migrationDeadline!
      );
    }, [organizationDetailsData]);

    const updateOrganizationMigrationDeadline = useCallback(
      async (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        const { target } = event;
        if (
          !target.value ||
          organizationDetailsData?.organization?.migrationDeadline ===
            target.value
        )
          return;
        try {
          await updateOrganizationMigrationDeadlineMutation({
            update: (cache, { data: { organization = {} } }: any) => {
              cache.modify({
                fields: {
                  migrationDeadline: () => {
                    return organization?.update?.migrationDeadline;
                  },
                },
                id: cache.identify({
                  __typename: "Organization",
                  id: organizationId,
                }),
              });
            },
            variables: {
              input: {
                id: organizationId,
                migrationDeadline: target.value,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            "Organization migration deadline could not be updated.",
            {
              context: error?.message,
              persist: true,
              variant: "error",
            }
          );
          setMigrationDeadline(
            organizationDetailsData?.organization?.migrationDeadline!
          );
        }
      },
      [
        enqueueSnackbar,
        organizationDetailsData?.organization?.migrationDeadline,
        organizationId,
        updateOrganizationMigrationDeadlineMutation,
      ]
    );

    const updateOrganizationMigrationDeadlineOnEnter = useCallback(
      (event: any) => {
        const { target, keyCode } = event || {};
        if (keyCode !== 13 || !target.value) return;
        updateOrganizationMigrationDeadline(event);
      },
      [updateOrganizationMigrationDeadline]
    );

    return (
      <FormControl sx={{ mb: 1 }}>
        <FormLabel>Migration deadline</FormLabel>
        <Input
          name="migrationDeadline"
          onBlur={updateOrganizationMigrationDeadline}
          onChange={({ target }) => setMigrationDeadline(target.value)}
          onKeyDown={updateOrganizationMigrationDeadlineOnEnter}
          placeholder="Migration deadline"
          value={migrationDeadline}
        />
      </FormControl>
    );
  }
);

OrganizationMigrationDeadline.displayName = "OrganizationMigrationDeadline";

export default OrganizationMigrationDeadline;
