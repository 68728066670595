import { useAuth0 } from "@auth0/auth0-react";
import {
  AppConfigurationClient,
  type ConfigurationSetting,
  type FeatureFlagValue,
  parseFeatureFlag,
} from "@azure/app-configuration";
import { useQuery } from "@tanstack/react-query";
import isNil from "lodash/isNil";
import { useEffect, useMemo } from "react";
import { useConfiguration } from "contexts";
import { logError } from "utils";

// Corresponds to the Azure Feature flag UI. To create such a filter, choose Custom as a feature flag filter type, then specify these fields as filter parameters.
interface Filter {
  type: FilterType;
  field: keyof FeatureUser;
  value: string[];
}

// The data the filter will be run against.
interface FeatureUser {
  email: string; // auth0 email
}

enum FilterType {
  ContainsAny = "ContainsAny",
  ContainsNone = "ContainsNone",
}

interface UseAzureAppConfigurationFeatureFlagParams {
  flagKey: string;
  polling?: number;
}

interface UseAzureAppConfigurationFeatureFlagResult {
  value: boolean | null;
  error: Error | null;
}

/**
 * Hook to check if a feature flag is enabled in Azure App Configuration
 * https://portal.azure.com/#browse/Microsoft.AppConfiguration%2FconfigurationStores
 * (operations / feature manager)
 * @param params - Configuration parameters
 * @param params.flagKey - The key of the feature flag to check
 * @param params.polling - The polling interval in milliseconds to check for updates to the feature flag
 * @returns {UseAzureAppConfigurationFeatureFlagResult} Object containing:
 *  - value: boolean | null - Whether the feature flag is enabled (null if not yet loaded)
 *  - error: Error | null - Any error that occurred while fetching the flag
 */
const useAzureAppConfigurationFeatureFlag = ({
  flagKey,
  polling,
}: UseAzureAppConfigurationFeatureFlagParams): UseAzureAppConfigurationFeatureFlagResult => {
  const { azureAppConfigurationConnectionString } = useConfiguration();
  const { user: auth0User } = useAuth0();

  const appConfigurationClient = useMemo(
    () => new AppConfigurationClient(azureAppConfigurationConnectionString),
    [azureAppConfigurationConnectionString]
  );

  const { data: enabled, error: configurationSettingError } = useQuery({
    enabled: !!flagKey && !!auth0User?.email,
    queryFn: async () => {
      if (!auth0User?.email) {
        return null;
      }
      const configurationSetting =
        await appConfigurationClient.getConfigurationSetting(
          {
            key: `.appconfig.featureflag/${flagKey}`,
          },
          {
            requestOptions: {
              customHeaders: {
                "Cache-Control": "no-cache,no-store,max-age=0",
              },
            },
          }
        );
      if (!configurationSetting) {
        return null;
      }
      const featureFlag = parseFeatureFlag(configurationSetting);

      return isFeatureFlagEnabled(featureFlag, {
        email: auth0User?.email,
      });
    },
    queryKey: ["feature-flag", flagKey],
    refetchInterval: polling,
  });

  useEffect(() => {
    if (configurationSettingError) {
      logError(configurationSettingError);
    }
  }, [configurationSettingError]);

  return useMemo(
    () => ({
      error: configurationSettingError,
      value: isNil(enabled) ? null : enabled,
    }),
    [enabled, configurationSettingError]
  );
};

/**
 * Returns true if the feature flag is enabled and applies to `featureUser`.
 * If there are errors during the processing of the feature flag, false is returned and a corresponding error is printed to console.
 * @param {ConfigurationSetting<FeatureFlagValue>} featureFlag - The feature flag configuration
 * @param {FeatureUser} featureUser - The user to check the feature flag against
 * @returns {boolean} Whether the feature flag is enabled for the given user
 */
function isFeatureFlagEnabled(
  featureFlag: ConfigurationSetting<FeatureFlagValue>,
  featureUser: FeatureUser
): boolean {
  let flagKey = featureFlag.key;
  if (!featureFlag.value.enabled) {
    return false;
  }
  if (featureFlag.value.conditions.clientFilters.length === 0) {
    return true;
  }
  for (const filterRaw of featureFlag.value.conditions.clientFilters) {
    const filter = filterRaw.parameters as unknown as Filter | null;
    if (filter == null) {
      logError(
        `Feature flag filter parameters not set for feature "${flagKey}", filter "${filterRaw.name}"`
      );
      return false;
    }
    const fieldValue = featureUser[filter.field];
    switch (filter.type) {
      case FilterType.ContainsAny: {
        if (typeof fieldValue !== "string") {
          logError(
            `Feature "${flagKey}"'s filter "${filterRaw.name}"'s type is ${filter.type} which only accepts string fields`
          );
          return false;
        }
        if (!Array.isArray(filter.value)) {
          logError(
            `Feature "${flagKey}"'s filter "${filterRaw.name}"'s type is ${filter.type} which only accepts an array of strings as "value"`
          );
          return false;
        }
        if (filter.value.some((member) => fieldValue.includes(member))) {
          return true;
        }
        break;
      }
      case FilterType.ContainsNone: {
        if (typeof fieldValue !== "string") {
          logError(
            `Feature "${flagKey}"'s filter "${filterRaw.name}"'s type is ${filter.type} which only accepts string fields`
          );
          return false;
        }
        if (!Array.isArray(filter.value)) {
          logError(
            `Feature "${flagKey}"'s filter "${filterRaw.name}"'s type is ${filter.type} which only accepts an array of strings as "value"`
          );
          return false;
        }
        if (filter.value.every((member) => !fieldValue.includes(member))) {
          return true;
        }
        break;
      }
      default: {
        logError(
          `Feature "${flagKey}"'s filter "${filterRaw.name}" has unknown type "${filter.type}"`
        );
        return false;
      }
    }
  }
  return false;
}

export default useAzureAppConfigurationFeatureFlag;
