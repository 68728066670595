// TODO: Fix joy migration
import {
  faEnvelopeOpenText,
  faIdBadge,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { type Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";

const useUnauthorizedDialogStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: grey["400"],
  },
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  title: {
    lineHeight: 1.25,
  },
}));

interface UnauthorizedDialogProps {
  open: boolean;
  onClose: () => void;
  error?: string;
}

const UnauthorizedDialog = ({
  open,
  onClose,
  error,
}: UnauthorizedDialogProps) => {
  const { classes: unauthorizedDialogClasses } = useUnauthorizedDialogStyles();
  const isEmailVerificationError = error === "Email address is not verified";
  return (
    <Modal open={open}>
      <ModalDialog>
        <Box className={unauthorizedDialogClasses.iconTitleWrapper}>
          <Box className={unauthorizedDialogClasses.iconWrapper}>
            <FontAwesomeIcon
              className={unauthorizedDialogClasses.icon}
              fixedWidth={true}
              icon={isEmailVerificationError ? faEnvelopeOpenText : faIdBadge}
              size="5x"
            />
          </Box>
          <Typography
            align="center"
            className={unauthorizedDialogClasses.title}
            color="textPrimary"
            level="title-md"
          >
            {isEmailVerificationError ? (
              <strong>Please verify your email first</strong>
            ) : (
              <strong>Sign in failed</strong>
            )}
          </Typography>
        </Box>
        <DialogContent>
          <Typography align="center" component="div" level="body-sm">
            {isEmailVerificationError ? (
              <>
                We must be sure that the email address you specified during the
                sign up process really belongs to you, therefore we've sent you
                a verification link. Please follow that link first and then come
                back and try to sign in again.
              </>
            ) : (
              <>
                During sign in, the following error occurred: <i>{error}</i>
                <br />
                Please try again. If the issue persists, please contact us at{" "}
                <a
                  href="mailto:support@decentriq.com"
                  style={{ color: "inherit" }}
                >
                  support@decentriq.com
                </a>
              </>
            )}
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>Sign in</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default UnauthorizedDialog;
