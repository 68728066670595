import {
  type DraftScript,
  type PublishedScript,
  ScriptingLanguage,
} from "@decentriq/graphql/dist/types";
import {
  faAngleDown,
  faAngleUp,
  faClone,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Modal,
  ModalDialog,
  Tooltip,
  Typography,
} from "@mui/joy";
import { styled } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { DetailsGridLabel, DetailsGridValue } from "components";
import { formatSize } from "features/datasets/utils";
import { useCopyToClipboard } from "hooks";
import {
  PYTHON_INPUT_FILE_PREFIX,
  R_INPUT_FILE_PREFIX,
  type ScriptingInputNodeSnapshot,
  scriptingNodeGroupLabelsMap,
  ScriptingNodeInputTypeNames,
} from "models";
import useScriptingComputeNode from "../../useScriptingComputeNode";
import useScriptingNodeInputs from "../../useScriptingNodeInputs/useScriptingNodeInputs";
import FileExplorerItem from "../FileExplorerItem/FileExplorerItem";

const FileExplorerTreeView = styled(SimpleTreeView)({
  "& .Mui-disabled": {
    "& .MuiTreeItem-iconContainer": {
      width: "0 !important",
    },
    cursor: "default",
    opacity: "1 !important",
  },
});

const tabularInputPath = (input: string) => {
  // read_tabular_data() function does not take the dataset.csv
  return input?.substring(input?.lastIndexOf("/dataset.csv"), -1);
};

const pythonNodeInputCopyContent = new Map<
  ScriptingInputNodeSnapshot["typename"],
  (input: string) => string
>([
  [
    ScriptingNodeInputTypeNames.DraftSyntheticNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftTableLeafNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftSqlNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftSqliteNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftMatchNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftPostNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftScriptingNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftScript,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/code/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftRawLeafNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftPreviewNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSyntheticNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedTableLeafNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSqlNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSqliteNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedMatchNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedPostNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedPreviewNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(input)}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedScriptingNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedScript,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/code/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedRawLeafNode,
    (input) => `${PYTHON_INPUT_FILE_PREFIX}/${input}`,
  ],
]);

const RNodeInputCopyContent = new Map<
  ScriptingInputNodeSnapshot["typename"],
  (input: string) => string
>([
  [
    ScriptingNodeInputTypeNames.DraftSyntheticNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftTableLeafNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftSqlNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftSqliteNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftMatchNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftPostNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftPreviewNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftScriptingNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftScript,
    (input) => `${R_INPUT_FILE_PREFIX}/code/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.DraftRawLeafNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSyntheticNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedTableLeafNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSqlNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedSqliteNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedMatchNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedPostNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedScriptingNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedScript,
    (input) => `${R_INPUT_FILE_PREFIX}/code/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedRawLeafNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
  [
    ScriptingNodeInputTypeNames.PublishedPreviewNode,
    (input) => `${R_INPUT_FILE_PREFIX}/${input}`,
  ],
]);
// TODO this will be used in the future https://decentriq.slack.com/archives/C01PK2G9HPS/p1721979948837869?thread_ts=1721897547.894199&cid=C01PK2G9HPS
// const pythonNodeInputCopyContent = new Map<
//   ScriptingInputNodeSnapshot["typename"],
//   (input: string) => string
// >([
//   [
//     ScriptingNodeInputTypeNames.DraftSyntheticNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftTableLeafNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftSqlNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftSqliteNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftMatchNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftPostNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftScriptingNode,
//     (input) => `f = open("${PYTHON_INPUT_FILE_PREFIX}/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftScript,
//     (input) => `f = open("${PYTHON_INPUT_FILE_PREFIX}/code/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftRawLeafNode,
//     (input) => `f = open("${PYTHON_INPUT_FILE_PREFIX}/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftPreviewNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedSyntheticNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedTableLeafNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedSqlNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedSqliteNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedMatchNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedPostNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedPreviewNode,
//     (input) =>
//       `df = decentriq_util.read_tabular_data("${PYTHON_INPUT_FILE_PREFIX}/${tabularInputPath(
//         input
//       )}")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedScriptingNode,
//     (input) => `f = open("${PYTHON_INPUT_FILE_PREFIX}/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedScript,
//     (input) => `f = open("${PYTHON_INPUT_FILE_PREFIX}/code/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedRawLeafNode,
//     (input) => `f = open("${PYTHON_INPUT_FILE_PREFIX}/${input}", "r")`,
//   ],
// ]);

// const RNodeInputCopyContent = new Map<
//   ScriptingInputNodeSnapshot["typename"],
//   (input: string) => string
// >([
//   [
//     ScriptingNodeInputTypeNames.DraftSyntheticNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftTableLeafNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftSqlNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftSqliteNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftMatchNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftPostNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftPreviewNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftScriptingNode,
//     (input) => `f <- file("${R_INPUT_FILE_PREFIX}/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftScript,
//     (input) => `f <- file("${R_INPUT_FILE_PREFIX}/code/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.DraftRawLeafNode,
//     (input) => `f <- file("${R_INPUT_FILE_PREFIX}/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedSyntheticNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedTableLeafNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedSqlNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedSqliteNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedMatchNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedPostNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedScriptingNode,
//     (input) => `f <- file("${R_INPUT_FILE_PREFIX}/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedScript,
//     (input) => `f <- file("${R_INPUT_FILE_PREFIX}/code/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedRawLeafNode,
//     (input) => `f <- file("${R_INPUT_FILE_PREFIX}/${input}", "r")`,
//   ],
//   [
//     ScriptingNodeInputTypeNames.PublishedPreviewNode,
//     (input) =>
//       `df <- read.csv(file = "${R_INPUT_FILE_PREFIX}/${input}", sep = ",", header=FALSE)`,
//   ],
// ]);

const getInputCopyContent = (
  scriptingLanguage: ScriptingLanguage,
  inputTypename: ScriptingInputNodeSnapshot["typename"],
  inputPath: string
) => {
  if (scriptingLanguage === ScriptingLanguage.R) {
    return RNodeInputCopyContent.get(inputTypename)!(inputPath);
  }
  if (scriptingLanguage === ScriptingLanguage.Python) {
    return pythonNodeInputCopyContent.get(inputTypename)!(inputPath);
  }
};

interface ScriptingComputeNodeFileExplorerProps {
  computeNodeId: string;
  readOnly?: boolean;
  fullHeight?: boolean;
}

const ScriptingComputeNodeFileExplorer: React.FC<
  ScriptingComputeNodeFileExplorerProps
> = ({ computeNodeId, readOnly, fullHeight = false }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();
  // TODO: move this state with button and dialog into separate component, something like PreviewNodeTreeItem
  const [selectedPreviewNode, setSelectedPreviewNode] =
    useState<ScriptingInputNodeSnapshot | null>(null);
  const { scripts, scriptingLanguage, dependencies } =
    useScriptingComputeNode(computeNodeId);
  // TreeView state
  const [expanded, setExpanded] = useState<string[]>([
    "input",
    "output",
    "__tmp__",
    "__script-tmp__",
    "code",
    "script-output",
  ]);
  const handleToggle = (
    event: React.SyntheticEvent,
    nodeIds: string[] | string | null
  ) => {
    setExpanded(nodeIds as string[]);
  };
  // Dependencies
  const scriptingNodeDependenciesIds = dependencies?.map((node) => node.id);
  const scriptingNodeAvailableInputs = useScriptingNodeInputs(computeNodeId);
  const scriptingNodeInputs = scriptingNodeAvailableInputs.filter(
    ({ computeNodeId }) => scriptingNodeDependenciesIds?.includes(computeNodeId)
  );
  // Static Scripts
  const staticScripts =
    scripts?.filter(
      ({ isMainScript }: DraftScript | PublishedScript) => !isMainScript
    ) || [];
  const staticScriptsInputs: ScriptingInputNodeSnapshot[] = staticScripts.map(
    (script) =>
      ({
        computeNodeId: script.name as string,
        id: script.name as string,
        label: `${script.name}, FILE`,
        name: script.name as string,
        path: `${script.name}`,
        typename: script.__typename,
      }) as ScriptingInputNodeSnapshot
  );
  useEffect(() => {
    setExpanded((expanded) =>
      scriptingNodeInputs.reduce(
        (expandedItemsArray, input, index) => {
          return [
            ...expandedItemsArray,
            `${index}-input-${input.computeNodeId}`,
            `${index}-input-code-${input.computeNodeId}`,
            `${index}-input-${input.computeNodeId}-content`,
          ];
        },
        [...expanded]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptingNodeInputs.length]);
  return (
    <Box
      sx={{
        flex: 1,
        maxHeight: readOnly ? "fit-content" : fullHeight ? "100%" : "450px",
        padding: 1,
        width: "100%",
      }}
    >
      <Typography level="body-sm">File browser</Typography>
      {/* This list should look like a TreeView, but with no ability to toggle and select on inner levels. 
      Hence, the easiest way to achieve that is to disable them and customize the styling to have it looking like expanded ones */}
      <FileExplorerTreeView
        expandedItems={expanded}
        onExpandedItemsChange={handleToggle}
        slots={{
          collapseIcon: () => <FontAwesomeIcon icon={faAngleUp} />,
          expandIcon: () => <FontAwesomeIcon icon={faAngleDown} />,
        }}
      >
        <TreeItem
          itemId={"input"}
          label={<Typography level="body-sm">input</Typography>}
        >
          {scriptingNodeInputs.map((input, index) =>
            input.typename === ScriptingNodeInputTypeNames.DraftRawLeafNode ||
            input.typename ===
              ScriptingNodeInputTypeNames.PublishedRawLeafNode ? (
              <FileExplorerItem
                copyValue={getInputCopyContent(
                  scriptingLanguage!,
                  input.typename,
                  input?.path
                )}
                disabled={true}
                input={input}
                itemId={`${index}-input-${input.computeNodeId}`}
                key={index}
                slots={{
                  collapseIcon: () => <></>,
                  expandIcon: () => <></>,
                }}
              />
            ) : (
              <TreeItem
                disabled={true}
                itemId={`${index}-input-${input.computeNodeId}`}
                key={`${index}-input-${input.computeNodeId}`}
                label={
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      gap: "0.25rem",
                      lineHeight: "1.5rem",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      level="body-sm"
                      style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                    >
                      {input?.name || ""}
                    </Typography>
                    <Typography
                      component="span"
                      level="body-sm"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                        borderRadius: "3px",
                        color: "black",
                        lineHeight: "0.75rem",
                        padding: "0.125rem",
                      }}
                    >
                      {scriptingNodeGroupLabelsMap[
                        input?.typename
                      ]?.toUpperCase()}
                    </Typography>
                  </Box>
                }
                slots={{
                  collapseIcon: () => <></>,
                  expandIcon: () => <></>,
                }}
              >
                {input?.typename === "PublishedPreviewNode" && (
                  <span>
                    Remaining quota:{" "}
                    {formatSize(input?.remainingQuotaBytes ?? 0)}{" "}
                    <Button
                      onClick={() => setSelectedPreviewNode(input)}
                      sx={{
                        display: "inline-block",
                        minWidth: "auto",
                        padding: "0",
                      }}
                    >
                      Details
                    </Button>
                  </span>
                )}
                <TreeItem
                  disabled={true}
                  itemId={`${index}-input-${input.computeNodeId}-content`}
                  label={
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {input.typename ===
                        ScriptingNodeInputTypeNames.DraftScriptingNode ||
                      input.typename ===
                        ScriptingNodeInputTypeNames.PublishedScriptingNode ? (
                        <Typography
                          level="body-sm"
                          sx={{ fontStyle: "italic" }}
                        >
                          Script output
                        </Typography>
                      ) : (
                        <Typography level="body-sm">dataset.csv</Typography>
                      )}
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Tooltip title="Click to copy path for this file">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(
                                // @ts-ignore
                                getInputCopyContent(
                                  scriptingLanguage!,
                                  input.typename,
                                  input.path
                                )
                              );
                              enqueueSnackbar(
                                `'${input.path}' path copied to clipboard`
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon icon={faClone} />
                          </div>
                        </Tooltip>
                        {/* TODO this will be used in the future https://decentriq.slack.com/archives/C01PK2G9HPS/p1721979948837869?thread_ts=1721897547.894199&cid=C01PK2G9HPS */}
                        {/* <Tooltip title="Click to copy snippet for importing this file">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(
                                // @ts-ignore
                                getInputCopyContent(
                                  scriptingLanguage!,
                                  input.typename,
                                  input.path
                                )
                              );
                              enqueueSnackbar(
                                `'${input.path}' importing snippet copied to clipboard`
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon icon={faClone} />
                          </div>
                        </Tooltip> */}
                      </Box>
                    </div>
                  }
                  slots={{
                    collapseIcon: () => <></>,
                    expandIcon: () => <></>,
                  }}
                />
              </TreeItem>
            )
          )}
          {staticScriptsInputs.length > 0 && (
            <TreeItem
              disabled={true}
              itemId={"code"}
              label={<Typography level="body-sm">code</Typography>}
              slots={{
                collapseIcon: () => <></>,
                expandIcon: () => <></>,
              }}
            >
              {staticScriptsInputs.map((input, index) => (
                <FileExplorerItem
                  copyValue={getInputCopyContent(
                    scriptingLanguage!,
                    input.typename,
                    input?.path
                  )}
                  input={input}
                  itemId={`${index}-input-code-${input.computeNodeId}`}
                  key={index}
                />
              ))}
            </TreeItem>
          )}
        </TreeItem>
        <TreeItem
          itemId={"output"}
          label={<Typography level="body-sm">output</Typography>}
        >
          <TreeItem
            disabled={true}
            itemId="script-output"
            label={
              <Typography level="body-sm">
                <i>Save output files into this directory</i>
              </Typography>
            }
          />
        </TreeItem>
        <TreeItem
          itemId={"__tmp__"}
          label={<Typography level="body-sm">tmp</Typography>}
        >
          <TreeItem
            disabled={true}
            itemId="__script-tmp__"
            label={
              <Typography level="body-sm">
                <i>Save temporary files into this directory</i>
              </Typography>
            }
          />
        </TreeItem>
      </FileExplorerTreeView>
      <Modal
        onClose={() => setSelectedPreviewNode(null)}
        open={selectedPreviewNode !== null}
      >
        <ModalDialog>
          <DialogTitle
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{selectedPreviewNode?.name}</span>
            <IconButton onClick={() => setSelectedPreviewNode(null)}>
              <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container={true} sx={{ paddingTop: "0 !important" }} xs={6}>
              <DetailsGridLabel label="Total quota" />
              <DetailsGridValue
                value={formatSize(selectedPreviewNode?.totalQuotaBytes ?? 0)}
              />
            </Grid>
            <Grid container={true} sx={{ paddingTop: "0 !important" }} xs={6}>
              <DetailsGridLabel label="Used quota" />
              <DetailsGridValue
                value={formatSize(selectedPreviewNode?.usedQuotaBytes ?? 0)}
              />
            </Grid>
            <Grid container={true} sx={{ paddingTop: "0 !important" }} xs={6}>
              <DetailsGridLabel label="Remaining quota" />
              <DetailsGridValue
                value={formatSize(
                  selectedPreviewNode?.remainingQuotaBytes ?? 0
                )}
              />
            </Grid>
            <Alert>
              If you need more quota, please create a request for a new Airlock
              computation.
            </Alert>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </Box>
  );
};

export default ScriptingComputeNodeFileExplorer;
