import { faArrowUpRight, faBookOpen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Stack } from "@mui/joy";
import { memo } from "react";
import { SidebarMenuItem, useSidebar } from "features/sidebar";
import { useDocsLink } from "hooks";

const DocumentationSidebarMenuItem = () => {
  const docsBaseLink = useDocsLink();
  const { isSidebarExpanded } = useSidebar();

  const tooltipTitle = (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      Docs <FontAwesomeIcon icon={faArrowUpRight} />
    </Stack>
  );
  return (
    <SidebarMenuItem
      data-testid="sidebar_documentation"
      href={docsBaseLink}
      icon={faBookOpen}
      label={isSidebarExpanded ? tooltipTitle : "Docs"}
      target="_blank"
      tooltipTitle={tooltipTitle}
      wrapperComponent={Link}
    />
  );
};

DocumentationSidebarMenuItem.displayName = "DocumentationSidebarMenuItem";

export default memo(DocumentationSidebarMenuItem);
