import { memo, useMemo } from "react";
import { type AudienceKind } from "features/mediaDataRoom/models";

const ADVERTISER_AUDIENCE_NAME_PREFIX = "All publisher users in";

interface AudienceNameProps {
  name: string;
  kind: AudienceKind;
}

const AudienceName = memo<AudienceNameProps>(({ name, kind }) => {
  const [namePrefix, shortName] = useMemo<[string | null, string]>(() => {
    if (kind !== "advertiser") {
      return [null, name];
    }
    const regex = new RegExp(`(${ADVERTISER_AUDIENCE_NAME_PREFIX}) ?(.*)`, "i");
    const match = name.match(regex);
    if (!match || match[1] !== ADVERTISER_AUDIENCE_NAME_PREFIX) {
      return [null, name];
    }
    return [match[1], match[2]];
  }, [name, kind]);
  if (!namePrefix) {
    return <>{shortName}</>;
  }
  return (
    <>
      {namePrefix}&nbsp;<i>{shortName.trim()}</i>
    </>
  );
});

AudienceName.displayName = "AudienceName";
export default AudienceName;
