import { type Session } from "@decentriq/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import {
  type ab_media_response as ddcAbMediaResponse,
  type AbMediaComputeLatest,
} from "ddc";
import { useCallback } from "react";
import { useApiCore } from "contexts";
import { useMediaDataRoomRequest } from "features/mediaDataRoom/hooks";

interface MediaDataRoomDefinition {
  definition: AbMediaComputeLatest | null;
  rawDefinition: ddcAbMediaResponse.AbMediaDcr;
  supportedFeatures: string[];
}

type MediaDataRoomHlDefinitionHookResult =
  UseQueryResult<MediaDataRoomDefinition>;

export const mapAbMediaDcrToMediaDataRoomDefinition =
  (session: Session) =>
  async (data: {
    dataRoom: ddcAbMediaResponse.AbMediaDcr;
  }): Promise<MediaDataRoomDefinition> => {
    const supportedFeatures = session.compiler.abMedia.getFeatures(
      data.dataRoom
    );
    const latestLmDcr = session.compiler.abMedia.convertToLatest(data.dataRoom);
    return {
      definition: latestLmDcr.v0.compute?.v1 ?? null,
      rawDefinition: latestLmDcr,
      supportedFeatures,
    };
  };

const useMediaDataRoomHlDefinition = ({
  queryKey,
  dataRoomId,
  driverAttestationHash,
}: {
  queryKey: (string | null)[];
  dataRoomId: string;
  driverAttestationHash: string;
}): MediaDataRoomHlDefinitionHookResult => {
  const { sessionManager } = useApiCore();
  const [fetchMediaDataRoomHlDefinition] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key: "retrieveDataRoom",
    requestCreator: useCallback(
      (dataRoomIdHex: string) => ({ dataRoomIdHex }),
      []
    ),
  });
  return useQuery({
    enabled: !!driverAttestationHash,
    queryFn: async () => {
      if (!driverAttestationHash) {
        return null;
      }
      return fetchMediaDataRoomHlDefinition().then(
        mapAbMediaDcrToMediaDataRoomDefinition(
          await sessionManager.get({ driverAttestationHash })
        )
      );
    },
    queryKey,
  });
};

export default useMediaDataRoomHlDefinition;
