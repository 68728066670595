import { Box, styled } from "@mui/joy";

const AudienceGeneratorStepsWrapper = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.spacing(7)})`,
  overflow: "auto",
  padding: theme.spacing(1),
  position: "relative",
}));

export default AudienceGeneratorStepsWrapper;
