import {
  DqSidePanel,
  DqSidePanelContent,
  DqSidePanelTitle,
} from "@decentriq/components";
import { memo } from "react";
import ExternalConnectionDetails from "features/datasets/components/ExternalConnectionDetails/ExternalConnectionDetails";
import ExternalConnectionName from "features/datasets/components/ExternalConnectionName/ExternalConnectionName";

interface ExternalConnectionDrawerProps {
  open: boolean;
  connectionId: string | null;
  onClose: () => void;
}

const ExternalConnectionDrawer = memo<ExternalConnectionDrawerProps>(
  ({ open, onClose, connectionId }) => {
    return (
      <DqSidePanel anchor="right" onClose={onClose} open={open}>
        <DqSidePanelTitle onClose={onClose} separated={true}>
          <ExternalConnectionName connectionId={connectionId} />
        </DqSidePanelTitle>
        <DqSidePanelContent>
          <ExternalConnectionDetails connectionId={connectionId} />
        </DqSidePanelContent>
      </DqSidePanel>
    );
  }
);

ExternalConnectionDrawer.displayName = "ExternalConnectionDrawer";
export default ExternalConnectionDrawer;
