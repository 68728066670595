import { useAuth0 } from "@auth0/auth0-react";
import { DqOtpInput, Loader, PromiseState } from "@decentriq/components";
import {
  faEnvelopeOpenText as fatEnvelopeOpenText,
  faExclamationTriangle as fatExclamationTriangle,
} from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Link,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Loading as Auth0Loading } from "components";
import { useApiCore, useConfiguration } from "contexts";
import { clearDecentriqStorage, logError } from "utils";
import { type EnclaveToken } from "wrappers";

interface SignInModalProps {
  authErrorState: string;
  open: boolean;
}

const SignInModal: React.FC<SignInModalProps> = ({ authErrorState, open }) => {
  const { logout } = useAuth0();
  const signOut = useCallback(() => {
    clearDecentriqStorage();
    logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
  }, [logout]);
  return (
    <Modal disableEscapeKeyDown={true} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>{authErrorState}</DialogTitle>
        <DialogContent>
          <Typography component="div" level="body-sm" textAlign="center">
            Please sign in again
          </Typography>
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column" }}>
          <Button color="primary" onClick={signOut} variant="solid">
            Sign in
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

const UnexpectedCommunicationIssueMessage = () => (
  <Fragment>
    Unexpected server-client communication issue happened. Please try to{" "}
    <Link
      color="neutral"
      onClick={() => location.reload()}
      tabIndex={0}
      underline="none"
      variant="soft"
    >
      Reload
    </Link>{" "}
    the page.
  </Fragment>
);

const MfaSessionNotFoundMessage = ({
  resendCode,
}: {
  resendCode: () => void;
}) => (
  <Fragment>
    Please try to{" "}
    <Link
      color="neutral"
      onClick={resendCode}
      tabIndex={0}
      underline="none"
      variant="soft"
    >
      Resend code
    </Link>{" "}
    again. If the issue persists, please contact{" "}
    <Link
      color="neutral"
      href="mailto:support@decentriq.com"
      tabIndex={0}
      underline="always"
    >
      support@decentriq.com
    </Link>
    .
  </Fragment>
);

const MalformedAuthenticationResponseMessage = ({
  signOut,
}: {
  signOut: () => void;
}) => (
  <Fragment>
    Authentication token could not be retrieved successfully. Please try to{" "}
    <Link
      color="neutral"
      onClick={signOut}
      tabIndex={0}
      underline="none"
      variant="soft"
    >
      Sign out
    </Link>{" "}
    and sign in again.
  </Fragment>
);

/**
 * TODO: Refactor the state machine
 * (e.g. `FormControl`'s `error` prop relies on the `message` key,
 * "Submit" button's `disabled` prop is even less obvious, etc.)
 */
type EmailMfaState =
  | null
  | { state: "expecting-code-input"; message: React.ReactNode }
  | { state: "waiting"; message: string }
  | { state: "code-retrieved"; code: string }
  | { state: "error"; error: string; message: React.ReactNode };

interface EnclaveMfaTokenDialogProps {
  setEnclaveToken: (value: string) => void;
  open: boolean;
}

const EnclaveMfaTokenDialog: React.FC<EnclaveMfaTokenDialogProps> = ({
  setEnclaveToken,
  open,
}) => {
  const { auth0ClientId, auth0Domain } = useConfiguration();
  const { sessionManager } = useApiCore();
  const { user: { email } = {}, logout } = useAuth0();
  const [authErrorState, setAuthErrorState] = useState<string | null>(null);
  const [mfaCodeFieldState, setMfaCodeFieldState] = useState("");
  const [emailMfaState, setEmailMfaState] = useState<EmailMfaState>(null);
  const fakeCode = useMemo(
    () => Math.floor(Math.random() * (999999 - 100000 + 1) + 100000),
    []
  );
  const signOut = useCallback(() => {
    clearDecentriqStorage();
    logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
  }, [logout]);
  const resendCode = useCallback(() => {
    setMfaCodeFieldState("");
    setEmailMfaState(null);
  }, []);
  useEffect(() => {
    if (!open || !email) {
      return;
    }
    const transitionState = async () => {
      let session = await sessionManager.getV2();
      if (!emailMfaState) {
        const request = {
          emailStart: { email },
        };
        setEmailMfaState({
          message: "Requesting email MFA code…",
          state: "waiting",
        });
        await session.sendAuthenticationRequest(request);
        setEmailMfaState({
          message: null,
          state: "expecting-code-input",
        });
        return;
      }
      if (emailMfaState.state === "code-retrieved") {
        const request = {
          emailFinish: { code: emailMfaState.code, email },
        };
        const authResponse = await session.sendAuthenticationRequest(request);
        if (!authResponse.emailFinish) {
          setEmailMfaState({
            error: "Malformed authentication response",
            message: <UnexpectedCommunicationIssueMessage />,
            state: "error",
          });
          return;
        }
        if (authResponse.emailFinish.incorrectCode) {
          setEmailMfaState({
            message: `The code is incorrect, please enter it again`,
            state: "expecting-code-input",
          });
          return;
        }
        if (authResponse.emailFinish.notFound) {
          setEmailMfaState({
            error: "MFA session not found",
            message: <MfaSessionNotFoundMessage resendCode={resendCode} />,
            state: "error",
          });
          return;
        }
        if (
          authResponse.emailFinish.success &&
          authResponse.emailFinish.success.token
        ) {
          setEmailMfaState(null);
          const enclaveToken: EnclaveToken = {
            email,
            token: authResponse.emailFinish.success.token,
          };
          setEnclaveToken(JSON.stringify(enclaveToken));
          return;
        }
        setEmailMfaState({
          error: "Malformed authentication response",
          message: <MalformedAuthenticationResponseMessage signOut={signOut} />,
          state: "error",
        });
      }
    };
    transitionState().catch((error) => {
      logError(error);
      setAuthErrorState(`${error}`);
    });
  }, [
    setEnclaveToken,
    sessionManager,
    auth0ClientId,
    auth0Domain,
    setAuthErrorState,
    emailMfaState,
    email,
    open,
    resendCode,
    signOut,
  ]);
  if (authErrorState) {
    return <SignInModal authErrorState={authErrorState} open={open} />;
  }
  if (!emailMfaState || !email) {
    return <Auth0Loading loading={open} />;
  }
  return (
    <Modal disableEscapeKeyDown={true} open={open}>
      {emailMfaState.state === "expecting-code-input" ||
      emailMfaState.state === "code-retrieved" ? (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setEmailMfaState({
              code: mfaCodeFieldState,
              state: "code-retrieved",
            });
          }}
        >
          <ModalDialog role="alertdialog">
            <DialogTitle>
              <Box sx={{ position: "relative" }}>
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={fatEnvelopeOpenText}
                  size="3x"
                />
                <Box
                  component="span"
                  sx={{
                    fontSize: "6px",
                    fontWeight: 900,
                    left: 0,
                    position: "absolute",
                    textAlign: "center",
                    top: "33%",
                    width: "100%",
                  }}
                >
                  {fakeCode}
                </Box>
              </Box>
              Enter the code from your email
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <Typography level="body-sm" sx={{ textWrap: "balance" }}>
                  An MFA email has been sent to <strong>{email}</strong>.<br />
                  If you can't access this email, click{" "}
                  <Link
                    color="primary"
                    onClick={signOut}
                    tabIndex={0}
                    underline="hover"
                  >
                    <strong>Sign out</strong>
                  </Link>{" "}
                  to use a different account. Can't find it? Check your Spam
                  folder or click{" "}
                  <Link
                    color="primary"
                    onClick={resendCode}
                    tabIndex={0}
                    underline="hover"
                  >
                    <strong>Resend code</strong>
                  </Link>
                  .
                </Typography>
                <FormControl
                  error={
                    emailMfaState.state === "expecting-code-input" &&
                    Boolean(emailMfaState.message)
                  }
                  sx={{ alignItems: "center" }}
                >
                  <DqOtpInput
                    disabled={emailMfaState.state !== "expecting-code-input"}
                    maxLength={6}
                    onChange={(newValue) => {
                      setMfaCodeFieldState(newValue);
                      setEmailMfaState((currentState) =>
                        currentState?.state === "expecting-code-input"
                          ? { ...currentState, message: null }
                          : currentState
                      );
                    }}
                    pattern="^\d+$"
                    slotProps={{
                      sheet: {
                        color:
                          emailMfaState.state === "expecting-code-input" &&
                          Boolean(emailMfaState.message)
                            ? "danger"
                            : "neutral",
                      },
                    }}
                    value={mfaCodeFieldState}
                  />
                  {emailMfaState.state === "expecting-code-input" ? (
                    <FormHelperText sx={{ display: "none" }}>
                      {emailMfaState.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ flexDirection: "column" }}>
              <Button
                color="primary"
                disabled={
                  emailMfaState.state === "code-retrieved" ||
                  mfaCodeFieldState.length !== 6 ||
                  Boolean(emailMfaState.message)
                }
                loading={emailMfaState.state === "code-retrieved"}
                loadingPosition="start"
                type="submit"
                variant="solid"
              >
                Submit
              </Button>
            </DialogActions>
          </ModalDialog>
        </form>
      ) : emailMfaState.state === "waiting" ? (
        <ModalDialog role="alertdialog">
          <DialogTitle>
            <Loader
              loadLabel={emailMfaState.message}
              loadState={PromiseState.Pending}
            />
          </DialogTitle>
        </ModalDialog>
      ) : emailMfaState.state === "error" ? (
        <ModalDialog role="alertdialog">
          <DialogTitle>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={fatExclamationTriangle}
              size="3x"
            />
            {emailMfaState.error}
          </DialogTitle>
          <DialogContent>
            <Typography level="body-sm" sx={{ textWrap: "balance" }}>
              {emailMfaState.message}
            </Typography>
          </DialogContent>
        </ModalDialog>
      ) : (
        <Fragment />
      )}
    </Modal>
  );
};

export default EnclaveMfaTokenDialog;
