import { differenceInCalendarDays } from "date-fns";
import { useSnackbar } from "notistack";
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { KeychainStatus } from "services";
import { EnclaveMfaTokenDialog } from "components";
import { MigrationStatus, useApiCore } from "contexts";
import { useSafeContext } from "hooks";
import { useEnclaveTokenStorage, useKeychainSetup } from "wrappers";
import {
  MigrationAvailableDialog,
  MigrationProgressDialog,
  MigrationRequiredDialog,
} from "./components";
import { useKeychainMigration } from "./hooks";

/* State machine for keychain migration:
 * Starting states: NoMigration, PromptMigration, MigrationInProgress
 * NoMigration: No migration required
 *
 * PromptMigration: -> Yes -> MFAPrompt -> MFA successful -> MigrationInProgress -> [close] + success popup
 *                  -> No -> MigrationRequiredWarning -> [close]
 */
const enum KeychainMigrationState {
  NoMigration = "NoMigration",
  PromptMigration = "PromptMigration",
  MigrationRequiredWarning = "MigrationRequiredWarning",
  MFAPrompt = "MFAPrompt",
  MigrationInProgress = "MigrationInProgress",
  Migrated = "Migrated",
}

const upstreamMigrationStatusToMigrationState: Record<
  MigrationStatus,
  KeychainMigrationState
> = {
  [MigrationStatus.Migrated]: KeychainMigrationState.NoMigration,
  [MigrationStatus.NoMigration]: KeychainMigrationState.NoMigration,
  [MigrationStatus.PromptMigration]: KeychainMigrationState.PromptMigration,
  [MigrationStatus.MigrationInProgress]:
    KeychainMigrationState.MigrationInProgress,
};

const keychainMigrationContext = createContext<{
  onPromptMigrationClick: () => void;
} | null>(null);

export const useKeychainMigrationContext = () =>
  useSafeContext(keychainMigrationContext);

const KeychainMigrationWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { migrationStatus: upstreamMigrationStatus, migrationDeadline } =
    useApiCore();
  const { progress, migrate: startOrResumeMigration } = useKeychainMigration();
  const [keychainMigrationState, setKeychainMigrationState] =
    useState<KeychainMigrationState>(KeychainMigrationState.NoMigration);

  const { state: enclaveToken, setState: setGlobalEnclaveToken } =
    useEnclaveTokenStorage();
  const [localEnclaveToken, setLocalEnclaveToken] = useState<string | null>(
    null
  );

  const { status: keychainStatus } = useKeychainSetup();

  useEffect(() => {
    if (keychainStatus === KeychainStatus.unlocked) {
      setKeychainMigrationState(
        upstreamMigrationStatusToMigrationState[upstreamMigrationStatus]
      );
    }
  }, [keychainStatus, upstreamMigrationStatus]);

  useEffect(() => {
    if (
      localEnclaveToken &&
      keychainMigrationState === KeychainMigrationState.MigrationInProgress
    ) {
      startOrResumeMigration(localEnclaveToken).then((migrated) => {
        if (!migrated) {
          enqueueSnackbar(
            "Migration failed, please refresh. If the problem persists, contact support@decentriq.com.",
            { persist: true, variant: "error" }
          );
          setKeychainMigrationState(KeychainMigrationState.NoMigration);
          return;
        }
        setGlobalEnclaveToken(localEnclaveToken);
        setKeychainMigrationState(KeychainMigrationState.Migrated);
        enqueueSnackbar("Keychain migration completed successfully.", {
          persist: true,
          variant: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    }
  }, [
    enqueueSnackbar,
    enclaveToken,
    keychainMigrationState,
    startOrResumeMigration,
    localEnclaveToken,
    setGlobalEnclaveToken,
  ]);

  const daysLeftToMigrate = differenceInCalendarDays(
    migrationDeadline,
    new Date()
  );

  const onSetEncalveToken = useCallback((token: string) => {
    setLocalEnclaveToken(token);
    setKeychainMigrationState(KeychainMigrationState.MigrationInProgress);
  }, []);

  const onPromptMigrationClick = useCallback(() => {
    setKeychainMigrationState(KeychainMigrationState.PromptMigration);
  }, []);

  const keychainMigrationContextValue = useMemo(
    () => ({ onPromptMigrationClick }),
    [onPromptMigrationClick]
  );

  return (
    <>
      <MigrationAvailableDialog
        onClose={() => {
          setKeychainMigrationState(
            KeychainMigrationState.MigrationRequiredWarning
          );
        }}
        onConfirm={() => {
          setKeychainMigrationState(KeychainMigrationState.MFAPrompt);
        }}
        open={keychainMigrationState === KeychainMigrationState.PromptMigration}
        postponeable={daysLeftToMigrate > 0}
      />
      <EnclaveMfaTokenDialog
        open={keychainMigrationState === KeychainMigrationState.MFAPrompt}
        setEnclaveToken={onSetEncalveToken}
      />
      <MigrationProgressDialog
        open={
          keychainMigrationState === KeychainMigrationState.MigrationInProgress
        }
        progress={progress ?? 0}
      />
      <MigrationRequiredDialog
        daysLeft={daysLeftToMigrate}
        onClose={() => {
          setKeychainMigrationState(KeychainMigrationState.NoMigration);
        }}
        onConfirm={() => {
          setKeychainMigrationState(KeychainMigrationState.MFAPrompt);
        }}
        open={
          keychainMigrationState ===
          KeychainMigrationState.MigrationRequiredWarning
        }
      />
      <keychainMigrationContext.Provider value={keychainMigrationContextValue}>
        {children}
      </keychainMigrationContext.Provider>
    </>
  );
};
export default KeychainMigrationWrapper;
