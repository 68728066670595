import {
  AudienceCombinatorOperator,
  AudienceCondition,
  type AudienceFilters,
  AudienceFiltersBooleanOperator,
  type AudienceRulesTree,
} from "@decentriq/components";
import { type parameter_payloads as ddcParameterPayloads } from "ddc";
import {
  ALL_PUBLISHER_USERS_AUDIENCE,
  type Audience,
  type RuleBasedAudience,
} from "features/mediaDataRoom/models";

export const replaceDecentriqOrgName = (
  orgName: string | undefined,
  replacement: string
): string => (orgName === "Decentriq" || !orgName ? replacement : orgName);

// Transforms data for UI purposes
export const formatBasePropensity = (basePropensity: number): string =>
  `${basePropensity}%`;
export const formatOverlapPropensity = (overlapPropensity: number): string =>
  `${overlapPropensity}%`;
export const formatNetPropensity = (netPropensity: number): string =>
  `${netPropensity}x`;

export const normalizeEmail = (email: string) => email.trim().toLowerCase();

// TODO: this should be removed after checking with Lorenzo that DCR with email case issue is not used anymore
export const isNormalizedEmailIncluded = (
  emails: (string | null | undefined)[],
  currentEmail: string
) =>
  emails.some(
    (email) => normalizeEmail(email || "") === normalizeEmail(currentEmail)
  );

const mapFilterOperatorToAudienceCondition: Record<
  ddcParameterPayloads.FilterOperator,
  AudienceCondition
> = {
  contains_all_of: AudienceCondition.CONTAINS_ALL_OF,
  contains_any_of: AudienceCondition.CONTAINS_ANY_OF,
  contains_none_of: AudienceCondition.CONTAINS_NONE_OF,
  empty: AudienceCondition.EMPTY,
  not_empty: AudienceCondition.NOT_EMPTY,
};

const mapCombineOperatorToAudienceCombinatorOperator: Record<
  ddcParameterPayloads.CombineOperator,
  AudienceCombinatorOperator
> = {
  diff: AudienceCombinatorOperator.DIFF,
  intersect: AudienceCombinatorOperator.INTERSECT,
  union: AudienceCombinatorOperator.UNION,
};

const mapBooleanOpToAudienceFiltersOperator: Record<
  ddcParameterPayloads.BooleanOp,
  AudienceFiltersBooleanOperator
> = {
  and: AudienceFiltersBooleanOperator.AND,
  or: AudienceFiltersBooleanOperator.OR,
};

const mapDdcAudienceFiltersToAudienceFilters = (
  filters: ddcParameterPayloads.AudienceFilters
): AudienceFilters => ({
  filters: filters.filters.map(({ attribute, operator, values }) => ({
    attribute,
    condition: mapFilterOperatorToAudienceCondition[operator],
    values: values ?? null,
  })),
  operator: mapBooleanOpToAudienceFiltersOperator[filters.boolean_op],
});

const getAudienceById = (id: string, audiences: Audience[]): Audience | null =>
  [...audiences, ALL_PUBLISHER_USERS_AUDIENCE].find(
    (audience) => audience.id === id
  ) ?? null;

export const mapRulesBasedAudiencetoAudienceRulesTree = (
  { combine, filters, source_ref }: RuleBasedAudience,
  audiences: Audience[]
): AudienceRulesTree => ({
  combine: combine
    ? combine.map(({ operator, source_ref, filters }) => ({
        filters: filters
          ? mapDdcAudienceFiltersToAudienceFilters(filters)
          : undefined,
        name: getAudienceById(source_ref, audiences)?.mutable?.name ?? "",
        operator: mapCombineOperatorToAudienceCombinatorOperator[operator],
        source_ref,
      }))
    : null,
  filters: filters ? mapDdcAudienceFiltersToAudienceFilters(filters) : null,
  name: getAudienceById(source_ref, audiences)?.mutable?.name ?? "",
  source_ref,
});

export const formatNumber = (number: number): string =>
  Intl.NumberFormat("de-DE", { notation: "standard" }).format(number);
