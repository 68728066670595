import { usePublishedMediaDataRoomQuery } from "@decentriq/graphql/dist/hooks";
import { DataRoomKind } from "@decentriq/graphql/dist/types";
import { memo, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { type DataRoomContextValue, DataRoomProvider } from "contexts";
import { NonMigratedUserDataRoomAccessWrapper } from "features/dataRooAccessGuard";
import { MediaDataRoom } from "features/mediaDataRoom";
import { MediaDataRoom as LegacyMediaDataRoom } from "features/MediaInsightsDcr";
import { type DataRoomTypeNames } from "models";

const MediaDataRoomPage: React.FC = () => {
  const { dataRoomId } = useParams();
  // TODO: Moving querying to context for now to support all media insights dcr and split on kind
  const { data, loading, error } = usePublishedMediaDataRoomQuery({
    variables: { id: dataRoomId || "" },
  });
  const dataRoomContextValue = useMemo<DataRoomContextValue>(
    () => ({
      allowTestMode: false,
      dataRoomId: dataRoomId || "__default__",
      isPublished: true,
    }),
    [dataRoomId]
  );
  if (loading) {
    return null;
  }
  if (error || !data?.publishedMediaDataRoom) {
    return <Navigate replace={true} to="/datarooms" />;
  }
  const kind = data.publishedMediaDataRoom.kind;
  return (
    <NonMigratedUserDataRoomAccessWrapper
      __typename={data.publishedMediaDataRoom.__typename as DataRoomTypeNames}
      driverAttestationHash={data.publishedMediaDataRoom.driverAttestationHash}
    >
      <DataRoomProvider value={dataRoomContextValue}>
        {kind === DataRoomKind.AbMedia ? (
          <MediaDataRoom data={data.publishedMediaDataRoom} />
        ) : (
          <LegacyMediaDataRoom data={data.publishedMediaDataRoom!} />
        )}
      </DataRoomProvider>
    </NonMigratedUserDataRoomAccessWrapper>
  );
};

export default memo(MediaDataRoomPage);
