import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import UpsertDataPartnerDisplayConfigurationDialog from "../UpsertDataPartnerDisplayConfigurationDialog/UpsertDataPartnerDisplayConfigurationDialog";

const UpdateDataPartnerDisplayConfigurationButton = memo(() => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  return (
    <span>
      <Button
        color="primary"
        onClick={openDialog}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPencil} />}
        variant="soft"
      >
        Edit
      </Button>
      <UpsertDataPartnerDisplayConfigurationDialog
        onClose={closeDialog}
        open={isDialogOpen}
        updating={true}
      />
    </span>
  );
});
UpdateDataPartnerDisplayConfigurationButton.displayName =
  "UpdateDataPartnerDisplayConfigurationButton";

export default UpdateDataPartnerDisplayConfigurationButton;
