import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { Grid } from "@mui/joy";
import { memo } from "react";
import { EmptyData } from "components";
import AdvertiserAudienceGeneratorHeader from "../AdvertiserAudienceGeneratorStep/AdvertiserAudienceGeneratorHeader";

interface AdvertiserAudienceGeneratorErrorStateProps {
  message?: string;
  RetryButton?: React.ReactNode;
}

const AdvertiserAudienceGeneratorErrorState =
  memo<AdvertiserAudienceGeneratorErrorStateProps>(
    ({ message, RetryButton }) => {
      return (
        <Grid container={true} rowSpacing={4}>
          <Grid xs={7}>
            <AdvertiserAudienceGeneratorHeader />
          </Grid>
          <Grid xs={12}>
            <EmptyData
              icon={faTriangleExclamation}
              title={message || "Audience data could not be retrieved"}
            >
              {RetryButton && RetryButton}
            </EmptyData>
          </Grid>
        </Grid>
      );
    }
  );

AdvertiserAudienceGeneratorErrorState.displayName =
  "AdvertiserAudienceGeneratorErrorState";

export default AdvertiserAudienceGeneratorErrorState;
