import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  useTheme,
} from "@mui/joy";
import { memo } from "react";
import MediaDataRoomSummary from "./MediaDataRoomSummary";

interface MediaDataRoomViewConfigurationDialogProps {
  open: boolean;
  onCancel: () => void;
}

const MediaDataRoomViewConfigurationDialog: React.FC<MediaDataRoomViewConfigurationDialogProps> =
  memo(({ open, onCancel }) => {
    const { breakpoints } = useTheme();
    return (
      <Modal open={open}>
        <ModalDialog minWidth={breakpoints.values.md}>
          <DialogTitle>Media data clean room configuration</DialogTitle>
          <Divider />
          <DialogContent>
            <MediaDataRoomSummary />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button color="neutral" onClick={onCancel} variant="plain">
              Close
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  });

export default MediaDataRoomViewConfigurationDialog;
