import { usePublishedDataRoomOverviewQuery } from "@decentriq/graphql/dist/hooks";
import { useCallback, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { PublishedDataRoom } from "containers";
import { type DataRoomContextValue, DataRoomProvider } from "contexts";
import { NonMigratedUserDataRoomAccessWrapper } from "features";
import { type DataRoomTypeNames } from "models";

const PublishedDataRoomPage = () => {
  const { dataRoomId } = useParams();
  const [contextValue, setContextValue] = useState<DataRoomContextValue>({
    allowTestMode: true,
    dataRoomId: dataRoomId || "__default__",
    isPublished: true,
  });
  useEffect(() => {
    setContextValue((state) => ({
      ...state,
      dataRoomId: dataRoomId || "__default__",
    }));
  }, [dataRoomId, setContextValue]);
  const setAllowTestMode = useCallback(
    (allowTestMode: boolean) => {
      setContextValue((state) => ({ ...state, allowTestMode }));
    },
    [setContextValue]
  );

  const { data, loading, error } = usePublishedDataRoomOverviewQuery({
    skip: !dataRoomId,
    variables: { dataRoomId: dataRoomId || "" },
  });

  if (loading) {
    return null;
  }
  if (error || !data?.publishedDataRoom) {
    return <Navigate replace={true} to="/datarooms" />;
  }

  return (
    <NonMigratedUserDataRoomAccessWrapper
      __typename={data?.publishedDataRoom.__typename as DataRoomTypeNames}
      driverAttestationHash={data.publishedDataRoom.driverAttestationHash}
    >
      <DataRoomProvider value={contextValue}>
        <PublishedDataRoom
          dataRoomId={dataRoomId!}
          setAllowTestMode={setAllowTestMode}
        />
      </DataRoomProvider>
    </NonMigratedUserDataRoomAccessWrapper>
  );
};

export default PublishedDataRoomPage;
