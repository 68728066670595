import { AppConfigurationClient } from "@azure/app-configuration";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useConfiguration } from "contexts";
import { logError } from "utils";

interface UseAzureAppConfigurationParams<T> {
  configKey: string;
  polling?: number;
  defaultValue: T;
}

interface UseAzureAppConfigurationResult<T> {
  value: T;
  error: Error | null;
}

/**
 * Hook to fetch and parse configuration values from Azure App Configuration
 * https://portal.azure.com/#browse/Microsoft.AppConfiguration%2FconfigurationStores
 * (operations / Configuration explorer)
 * @template T - The expected type of the configuration value
 * @param {UseAzureAppConfigurationParams<T>} params - Configuration parameters
 * @param params.configKey - The key of the configuration to fetch
 * @param params.defaultValue - The default value to use if the configuration is not yet loaded
 * @param params.polling - The polling interval in milliseconds to check for updates to the configuration
 * @returns {UseAzureAppConfigurationResult<T>} Object containing:
 *  - value: T - The configuration value (uses defaultValue if not yet loaded)
 *  - error: Error | null - Any error that occurred while fetching the configuration
 * @remarks
 * Supports both JSON and plain text configuration values. JSON values are automatically parsed.
 * Logs errors to console if the configuration fetch fails.
 */
const useAzureAppConfiguration = <T>({
  configKey,
  defaultValue,
  polling,
}: UseAzureAppConfigurationParams<T>): UseAzureAppConfigurationResult<T> => {
  const { azureAppConfigurationConnectionString } = useConfiguration();
  const appConfigurationClient = useMemo(
    () => new AppConfigurationClient(azureAppConfigurationConnectionString),
    [azureAppConfigurationConnectionString]
  );

  const {
    data: configurationValue,
    error: configurationSettingError,
    status: configurationSettingStatus,
  } = useQuery({
    queryFn: async (): Promise<T> => {
      const configurationSetting =
        await appConfigurationClient.getConfigurationSetting(
          {
            key: configKey,
          },
          {
            requestOptions: {
              customHeaders: {
                "Cache-Control": "no-cache,no-store,max-age=0",
              },
            },
          }
        );

      if (configurationSetting.value == null) {
        throw new Error(`App configuration "${configKey}" is not set remotely`);
      }
      switch (configurationSetting.contentType) {
        case "application/json": {
          return JSON.parse(configurationSetting.value);
        }
        case "text/plain": {
          return configurationSetting.value as T;
        }
        default: {
          throw new Error(
            `App Configuration "${configKey}" has unknown Content type "${configurationSetting.contentType}"`
          );
        }
      }
    },
    queryKey: ["app-configuration", configKey],
    refetchInterval: polling,
  });

  useEffect(() => {
    if (configurationSettingError) {
      logError(configurationSettingError);
    }
  }, [configurationSettingError]);

  return useMemo(
    () => ({
      error: configurationSettingError,
      value:
        configurationSettingStatus === "success"
          ? configurationValue
          : defaultValue,
    }),
    [
      configurationSettingError,
      configurationSettingStatus,
      configurationValue,
      defaultValue,
    ]
  );
};

export default useAzureAppConfiguration;
