import { InfoTooltip } from "@decentriq/components";
import { Slider, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";

const AudienceGeneratorReachSlider = memo(() => {
  const { reach, setReach } = useLookalikeAdvertiserAudienceGenerator();

  const sliderMarks = [...Array(31).keys()].map((reach, index, array) => ({
    label: index === 1 || index === array.length - 1 ? `${reach}%` : "",
    value: reach,
  }));

  return (
    <Stack alignItems="center" direction="row" spacing={4}>
      <Typography fontWeight={600} level="body-md">
        Precision
      </Typography>
      <Slider
        marks={sliderMarks}
        max={30}
        min={1}
        onChange={(event, newReach: number | number[]) =>
          setReach(newReach as number)
        }
        step={null}
        sx={{
          "& .MuiSlider-markLabel": { top: "1.5rem" },
          minWidth: 200,
        }}
        value={reach}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />
      <Typography display="inline-flex" fontWeight={600} level="body-md">
        Reach
        <InfoTooltip tooltip="The generated audience will include users sorted by similarity until the desired reach is achieved." />
      </Typography>
    </Stack>
  );
});

AudienceGeneratorReachSlider.displayName = "AudienceGeneratorReachSlider";

export default AudienceGeneratorReachSlider;
