import { useDataConnectorJobResultLazyQuery } from "@decentriq/graphql/dist/hooks";
import { useCallback, useState } from "react";

type ExternalConnectioResultHookResult = [
  () => void,
  { error: string; isLoading: boolean },
];

const useExternalConnectionResult = (
  connectionId: string
): ExternalConnectioResultHookResult => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fetch enclave data to show on expanded item
  const [fetchDataConnectorJobResult] = useDataConnectorJobResultLazyQuery({
    variables: { id: connectionId },
  });

  const fetchExternalConnectionResult = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const { data: { dataConnectorJob } = {} } =
      await fetchDataConnectorJobResult();
    setError(dataConnectorJob?.result?.error || "");
    setIsLoading(false);
  }, [fetchDataConnectorJobResult]);

  return [fetchExternalConnectionResult, { error, isLoading }];
};

export default useExternalConnectionResult;
