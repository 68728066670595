import {
  useDataConnectorJobsQuery,
  usePollDatasetExportMutation,
  usePollDatasetImportMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJob,
  DataConnectorJobType,
  DataImportExportStatus,
  DatasetsDocument,
} from "@decentriq/graphql/dist/types";
import { useEffect, useMemo, useState } from "react";
import { useKeychainSetup } from "wrappers";
import { KeychainItemKind, KeychainStatus } from "../services/keychain";
import { useKeychainItems } from "./keychain/useKeychainItems";

const usePendingImportsFromKeychain = (itemKind: KeychainItemKind) => {
  const { items: keychainItems } = useKeychainItems();
  return useMemo(
    () =>
      keychainItems.filter((item) => {
        return item.kind === itemKind;
      }),
    [keychainItems, itemKind]
  );
};

/**
 * This hook's job is to poll the result of all pending dataset imports.
 * For each import we'll trigger the process of polling the enclave for the import
 * result (the manifest hash of the new dataset), so that we can update the keychain
 * with the new key->manifestHash mapping.
 * This is necessary, as the user might have closed the browser window
 * in an earlier dataset import attempt, without having waited for the import
 * to finish.
 */
export default function useDatasetImportExportWatcher() {
  const { status: keychainStatus } = useKeychainSetup();
  const setCurrentlyRunningImportIds = useState<string[]>([])[1];
  const setCurrentlyRunningExportIds = useState<string[]>([])[1];
  // When a new dataset import data connector job is created,
  // this component's useEffect callback should run again
  // to trigger the import polling procedure.
  const allDataConnectorJobs = useDataConnectorJobsQuery({
    variables: {
      filter: null,
    },
  });
  const [pollDatasetImportMutation] = usePollDatasetImportMutation({
    refetchQueries: [{ query: DatasetsDocument }],
  });
  const [pollDatasetExportMutation] = usePollDatasetExportMutation({
    refetchQueries: [{ query: DatasetsDocument }],
  });
  // Note that this effect depends on `allDatasetImports`. If a new dataset import
  // is being created by the import wizard, there will also be a new keychain
  // item for a pending dataset import.
  // Ideally this component would directly depend on the keychain items cache
  // (or whatever other state management system we end up using).
  const pendingImports = usePendingImportsFromKeychain(
    KeychainItemKind.PendingDatasetImport
  );
  useEffect(
    () =>
      setCurrentlyRunningImportIds((currentState) => {
        if (
          keychainStatus !== KeychainStatus.unlocked ||
          (currentState.length === 0 && pendingImports.length === 0)
        ) {
          return currentState;
        }
        const pendingImportIds = pendingImports.map((item) => item.id);
        const importsToStart = pendingImportIds.filter(
          (id) => !currentState.includes(id)
        );
        if (importsToStart.length === 0) {
          return currentState;
        }
        Promise.all(
          pendingImportIds.map((id) =>
            pollDatasetImportMutation({ variables: { id } })
          )
        );
        return [...new Set([...currentState, ...pendingImportIds])];
      }),
    [
      keychainStatus,
      pendingImports,
      setCurrentlyRunningImportIds,
      pollDatasetImportMutation,
    ]
  );
  useEffect(
    () =>
      setCurrentlyRunningExportIds((currentState) => {
        const pendingExports = (
          allDataConnectorJobs?.data?.dataConnectorJobs?.nodes || []
        ).filter(
          ({ status, type }: DataConnectorJob) =>
            type === DataConnectorJobType.ExportDataset &&
            status === DataImportExportStatus.Pending
        );
        if (
          keychainStatus !== KeychainStatus.unlocked ||
          (currentState.length === 0 && pendingExports.length === 0)
        ) {
          return currentState;
        }
        const pendingExportIds = pendingExports.map((item) => item.id);
        const exportsToStart = pendingExportIds.filter(
          (id) => !currentState.includes(id)
        );
        if (exportsToStart.length === 0) {
          return currentState;
        }
        Promise.all(
          pendingExportIds.map((id) =>
            pollDatasetExportMutation({ variables: { id } })
          )
        );
        return [...new Set([...currentState, ...exportsToStart])];
      }),
    [
      keychainStatus,
      allDataConnectorJobs?.data?.dataConnectorJobs?.nodes,
      setCurrentlyRunningExportIds,
      pollDatasetExportMutation,
    ]
  );
}
