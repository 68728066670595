import { DqLoader } from "@decentriq/components";
import { accordionDetailsClasses, AccordionGroup, Box } from "@mui/joy";
import { memo } from "react";
import { useMediaDataRoom } from "features/mediaDataRoom";
import {
  AdvertiserDataNode,
  MediaDataroomDataTabOverlap,
  PublisherDataNode,
} from "features/mediaDataRoom/components";
import { useMediaDataRoomInsightsData } from "features/mediaDataRoom/contexts";

const MediaDataRoomData = memo(() => {
  const {
    datasets: { isLoading: datasetsLoading },
  } = useMediaDataRoomInsightsData();
  const {
    supportedFeatures: { showAbsoluteValues },
  } = useMediaDataRoom();

  if (datasetsLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <DqLoader label="Loading data..." />
      </Box>
    );
  }
  return (
    <>
      <AccordionGroup
        className="separated"
        sx={{
          [`& .${accordionDetailsClasses.content}`]: {
            boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
            [`&.${accordionDetailsClasses.expanded}`]: {
              paddingBlock: "0.75rem",
            },
          },
          flex: 0,
        }}
      >
        <AdvertiserDataNode />
        <PublisherDataNode />
      </AccordionGroup>
      {showAbsoluteValues && <MediaDataroomDataTabOverlap />}
    </>
  );
});
MediaDataRoomData.displayName = "MediaDataRoomData";

export default MediaDataRoomData;
