import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBan as falBan } from "@fortawesome/pro-light-svg-icons";
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Stack, type StackProps, Typography } from "@mui/joy";
import { memo } from "react";

interface EmptyDataProps {
  icon?: IconProp;
  title: string;
  description?: string;
  slotProps?: {
    containerProps?: StackProps;
    iconProps?: FontAwesomeIconProps;
  };
}

const EmptyData = memo<React.PropsWithChildren<EmptyDataProps>>(
  ({ icon = falBan, title, description, slotProps, children }) => (
    <Stack
      alignItems="center"
      height="100%"
      justifyContent="center"
      width="100%"
      {...slotProps?.containerProps}
    >
      {icon && (
        <FontAwesomeIcon
          fixedWidth={true}
          icon={icon}
          size="2x"
          {...slotProps?.iconProps}
        />
      )}
      {title || description ? (
        <Stack alignItems="center" gap={0} justifyContent="center">
          {title && <Typography level="body-sm">{title}</Typography>}
          {description && (
            <Typography level="body-sm">{description}</Typography>
          )}
        </Stack>
      ) : null}
      {children}
    </Stack>
  )
);

export default EmptyData;
