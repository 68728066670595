import { DqLoader } from "@decentriq/components";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  type ModalProps,
  Stack,
} from "@mui/joy";
import { memo, type MouseEventHandler } from "react";

type CustomAdminDialogProps = {
  title: React.ReactNode;
  disabled?: boolean;
  confirmButtonText?: string;
  withConfirmButton?: boolean;
  withCancelButton?: boolean;
  onConfirm?: () => void;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
};

type AdminDialogProps = CustomAdminDialogProps &
  Omit<ModalProps, "onClose" | "title">;

const AdminDialog: React.FC<AdminDialogProps> = ({
  open,
  title,
  loading = false,
  onClose,
  children,
  onConfirm,
  disabled = false,
  confirmButtonText = "Create",
  withConfirmButton = true,
  withCancelButton = true,
  ...restDialogProps
}) => (
  <Modal onClose={onClose} open={open} {...restDialogProps}>
    <ModalDialog>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        {loading ? (
          <Stack alignItems="center" justifyItems="center" p={2}>
            <DqLoader label="Sending invitation..." />
          </Stack>
        ) : (
          children
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        {withCancelButton && (
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
        )}
        {withConfirmButton && (
          <Button
            color="primary"
            disabled={disabled || loading}
            onClick={onConfirm}
            variant="solid"
          >
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </ModalDialog>
  </Modal>
);
AdminDialog.displayName = "AdminDialog";

export default memo(AdminDialog);
