import { faExclamationTriangle as falExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { Typography } from "@mui/material";
import { memo } from "react";

export interface MigrationRequiredDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  daysLeft: number;
}

const MigrationRequiredDialog = memo<MigrationRequiredDialogProps>(
  ({ open, onClose, onConfirm, daysLeft }) => {
    return (
      <Modal onClose={onClose} open={open}>
        <ModalDialog color="danger" role="alertdialog">
          <DialogTitle>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={falExclamationTriangle}
              size="3x"
            />
            {/* This should be colored in the JoyTheme but it doesn't work */}
            <span style={{ color: "initial" }}>Migration required</span>
          </DialogTitle>
          <DialogContent>
            <Stack direction="column">
              <Typography> Remaining days: {daysLeft}</Typography>
              <Alert
                slotProps={{
                  root: { sx: { textAlign: "left", textWrap: "auto" } },
                }}
              >
                You can postpone the migration for up to 90 days. After 90 days,
                migration will be mandatory to continue using the platform.
              </Alert>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ flexDirection: "column", pt: `0 !important` }}>
            <Button color="primary" onClick={onConfirm} variant="solid">
              Migrate now
            </Button>
            <Button onClick={onClose} size="sm">
              I’ll do it later
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }
);

MigrationRequiredDialog.displayName = "MigrationRequiredDialog";
export default MigrationRequiredDialog;
