import { type AudienceAttributes } from "@decentriq/components";
import {
  ColumnDataType,
  type MatchingColumnFormat,
  TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { type parameter_payloads as ddcParameterPayloads } from "ddc";
import { type DataRoomTableColumn } from "models";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";

export enum MediaDataRoomUserRole {
  Publisher = "publisher",
  Advertiser = "advertiser",
  Observer = "observer",
  Agency = "agency",
  DataPartner = "dataPartner",
}

export enum MediaDataRoomTab {
  data = "data",
  insights = "insights",
  activation = "activation",
  auditLog = "auditLog",
}

export enum MediaDataRoomActivationTab {
  creation = "creation",
}

export const mediaDataRoomTabs = [
  MediaDataRoomTab.data,
  MediaDataRoomTab.insights,
  MediaDataRoomTab.activation,
  MediaDataRoomTab.auditLog,
];

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

export interface OverlapSegmentsCollection {
  audiences: OverlapSegment[];
}

export interface PublisherDatasetsHashes {
  matchingDatasetHash: string | null;
  segmentsDatasetHash: string | null;
  demographicsDatasetHash: string | null;
  embeddingsDatasetHash: string | null;
}

/**
 * This class is used to store the hashes of the datasets that are used in the media data room
 * It also contains audiences node dataset hash and the combined hash of all datasets (advertiser & publisher) which are used as a part of the key to store the cache.
 * Also this class provides a methods to update certain hashes which are used then during provisioning/deprovisioning of the data room data.
 */
export class PublishedDatasetsHashes {
  /**
   * Creates a cache key object from the provided advertiser & publisher datasets hashes
   */
  static datasetsCacheKeyCreator = (
    advertiserDatasetHash: string | null,
    publisherDatasetsHashes: PublisherDatasetsHashes
  ) => {
    if (
      !advertiserDatasetHash ||
      !publisherDatasetsHashes?.matchingDatasetHash
    ) {
      return null;
    }
    return {
      advertiserDatasetHash,
      matchingDatasetHash: publisherDatasetsHashes.matchingDatasetHash,
      ...(publisherDatasetsHashes.segmentsDatasetHash
        ? {
            publisherSegmentsDatasetHash:
              publisherDatasetsHashes.segmentsDatasetHash,
          }
        : {}),
      ...(publisherDatasetsHashes.demographicsDatasetHash
        ? {
            publisherDemographicsDatasetHash:
              publisherDatasetsHashes.demographicsDatasetHash,
          }
        : {}),
      ...(publisherDatasetsHashes.embeddingsDatasetHash
        ? {
            publisherEmbeddingsDatasetHash:
              publisherDatasetsHashes.embeddingsDatasetHash,
          }
        : {}),
    };
  };

  /**
   * Creates a cache key string from the provided advertiser & publisher datasets hashes
   */
  static datasetsCacheKeyAsStringCreator = (
    advertiserDatasetHash: string | null,
    publisherDatasetsHashes: PublisherDatasetsHashes
  ) => {
    const cacheObj = PublishedDatasetsHashes.datasetsCacheKeyCreator(
      advertiserDatasetHash,
      publisherDatasetsHashes
    );
    if (!cacheObj) {
      return null;
    }
    return computeCacheKeyString(cacheObj);
  };

  static defaultPublisherDatasetsHashes = {
    demographicsDatasetHash: null,
    embeddingsDatasetHash: null,
    matchingDatasetHash: null,
    segmentsDatasetHash: null,
  };

  constructor(
    public readonly advertiserDatasetHash: string | null = null,
    public readonly publisherDatasetsHashes: PublisherDatasetsHashes = PublishedDatasetsHashes.defaultPublisherDatasetsHashes,
    public readonly audiencesDatasetHash: string | null = null,
    public readonly datasetsHash: string | null | undefined = undefined
  ) {
    this.datasetsHash =
      datasetsHash === undefined
        ? PublishedDatasetsHashes.datasetsCacheKeyAsStringCreator(
            advertiserDatasetHash,
            publisherDatasetsHashes
          )
        : datasetsHash;
  }

  /**
   * Returns true if advertiser datasets are present
   */
  public get hasAdvertiserData() {
    return this.advertiserDatasetHash !== null;
  }

  /**
   * Returns true if publisher datasets are present
   */
  public get hasPublisherData() {
    return this.publisherDatasetsHashes.matchingDatasetHash !== null;
  }

  /**
   * Returns true if both advertiser and publisher datasets are present
   */
  public get hasRequiredData() {
    return this.hasAdvertiserData && this.hasPublisherData;
  }

  /**
   * This method is used to update the `audiencesDatasetHash` either when the audiences updated or when audiences data node is deprovisioned
   * @param audiencesDatasetHash hash of the audiences node dataset in the data room
   * @returns new instanse of `PublishedDatasetsHashes` with updated `audiencesDatasetHash`
   */
  updateAudiencesDatasetHash(audiencesDatasetHash: string | null) {
    return new PublishedDatasetsHashes(
      this.advertiserDatasetHash,
      this.publisherDatasetsHashes,
      audiencesDatasetHash,
      this.datasetsHash
    );
  }

  /**
   * This method is used to update the `advertiserDatasetHash`. It will recalculate `datasetsHash` using new value of `advertiserDatasetHash`
   * @param advertiserDatasetHash hash of the advertiser data provisioned in the data room
   * @returns new instanse of `PublishedDatasetsHashes` with updated `advertiserDatasetHash`
   */
  updateAdvertiserDatasetHash(advertiserDatasetHash: string | null) {
    return new PublishedDatasetsHashes(
      advertiserDatasetHash,
      this.publisherDatasetsHashes,
      this.audiencesDatasetHash
    );
  }

  /**
   * This method is used to update the `publisherDatasetsHashes`. It will recalculate `datasetsHash` using new value of `publisherDatasetsHashes`
   * @param publisherDatasetsHashes hashes of the publisher data provisioned in the data room
   * @returns new instanse of `PublishedDatasetsHashes` with updated `publisherDatasetsHashes`
   */
  updatePublisherDatasetsHashes(
    publisherDatasetsHashes: PublisherDatasetsHashes
  ) {
    return new PublishedDatasetsHashes(
      this.advertiserDatasetHash,
      publisherDatasetsHashes,
      this.audiencesDatasetHash
    );
  }

  /**
   * This method can be used to create an instance of `PublishedDatasetsHashes` with the provided datasets hashes object which
   * might not include all the datasets hashes, thats usefull to only include what is important for execution context
   * @param datasetsHashObj object with the datasets hashes
   * @returns instance of the `PublishedDatasetsHashes`
   */
  updateDatasetsHash(datasetsHashObj: Record<string, string> | null) {
    return new PublishedDatasetsHashes(
      this.advertiserDatasetHash,
      this.publisherDatasetsHashes,
      this.audiencesDatasetHash,
      datasetsHashObj ? computeCacheKeyString(datasetsHashObj) : null
    );
  }
}

export enum RawSupportedFeatures {
  ENABLE_MODEL_PERFORMANCE_EVALUATION = "ENABLE_MODEL_PERFORMANCE_EVALUATION",
  ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES = "ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES",
  ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD = "ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD",
}

export const getAdvertiserAudienceColumns: (
  matchingIdFormat: TableColumnFormatType,
  hashMatchingIdWith: TableColumnHashingAlgorithm | null
) => DataRoomTableColumn[] = (matchingIdFormat, hashMatchingIdWith) => [
  {
    formatType: matchingIdFormat,
    hashWith: hashMatchingIdWith ?? undefined,
    id: "matchingId",
    name: "matchingId",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
  {
    default: "All customers",
    formatType: TableColumnFormatType.String,
    id: "audienceType",
    name: "audienceType",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
];

// TODO: Reusing the same type from ddc even though it has snake_case keys as in the future they wii be converted to camelCase
export type CommonAudience = ddcParameterPayloads.Audience;
export type AdvertiserAudience = Extract<
  CommonAudience,
  { kind: "advertiser" }
> & { mutable: ddcParameterPayloads.MutableAudienceData };
export type LookalikeAudience = Extract<
  CommonAudience,
  { kind: "lookalike" }
> & { mutable: ddcParameterPayloads.MutableAudienceData };
export type RuleBasedAudience = Extract<
  CommonAudience,
  { kind: "rulebased" }
> & { mutable: ddcParameterPayloads.MutableAudienceData };
export type Audience =
  | AdvertiserAudience
  | LookalikeAudience
  | RuleBasedAudience;

export type RulesBasedAudienceDataAttributes = AudienceAttributes;
export interface RulesBasedAudienceDataAttributesFileStructure {
  attributes: RulesBasedAudienceDataAttributes;
}

export interface AudiencesFileStructure {
  advertiser_manifest_hash: string | null;
  matching_manifest_hash: string | null;
  segments_manifest_hash?: string | null;
  embeddings_manifest_hash?: string | null;
  demographics_manifest_hash?: string | null;
  audiences: Audience[];
  version: "v1";
}

export interface LookalikeAudienceStatisticsRaw {
  addressable_audience_size: number;
  filtered_audiences: FilteredLookalikeAudience[];
  quality: LookalikeAudienceQuality;
  seed_audience_size: number;
}

export interface FilteredLookalikeAudience {
  audience_type: string;
  reach: number;
  size: number;
  quality: FilteredLookalikeAudienceQuality;
}

export interface FilteredLookalikeAudienceQuality {
  roc_curve: FilteredLookalikeAudienceROCCurve;
}

export interface FilteredLookalikeAudienceROCCurve {
  fpr: number;
  tpr: number;
  score: number;
}

export interface LookalikeAudienceQuality {
  roc_curve: LookalikeAudienceROCCurve;
}

export interface LookalikeAudienceROCCurve {
  auc: number;
  fpr: [number, number];
  reach: [number, number];
  test_set_size?: number;
  test_set_positive_examples_size?: number;
  tpr: [number, number];
}

export type AudienceKind = Pick<Audience, "kind">["kind"];

export const audienceTypePresentationMap = new Map<AudienceKind, string>([
  ["lookalike", "AI Lookalike"],
  ["advertiser", "Remarketing"],
  ["rulebased", "Rule-based"],
]);

export const audienceStatusPresentationMap = new Map<AudienceStatus, string>([
  ["published", "Available to publisher"],
  ["published_as_intermediate", "Ready"],
  ["ready", "Ready"],
]);

export const ALL_PUBLISHER_USERS_AUDIENCE: AdvertiserAudience = {
  audience_size: Infinity,
  audience_type: "all publisher users",
  id: "ALL_PUBLISHER_USERS",
  kind: "advertiser",
  mutable: {
    created_at: new Date().toISOString(),
    name: "All publisher users",
    status: "ready",
  },
};

export interface MediaInsightsAudienceCacheKey {
  audienceKind: AudienceKind;
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  audiences: Audience[];
  audienceId: string;
  reach?: number;
}

export interface InsightsBasicViewFormValues {
  audienceType: string;
  genderChartValueKey: string;
}

export interface InsightsComparisonViewFormValues {
  aggregation: string;
  audienceType: string;
}

export interface InsightsDetailedViewFormValues {
  audienceType: string;
  genderChartValueKey: string;
  // Defines whether selected best or worst affinityRatio values
  // TODO: check why boolean is needed s a part of definition
  dataSortDirection: boolean | "desc" | "asc";
}

export const DEFAULT_PARTICIPANTS_EMAILS = new Map<
  MediaDataRoomUserRole,
  string[]
>([
  [MediaDataRoomUserRole.Publisher, []],
  [MediaDataRoomUserRole.DataPartner, []],
  [MediaDataRoomUserRole.Advertiser, []],
  [MediaDataRoomUserRole.Observer, []],
  [MediaDataRoomUserRole.Agency, []],
]);

export interface PublishMediaDataRoomInput {
  dataPartnerEmails: string[];
  advertiserEmails: string[];
  agencyEmails: string[];
  enableInsights: boolean;
  enableLookalike: boolean;
  enableRemarketing: boolean;
  enableRuleBasedAudiences: boolean;
  hideAbsoluteValuesFromInsights: boolean;
  enableAdvertiserAudienceDownload: boolean;
  observerEmails: string[];
  publisherEmails: string[];
  mainAdvertiserEmail: string;
  mainPublisherEmail: string;
  name: string;
  matchingIdFormat: MatchingColumnFormat;
  matchingIdHashingAlgorithm?: TableColumnHashingAlgorithm;
}

export const entireOverlapKey = "All audiences combined";

export interface AggregationDataModel {
  addressableAudienceSize: number;
  affinityRatio: number;
  age?: string;
  aggregationName?: string;
  interest?: string;
  gender?: string;
  shareInMatchableAudience: number;
  shareInOverlap: number;
}

export type AudienceFilter = AudienceKind | "all";

export type AudienceStatus = ddcParameterPayloads.Status;
