import { memo } from "react";
import { ConfigurationProvider } from "contexts";
import { configuration } from "utils/configuration/configuration";

const ConfigurationWrapper = memo<React.PropsWithChildren>(({ children }) => {
  return (
    <ConfigurationProvider value={configuration}>
      {children}
    </ConfigurationProvider>
  );
});

export default ConfigurationWrapper;
