// TODO: Fix joy migration
import { InlineEditor } from "@decentriq/components";
import {
  useCreateStaticScriptMutation,
  useDeleteStaticScriptMutation,
  useUpdateScriptContentMutation,
  useUpdateStaticScriptNameMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftScript,
  type PublishedScript,
  StaticScriptFragment,
} from "@decentriq/graphql/dist/types";
import { faPen, faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tab, TabList, Tabs, Tooltip } from "@mui/joy";
import { useDebounceFn } from "ahooks";
import { Allotment } from "allotment";
import { useCallback, useState } from "react";
import { PlainTextEditorField, ScriptingEditorField } from "components";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { DEFAULT_SCRIPT } from "models";
import { FileExplorer, InputsEditor } from "./components";
import useScriptingComputeNode from "./useScriptingComputeNode";

const DataRoomComputeNodeTabPanel = (props: any) => {
  const { children, value, index, ...rest } = props;
  return (
    <div
      hidden={value !== index}
      role="tabpanel"
      style={{ height: "100%" }}
      {...rest}
    >
      {value === index && (
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              alignItems: "stretch",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "stretch",
              py: 1,
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </div>
  );
};

interface ScriptingComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
  editorOptions?: object;
  fullHeight?: boolean;
}

const ScriptingComputeNodeEditor: React.FC<ScriptingComputeNodeEditorProps> = ({
  computeNodeId,
  readOnly,
  editorOptions,
  fullHeight = false,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { scriptingLanguage, scripts } = useScriptingComputeNode(computeNodeId);
  const mainScript = scripts?.find(
    ({ isMainScript }: DraftScript | PublishedScript) => !!isMainScript
  );
  const hasMainScript = !!mainScript;
  const mainScriptDefaultValue =
    mainScript?.content ?? DEFAULT_SCRIPT.get(scriptingLanguage!);
  const staticScripts =
    scripts?.filter(
      ({ isMainScript }: DraftScript | PublishedScript) => !isMainScript
    ) || [];
  const staticScriptsOffset = hasMainScript ? 1 : 0;
  // Tabs
  const [activeTab, setActiveTab] = useState<number>(0);
  const onChangeTab = useCallback((event: any, value: number) => {
    setActiveTab(value);
  }, []);
  // Create static script
  const [createStaticScriptMutation] = useCreateStaticScriptMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The new script could not be created."
        )
      );
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          scripts: (existing = {}) => {
            const scriptRef = cache.writeFragment({
              data: data?.draftScriptingNode?.createScript?.record,
              fragment: StaticScriptFragment,
            });
            return {
              ...existing,
              nodes: [...(existing?.nodes || []), scriptRef],
            };
          },
        },
        id: cache.identify({
          __typename: "DraftScriptingNode",
          id: computeNodeId,
        }),
      });
    },
    variables: {
      input: {
        draftScriptingNodeId: computeNodeId,
        isMainScript: !hasMainScript,
      },
    },
  });
  const onAddTab = useCallback(
    () => createStaticScriptMutation(),
    [createStaticScriptMutation]
  );
  // Update static script name
  const [updateStaticScriptNameMutation] = useUpdateStaticScriptNameMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The script could not be renamed."
        )
      );
    },
  });
  const onRenameTab = useCallback(
    (name: string, scriptId: string) => {
      updateStaticScriptNameMutation({
        variables: {
          input: {
            id: scriptId,
            name,
          },
        },
      });
    },
    [updateStaticScriptNameMutation]
  );
  // Delete static script
  const [deleteStaticScriptMutation] = useDeleteStaticScriptMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The script could not be deleted."
        )
      );
    },
  });
  const onDeleteTab = useCallback(
    (scriptId: string, index: number) => {
      deleteStaticScriptMutation({
        onCompleted: () => {
          if (activeTab === index) {
            setActiveTab(index - 1);
          }
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              __typename: "DraftScript",
              id: scriptId,
            }),
          });
          cache.gc();
        },
        variables: {
          scriptId,
        },
      });
    },
    [activeTab, deleteStaticScriptMutation]
  );
  // Update script content
  const [updateScriptContentMutation] = useUpdateScriptContentMutation({
    onError: (error) => {
      enqueueSnackbar("The script content could not be updated.", {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
  });
  const updateScriptContent = useCallback(
    (scriptId: string, value?: string) => {
      return updateScriptContentMutation({
        variables: {
          input: {
            content: value,
            id: scriptId,
          },
        },
      });
    },
    [updateScriptContentMutation]
  );
  const { run: debouncedUpdateScriptContent } = useDebounceFn(
    updateScriptContent,
    { wait: 750 }
  );
  return (
    <Box
      sx={{
        "--focus-border": "var(--joy-palette-primary-500)",
        "--separator-border": "var(--joy-palette-divider)",
        height: "100%",
        minHeight: "300px",
        overflow: "scroll",
        resize: "vertical",
      }}
    >
      <Allotment maxSize={Number.POSITIVE_INFINITY} minSize={0}>
        <Allotment.Pane>
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Tabs onChange={onChangeTab} value={activeTab}>
              <TabList sx={{ "--List-radius": "0px" }}>
                {mainScript ? <Tab>Main script</Tab> : null}
                {staticScripts.map(
                  (script: DraftScript | PublishedScript, index: number) => (
                    <Tab key={index} value={script.name}>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", m: 0.5 }}>
                          <InlineEditor
                            cancelEditingButtonEnabled={false}
                            onChange={(name: string) =>
                              onRenameTab(name, (script as DraftScript).id)
                            }
                            placeholder="Name"
                            readOnly={readOnly}
                            saveEditingButtonEnabled={false}
                            saveEditingOnClickAway={true}
                            startEditingButtonEnabled={!readOnly}
                            startEditingButtonIcon={faPen}
                            startEditingButtonTooltipTitle="Rename"
                            startEditingOnContentClick={false}
                            validate={(value: string) => {
                              if (!value?.trim()) {
                                return "Name must be set";
                              }
                              if (
                                staticScripts.some(
                                  ({ name }: DraftScript | PublishedScript) =>
                                    name === value.trim()
                                )
                              ) {
                                return "Name must be unique";
                              }
                            }}
                            value={script.name || undefined}
                          />
                        </Box>
                        {!readOnly ? (
                          <Tooltip placement="top" title="Delete">
                            <IconButton
                              onClick={() =>
                                onDeleteTab((script as DraftScript).id, index)
                              }
                            >
                              <FontAwesomeIcon
                                fixedWidth={true}
                                icon={faTimes}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Box>
                    </Tab>
                  )
                )}
                {!readOnly ? (
                  <IconButton onClick={onAddTab}>
                    <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
                  </IconButton>
                ) : null}
              </TabList>
            </Tabs>
            {mainScript ? (
              <DataRoomComputeNodeTabPanel index={0} value={activeTab}>
                <ScriptingEditorField
                  defaultLanguage={scriptingLanguage!.toLowerCase()}
                  defaultValue={mainScriptDefaultValue}
                  editorOptions={editorOptions}
                  onChange={(value) =>
                    debouncedUpdateScriptContent(
                      (mainScript as DraftScript | undefined)?.id || "",
                      value
                    )
                  }
                />
              </DataRoomComputeNodeTabPanel>
            ) : null}
            {staticScripts.map(
              (script: DraftScript | PublishedScript, index: number) => (
                <DataRoomComputeNodeTabPanel
                  index={index + staticScriptsOffset}
                  key={`${script.name}-${index + staticScriptsOffset}`}
                  value={activeTab}
                >
                  <PlainTextEditorField
                    defaultValue={script.content || ""}
                    editorOptions={{
                      ...(readOnly ? { height: 560 } : {}),
                      readOnly: readOnly,
                      ...editorOptions,
                    }}
                    onChange={(value) =>
                      debouncedUpdateScriptContent(
                        (script as DraftScript).id,
                        value || ""
                      )
                    }
                  />
                </DataRoomComputeNodeTabPanel>
              )
            )}
          </Box>
        </Allotment.Pane>
        <Allotment.Pane minSize={200} preferredSize="25%" snap={true}>
          <Box sx={{ height: "100%", overflow: "auto", width: "100%" }}>
            <InputsEditor computeNodeId={computeNodeId} readOnly={readOnly} />
            <FileExplorer
              computeNodeId={computeNodeId}
              fullHeight={fullHeight}
              readOnly={readOnly}
            />
          </Box>
        </Allotment.Pane>
      </Allotment>
    </Box>
  );
};
ScriptingComputeNodeEditor.displayName = "ScriptingComputeNodeEditor";

export default ScriptingComputeNodeEditor;
