import { useAuth0 } from "@auth0/auth0-react";
import { testIds } from "@decentriq/utils";
import {
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { type SxProps } from "@mui/system";
import { memo } from "react";
import { Link, Navigate } from "react-router-dom";
import { PublishedDataRoomErrorReport } from "components";
import { DataRoomAuditLogs, DataRoomParticipants } from "containers";
import { usePublishedDataRoom } from "contexts";
import {
  ComputeNodeConstructorMode,
  ComputeNodes,
  DataNodeConstructorMode,
  DataNodes,
  DevComputeNodes,
  Requests,
} from "features";
import { PublishedDataRoomTab } from "models";
import { ComputeNodesVarsWrapper, PermissionsVarsWrapper } from "wrappers";

const tabsSx: SxProps = { flex: 1, overflow: "hidden" };
const tabPanelSx: SxProps = {
  "&:not([hidden])": {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
};

interface PublishedDataRoomTabsProps {
  activeTab: PublishedDataRoomTab;
  baseUrl: string;
  requestsTotalCount: number;
  ownerEmail: string | undefined;
  createdAt: string | undefined;
  permittedOnly: boolean;
  handlePermittedOnlyChange: () => void;
}

const PublishedDataRoomTabs: React.FC<PublishedDataRoomTabsProps> = ({
  activeTab,
  baseUrl,
  ownerEmail,
  requestsTotalCount,
  createdAt,
  permittedOnly,
  handlePermittedOnlyChange,
}) => {
  const {
    testing,
    enableRequests,
    isDeactivated,
    isStopped,
    dataRoomId,
    driverAttestationHash,
    switchToRequests,
  } = usePublishedDataRoom();
  const { user = {} } = useAuth0();
  const { email: currentUserEmail } = user || {};

  const showActionableItemsSwitcher = !testing && !isDeactivated && !isStopped;
  if (
    (testing &&
      [PublishedDataRoomTab.audit, PublishedDataRoomTab.requests].includes(
        activeTab
      )) ||
    (activeTab === PublishedDataRoomTab.development && !enableRequests)
  ) {
    return <Navigate to={`${baseUrl}/${PublishedDataRoomTab.overview}`} />;
  }
  return (
    <Tabs sx={tabsSx} value={activeTab}>
      <TabList>
        <Tab
          component={Link}
          data-testid={testIds.containers.draftDataRoom.overviewTab}
          to={`${baseUrl}/${PublishedDataRoomTab.overview}`}
          value={PublishedDataRoomTab.overview}
        >
          Overview
        </Tab>
        {enableRequests ? (
          <Tab
            component={Link}
            data-testid="tab_published_dcr_development"
            to={`${baseUrl}/${PublishedDataRoomTab.development}`}
            value={PublishedDataRoomTab.development}
          >
            Development
          </Tab>
        ) : null}
        {enableRequests && !testing ? (
          <Tab
            component={Link}
            data-testid="tab_published_dcr_requests"
            to={`${baseUrl}/${PublishedDataRoomTab.requests}`}
            value={PublishedDataRoomTab.requests}
          >
            <div style={{ alignItems: "center", display: "flex" }}>
              {!!requestsTotalCount && (
                <span
                  style={{
                    alignItems: "center",
                    backgroundColor: "rgb(40, 144, 144)",
                    borderRadius: "50%",
                    height: "8px",
                    justifyContent: "center",
                    marginRight: "0.5rem",
                    width: "8px",
                  }}
                ></span>
              )}{" "}
              Requests
            </div>
          </Tab>
        ) : null}
        {!testing && (
          <Tab
            component={Link}
            data-testid="tab_published_dcr_auditlog"
            to={`${baseUrl}/${PublishedDataRoomTab.audit}`}
            value={PublishedDataRoomTab.audit}
          >
            <Typography level="inherit">Audit log</Typography>
          </Tab>
        )}
      </TabList>
      <TabPanel sx={tabPanelSx} value={PublishedDataRoomTab.overview}>
        <Stack>
          {showActionableItemsSwitcher && (
            <Switch
              checked={permittedOnly}
              endDecorator="Show only actionable items"
              onChange={handlePermittedOnlyChange}
              sx={{ alignSelf: "auto" }}
            />
          )}
          <Typography level="title-md">Data</Typography>
          <DataNodes
            currentUserEmail={currentUserEmail!}
            dataRoomId={dataRoomId}
            mode={
              isStopped || isDeactivated
                ? DataNodeConstructorMode.DEGRADE_ACTION
                : DataNodeConstructorMode.ACTION
            }
            ownerEmail={ownerEmail!}
            permittedOnly={permittedOnly}
          />
          <ComputeNodesVarsWrapper
            hasComputeNodeActions={true}
            hasComputeNodeParticipants={true}
            mode={
              isStopped || isDeactivated
                ? ComputeNodeConstructorMode.READONLY
                : ComputeNodeConstructorMode.ACTION
            }
            permittedOnly={permittedOnly}
          >
            <Typography level="title-md">Computations</Typography>
            <ComputeNodes relaxed={true} />
          </ComputeNodesVarsWrapper>
          {!permittedOnly && (
            <>
              <Typography level="title-md">Permissions</Typography>
              <PermissionsVarsWrapper canEditPermissions={false}>
                <DataRoomParticipants />
              </PermissionsVarsWrapper>
            </>
          )}
        </Stack>
      </TabPanel>
      {enableRequests ? (
        <TabPanel sx={tabPanelSx} value={PublishedDataRoomTab.development}>
          <PublishedDataRoomErrorReport
            createdAt={createdAt!}
            dataRoomId={dataRoomId}
            driverAttestationHash={driverAttestationHash}
            includeDevelopmentFetching={true}
          >
            <ComputeNodesVarsWrapper
              executionContext="development"
              hasComputeNodeActions={true}
              hasComputeNodeParticipants={false}
              mode={ComputeNodeConstructorMode.EDIT}
            >
              <DevComputeNodes
                dataRoomId={dataRoomId!}
                switchToRequests={switchToRequests}
              />
            </ComputeNodesVarsWrapper>
          </PublishedDataRoomErrorReport>
        </TabPanel>
      ) : null}
      {enableRequests && !testing ? (
        <TabPanel sx={tabPanelSx} value={PublishedDataRoomTab.requests}>
          <PublishedDataRoomErrorReport
            createdAt={createdAt!}
            dataRoomId={dataRoomId}
            driverAttestationHash={driverAttestationHash}
            includeRequestsFetching={true}
          >
            <Requests dataRoomId={dataRoomId} />
          </PublishedDataRoomErrorReport>
        </TabPanel>
      ) : null}
      {!testing ? (
        <TabPanel
          sx={{
            "&:not([hidden])": {
              alignItems: "stretch",
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              overflow: "hidden",
            },
          }}
          value={PublishedDataRoomTab.audit}
        >
          <DataRoomAuditLogs
            dcrHash={dataRoomId!}
            driverAttestationHash={driverAttestationHash}
          />
        </TabPanel>
      ) : null}
    </Tabs>
  );
};

PublishedDataRoomTabs.displayName = "PublishedDataRoomTabs";

export default memo(PublishedDataRoomTabs);
