import { useDraftDataRoomTestPublicationsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type DraftRawLeafNode,
  type DraftTableLeafNode,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faFileContract as falFileContract } from "@fortawesome/pro-light-svg-icons";
import { faInfoCircle as fatInfoCircle } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { DraftDataNodeTypeNames } from "models";

const ALLOW_TEST_DATA_TRANSFERING = false;

interface DataRoomPublishDialogProps {
  dataRoomId: string;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (keepTestData: boolean) => void;
}

const DataRoomPublishDialog: React.FC<DataRoomPublishDialogProps> = ({
  dataRoomId,
  open,
  loading,
  onCancel,
  onConfirm,
}) => {
  const { data: testPublicationsData } = useDraftDataRoomTestPublicationsQuery({
    skip: !ALLOW_TEST_DATA_TRANSFERING,
    variables: {
      dataRoomId,
    },
  });
  const hasTestPublications =
    testPublicationsData?.draftDataRoom?.draftNodes?.nodes?.some(
      ({ id, __typename, ...rest }) =>
        __typename === DraftDataNodeTypeNames.DraftRawLeafNode ||
        (__typename === DraftDataNodeTypeNames.DraftTableLeafNode &&
          (rest as DraftTableLeafNode | DraftRawLeafNode).testModePublication)
    );
  const [keepTestData, setKeepTestData] = useState(true);
  return (
    <Modal open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <FontAwesomeIcon fixedWidth={true} icon={fatInfoCircle} size="3x" />
        </DialogTitle>
        <DialogContent>
          <Typography level="body-md">
            <strong>
              This publishes the data clean room and sends invitation emails to
              all participants.
            </strong>
          </Typography>
          {hasTestPublications && (
            <div>
              <Typography>
                Test data was used for drafting the data clean room. Would you
                like to keep it?
                <br />
                Note: test data will only be accesible to you
              </Typography>
              <FormControl style={{ width: "100%" }}>
                <RadioGroup
                  onChange={(e) =>
                    setKeepTestData(e.target.value as unknown as boolean)
                  }
                  value={keepTestData}
                >
                  <Radio
                    label="Yes, keep test data accesible to me"
                    value={true}
                  />
                  <Radio label="No, delete it" value={false} />
                </RadioGroup>
              </FormControl>
            </div>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Go back to editing
          </Button>
          <Button
            color="primary"
            data-testid={testIds.dataroom.dataroomPublishDialog.publishButton}
            loading={loading}
            loadingPosition="start"
            onClick={() => onConfirm(keepTestData)}
            startDecorator={<FontAwesomeIcon icon={falFileContract} />}
            variant="solid"
          >
            Publish data clean room
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default DataRoomPublishDialog;
