import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import {
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  useAdvertiserAudiences,
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr/contexts";
import {
  type Audience,
  MediaDataRoomTab,
} from "features/MediaInsightsDcr/models";
import { useDataRoomSnackbar, useOrganizationPreferences } from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import {
  AdvertiserAudienceGeneratorEmptyState,
  AdvertiserAudienceGeneratorErrorState,
  AdvertiserAudienceGeneratorLoadingState,
} from "../../AdvertiserAudienceGenerator/components";
import {
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "../../AdvertiserAudienceGenerator/contexts/AdvertiserAudienceGeneratorContext";
import {
  LookalikeAdvertiserAudienceGeneratorConfigurationStep,
  LookalikeAdvertiserAudienceGeneratorSummaryStep,
} from "../components";
import { type LookalikeAdvertiserAudienceGeneratorContextValue } from "../contexts/LookalikeAdvertiserAudienceGeneratorContext";
import { LookalikeAdvertiserAudienceGeneratorProvider } from "..";

const LookalikeAdvertiserAudienceGenerator = memo(() => {
  const { currentStep } = useAdvertiserAudienceGenerator();
  const [reach, setReach] = useState<number>(1);
  const [excludeSeedAudience, setExcludeSeedAudience] =
    useState<boolean>(false);
  const [selectedAudienceType, setSelectedAudienceType] = useState<
    string | undefined
  >();
  const { allowExcludingSeedAudience } = useOrganizationPreferences();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId, isDeactivated } = usePublishedMediaInsightsDcr();
  // Advertiser audience Quality drawer state
  const [
    isAdvertiserAudienceQualityDrawerOpen,
    {
      setTrue: openAdvertiserAudienceQualityDrawer,
      setFalse: closeAdvertiserAudienceQualityDrawer,
    },
  ] = useBoolean(false);
  // Exclude Seed Audience dialog state
  const [
    isExcludeSeedAudienceInfoDialogVisible,
    {
      setTrue: openExcludeSeedAudienceInfoDialog,
      setFalse: closeExcludeSeedAudienceInfoDialog,
    },
  ] = useBoolean(false);
  const {
    availableAudiences: {
      computeResults: {
        availableAudiences: {
          lookalike: availableLookalikeAudiences = [],
        } = {},
        audienceTypes = [],
      } = {},
    },
    availableAudiences,
  } = useMediaInsightsDcrData();
  const { activatedAudiences, addAudience } = useAdvertiserAudiences();
  const [generationStep, setGenerationStep] =
    useState<
      LookalikeAdvertiserAudienceGeneratorContextValue["generationStep"]
    >(null);
  // In order to properly show the state of Exclude Seed Audience checkbox,
  // needed to check for only manually generated audiences, thus the checkbox will be greyed out
  // and could be defined whether a generation is a first one, so the info dialog will pop up
  // note that pregenerated (retarget, exclusion target) audiences do not have reach value
  const activatedLookalikeAudiences: Audience[] = activatedAudiences.filter(
    ({ activationType, reach }) => activationType === "lookalike" && reach
  );
  const selectedLookalikeAudience = useMemo(
    () =>
      availableLookalikeAudiences.find(
        ({ audience_type }) => audience_type === selectedAudienceType
      ),
    [selectedAudienceType, availableLookalikeAudiences]
  );
  const { estimatedAudienceSize, selectedAudienceQuality } = useMemo(() => {
    if (!selectedAudienceType) {
      return { audienceQualityScore: 0, estimatedAudienceSize: 0 };
    }
    const { size, quality } =
      selectedLookalikeAudience?.filtered_audiences?.find(
        ({ reach: lookalikeAudienceReach }) => reach === lookalikeAudienceReach
      ) || {};

    return {
      estimatedAudienceSize: size || 0,
      selectedAudienceQuality: quality,
    };
  }, [selectedLookalikeAudience, selectedAudienceType, reach]);
  // Set default value for reach slider
  useEffect(() => {
    // If current reach is amongst the values of the new type, keep it, otherwise select the smallest
    setReach((currentReach) => {
      return (selectedLookalikeAudience?.filtered_audiences || []).some(
        ({ reach }) => reach === currentReach
      )
        ? currentReach
        : 1;
    });
  }, [selectedLookalikeAudience?.filtered_audiences, setReach]);
  // Set default value for audienceType selector
  useEffect(() => {
    if (audienceTypes) {
      setSelectedAudienceType(audienceTypes[0]);
    }
  }, [audienceTypes]);
  // Set default value for Exclude Seed Audience checkbox
  useEffect(() => {
    const isExcludeSeedAudienceActivated = activatedLookalikeAudiences.some(
      ({ excludeSeedAudience }) => !!excludeSeedAudience
    );
    if (!allowExcludingSeedAudience || !isExcludeSeedAudienceActivated) return;
    setExcludeSeedAudience(isExcludeSeedAudienceActivated);
  }, [
    allowExcludingSeedAudience,
    setExcludeSeedAudience,
    activatedLookalikeAudiences,
  ]);
  const onGenerateLookalikeAudienceSuccess = useCallback(() => {
    setGenerationStep("completed");
    enqueueSnackbar("Audiences successfully updated");
    // When an Audience is generated, a user should be redirected to the Audience list
    // with small timeout so notification can be easily read
    setTimeout(() => {
      const dataRoomBasePath = dataRoomPathBuilder(
        dataRoomId!,
        DataRoomTypeNames.PublishedMediaInsightsDcr
      );
      navigate(`${dataRoomBasePath}/${MediaDataRoomTab.activation}`);
    }, 1200);
  }, [enqueueSnackbar, navigate, dataRoomId]);
  const generateLookalikeAudience = useCallback(() => {
    setGenerationStep("pending");
    addAudience({
      audienceType: selectedAudienceType!,
      excludeSeedAudience,
      onError: () => {
        setGenerationStep(null);
      },
      onSuccess: onGenerateLookalikeAudienceSuccess,
      reach,
    });
  }, [
    selectedAudienceType,
    onGenerateLookalikeAudienceSuccess,
    excludeSeedAudience,
    addAudience,
    reach,
  ]);
  const [retryInProgress, setRetryInProgress] = useState(false);
  const retryGeneration = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      await availableAudiences.retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [availableAudiences, isDeactivated]);
  if (availableAudiences.loading) {
    return <AdvertiserAudienceGeneratorLoadingState />;
  }
  if (availableAudiences.error) {
    return (
      <AdvertiserAudienceGeneratorErrorState
        RetryButton={
          !isDeactivated && (
            <Button
              color="neutral"
              loading={retryInProgress}
              onClick={retryGeneration}
              variant="soft"
            >
              Retry
            </Button>
          )
        }
      />
    );
  }
  if (!availableAudiences?.computeResults?.audienceTypes?.length) {
    return <AdvertiserAudienceGeneratorEmptyState />;
  }
  let step: ReactNode = null;
  switch (currentStep) {
    case AdvertiserAudienceGeneratorStep.CONFIGURATION:
      step = <LookalikeAdvertiserAudienceGeneratorConfigurationStep />;
      break;
    case AdvertiserAudienceGeneratorStep.SUMMARY:
      step = <LookalikeAdvertiserAudienceGeneratorSummaryStep />;
      break;
  }
  return (
    <LookalikeAdvertiserAudienceGeneratorProvider
      value={{
        activatedLookalikeAudiences,
        availableAudiences,
        closeAdvertiserAudienceQualityDrawer,
        closeExcludeSeedAudienceInfoDialog,
        estimatedAudienceSize,
        excludeSeedAudience,
        generateLookalikeAudience,
        generationStep,
        isAdvertiserAudienceQualityDrawerOpen,
        isExcludeSeedAudienceInfoDialogVisible,
        openAdvertiserAudienceQualityDrawer,
        openExcludeSeedAudienceInfoDialog,
        reach,
        selectedAudienceQuality,
        selectedAudienceType,
        selectedLookalikeAudience,
        setExcludeSeedAudience,
        setReach,
        setSelectedAudienceType,
      }}
    >
      {step}
    </LookalikeAdvertiserAudienceGeneratorProvider>
  );
});

LookalikeAdvertiserAudienceGenerator.displayName =
  "LookalikeAdvertiserAudienceGenerator";

export default LookalikeAdvertiserAudienceGenerator;
